import React, { Component } from 'react';
import classNames from 'classnames/bind';

class SearchTabContentItem extends Component {
  constructor(props) {
    super(props);

    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(e) {
      e.preventDefault();

      this.props.submitMenuItemClick(this.props.menuName, this.props.id);
  }

  render() {
      let classes = classNames({
            'search-tab__content-item' : true,
            'search-tab__content-item--active' : this.props.active,
            'search-tab__content-item--remove-style' : this.props.removeStyling
          });

      let deleteLink;

      if (this.props.removeStyling) {
          deleteLink = (<span className='search-tab__content-item-remove-link'><i className="fa fa-close search-tab__content-item-remove-link-icon"></i></span>)
      }

      return (
        <a href={this.props.url || '#'} className={classes} onClick={this.handleClick}>
          {this.props.name}{deleteLink}
        </a>
      )

  }
};

SearchTabContentItem.defaultProps = {
  id : '',
  name: '',
  active : false,
  menuName : 'genre'
}

export default SearchTabContentItem;
