import React, { Component } from 'react';
import classNames from 'classnames/bind';

class SearchTabFilterContentItem extends Component {
  constructor(props) {
    super(props);
    
    this.handleClick = this.handleClick.bind(this);  
  }

  handleClick(e) {
      e.preventDefault();

      this.props.submitMenuItemClick(this.props.menuName, this.props.id);
  }

  render() {
      let classes = classNames({
            'search-tab__content-filter-item' : true,
            'search-tab__content-filter-item--active' : this.props.active
          });

      return (
        <div className='row'>
          <div className='col12'>
            <strong>{this.props.heading}</strong>
            <div>
              <div className='display-inline-block margin-right10'>
                <input type="radio" id={this.props.name + '_' + '0'} name={this.props.name} value="0" onClick={this.props.submitExtendedFilterChange.bind(this, this.props.name, 0)} checked={this.props.isExtendedFilterChecked(this.props.name, 0)}></input>
                <label htmlFor={this.props.name + '_' + '0'}>{this.props.labels[0]}</label>
              </div>
              <div className='display-inline-block margin-right10'>
                <input type="radio" id={this.props.name + '_' + '1'} name={this.props.name} value="1" onClick={this.props.submitExtendedFilterChange.bind(this, this.props.name, 1)} checked={this.props.isExtendedFilterChecked(this.props.name, 1)}></input>
                <label htmlFor={this.props.name + '_' + '1'}>{this.props.labels[1]}</label>
              </div>
              <div className='display-inline-block margin-right10'>
                <input type="radio" id={this.props.name + '_' + '2'} name={this.props.name} value="2" onClick={this.props.submitExtendedFilterChange.bind(this, this.props.name, 2)} checked={this.props.isExtendedFilterChecked(this.props.name, 2)}></input>
                <label htmlFor={this.props.name + '_' + '2'}>{this.props.labels[2]}</label>
              </div>
            </div>
          </div>
        </div>
      )

  }
};

SearchTabFilterContentItem.defaultProps = {
  id : '',
  name: '',
  active : false,
  menuName : 'genre'
}

export default SearchTabFilterContentItem;