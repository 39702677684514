import React, { Component } from 'react';
import classNames from 'classnames/bind';

class SearchTabName extends Component {

  constructor(props) {
    super(props);

    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(e) {
      e.preventDefault();
      this.props.handleChange(this.props.index);
  }

  getItemNameForItemId(id) {
    let title = '';
    this.props.items.forEach(function(item) {
      if (item['id'] === id) {
        title = item['name'];
      }
    });

    return title;
  }

  render() {
    let classes = classNames({
          'search-tab__name' : true,
          'search-tab__name--active' : this.props.active === true,
          'search-tab__name--highlighted' : this.props.highlighted === true,
        }),
        icon,
        counter,
        label;

    if (this.props.iconClassName) {
        icon = <span className='search-tab__name-icon'><i className={'fa fa-' + this.props.iconClassName} /></span>;
    }

    if (this.props.counter && this.props.counter > 1) {
        counter = <span className='search-tab__name-counter'>+{this.props.counter - 1}</span>;
    }

    if (this.props.proFilter) {
      if (this.props.activeItems === 0) {
        label = (
          <span>{this.props.locale === 'de' ? 'Alle Tracks (P.R.O. und GEMAfrei)' : 'All tracks (P.R.O. and royalty free)'}</span>
        )
      } else if (this.props.activeItems === 1) {
        label = (
          <span>{this.props.locale === 'de' ? 'P.R.O. Tracks (nicht GEMA-frei)' : 'P.R.O. tracks'}</span>
        )
      } else {
        label = (
          <span>{this.props.locale === 'de' ? 'GEMAfrei' : 'Royalty free (non P.R.O.)'}</span>
        )
      }
    } else {
      label = (
        <span>
          {(this.props.activeItems.length > 0) ? this.getItemNameForItemId(this.props.activeItems[0]) : this.props.title}
          {counter}
        </span>
      )
    }

    return (
      <div className={classes}>
        <a onClick={this.handleClick} href='#' className='search-tab__name-link'>
          {icon}
          <span className='search-tab__name-title'>
            {label}
          </span>
        </a>
      </div>
    );
  }
};

export default SearchTabName;
