import Helpers from '~/modules/Helpers.js';

const CheckboxDropdown = function (element, config) {

    var _defaultConfig;

    _defaultConfig = {
        toggleClass: 'checkbox-dropdown--visible',
        unselectAllSelector: '[data-action~=checkboxDropdownUnselectAll]',
        // internals
        beforeMount: function () {
            // instance is available as instance parameter
        },
        afterMount: function () {
            // instance is available as instance parameter
        }
    };
    if (element && element.nodeType === 1) {
        this.dropdownContainer = element;
    } else {
        throw new Error('linkNode for Checkbox Dropdown was invalid or not of the correct type');
    }

    this.originalSwitchLabel = undefined;

    this.config = Helpers.createConfigObject(config, _defaultConfig);

    this._init();
};

CheckboxDropdown.prototype._init = function () {
    this.config.beforeMount(this);

    if (this.dropdownContainer) {
        this.unselectElement = this.dropdownContainer.querySelector(this.config.unselectAllSelector);
        this.originalSwitchLabel = this.dropdownContainer.querySelector('.checkbox-dropdown__switch-counter').getAttribute('data-checkbox-dropdown-label');
        this._updateSwitchLabel();
        this._addEventListeners();
    }

    this.config.afterMount(this);
};

CheckboxDropdown.prototype._updateSwitchLabel = function () {
    var activeElements = this.dropdownContainer.querySelectorAll('input[type=checkbox]:checked');

    if (activeElements.length === 0) {
        this.dropdownContainer.querySelector('.checkbox-dropdown__switch-counter').innerHTML = this.originalSwitchLabel;
    } else {
        if (activeElements.length === 1) {
            this.dropdownContainer.querySelector('.checkbox-dropdown__switch-counter').innerHTML = activeElements.length + ' ' + (I18n.locale === 'de' ? ' Option ausgewählt' : ' option selected');
        } else {
            this.dropdownContainer.querySelector('.checkbox-dropdown__switch-counter').innerHTML = activeElements.length + ' ' + (I18n.locale === 'de' ? ' Optionen ausgewählt' : ' options selected');
        }

    }
};

CheckboxDropdown.prototype._addEventListeners = function () {
    global.EventHub.on('closeAllDropdowns', function (data) {
        console.log('removing via eventhub');
        var originDropdownContainerElement = data[0];
        if (originDropdownContainerElement !== this.dropdownContainer) {
            this.dropdownContainer.classList.remove(this.config.toggleClass);
        }
    }.bind(this));

    global.EventHub.on('closePopupMenus', function (data) {
        this.dropdownContainer.classList.remove(this.config.toggleClass);
    }.bind(this));


    this.dropdownContainer.querySelector('.checkbox-dropdown__switch').addEventListener('click', function (e) {
        e.preventDefault();

        global.EventHub.trigger('closeAllDropdowns', this.dropdownContainer);

        // setTimeout(function () {
        if (this.dropdownContainer.classList.contains(this.config.toggleClass)) {
            // still open
            console.log('removing after 10ms');
            this.dropdownContainer.classList.remove(this.config.toggleClass);
        } else {
            // closed
            console.log('adding after 10ms');
            this.dropdownContainer.classList.add(this.config.toggleClass);
        }
        // }.bind(this), 10)
    }.bind(this));

    this.dropdownContainer.querySelector('.checkbox-dropdown__checkboxes').addEventListener('click', function (e) {
        this._updateSwitchLabel();
    }.bind(this));

    if (this.unselectElement) {
        this.unselectElement.addEventListener('click', function(e) {
            var checkedElements = this.dropdownContainer.querySelectorAll('input[type=checkbox]:checked');
            
            e.preventDefault();

            checkedElements.forEach(function(element) {
                element.checked = false;
            })
        }.bind(this))
    }
};

export default CheckboxDropdown;