import React, { Component } from 'react';
import SearchTabFilterContentItem from '~/components/legacy/SearchTabFilterContentItem.jsx';

class SearchTabFilterContent extends Component {
  constructor(props) {
    super(props);
  }

  isExtendedFilterActive(category, value) {
      let isActive = false;

      this.props.activeItems.forEach(function(activeItem) {
          if (activeItem.name === category && activeItem.value === value) {
            isActive = true;
          }
      });

      return isActive;
  }

  render() {
      let items = this.props.items.map(function (item, index) {
        return (
          <SearchTabFilterContentItem key={item.category + '_' + item.value + '_' + index} name={item.category} heading={I18n.locale == 'de' ? item.nameDe : item.nameEn} labels={I18n.locale == 'de' ? item.labelsDe : item.labelsEn} submitExtendedFilterChange={this.props.submitExtendedFilterChange} isExtendedFilterChecked={this.props.isExtendedFilterChecked} active={this.isExtendedFilterActive(item.category, item.value)} value={item.value} />
        );
      }.bind(this)).filter(function (el) {
          return el != null;
      });


      return (
        <div className="search-tab__content-wrapper">
          <div className="search-tab__content search-tab__content--filter">
            {items}
          </div>
        </div>
      )

  }
};

SearchTabFilterContent.defaultProps = {
  items: [
    {
        id : '',
        name: '',
        active : false,
        prio: false
    }
  ],
  activeItems : [], // [0, 1, 2]
  name: 'extendedFilters'
};

export default SearchTabFilterContent;
