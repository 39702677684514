import React, { Component } from 'react';

class SearchMenuQueryInput extends Component {

  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);

    this.queryField = null;

    this.setQueryField = element => {
      this.queryField = element;
    };
  }

  componentDidMount() {
      // let $input = $(this.refs.queryField),
      //     availableTags = this.props.autocompletion,
      //     that = this;

      // function split( val ) {
      //   return val.split( /,\s*/ );
      // }

      // function extractLast( term ) {
      //     return split(term).pop();
      // }

      // // if ($input.length > 0) {
      // //     availableTags = this.props.autocompletion.split(', ');
      // // }

      // $input
      //   // don't navigate away from the field on tab when selecting an item
      //   .bind( "keydown", function( event ) {
      //     if ( event.keyCode === $.ui.keyCode.TAB &&
      //         $( this ).autocomplete( "instance" ).menu.active ) {
      //       event.preventDefault();
      //     }
      //   })
      //   .autocomplete({
      //     minLength: 1,
      //     autoFocus: false,

      //     source: function( request, response ) {
      //         let re = $.ui.autocomplete.escapeRegex(extractLast( request.term ));
      //         let matcher = new RegExp( "^" + re, "i" );
      //         let suggestions = $.grep( availableTags, function(item,index){
      //             return matcher.test(item);
      //         });

      //         // delegate back to autocomplete, but extract the last term
      //         response( $.ui.autocomplete.filter(
      //           suggestions, extractLast( request.term ) ).slice(0, 20) );

      //     },
      //     focus: function() {
      //       // prevent value inserted on focus
      //       return false;
      //     },
      //     select: function( event, ui ) {
      //       let terms = split( this.value );
      //       // remove the current input
      //       terms.pop();
      //       // add the selected item
      //       terms.push( ui.item.value );
      //       // add placeholder to get the comma-and-space at the end
      //       terms.push( "" );
      //       this.value = terms.join( ", " );

      //       that.props.submitQueryChange(this.value);

      //       return false;
      //     }
      //   });
  }

  handleChange(e) {
      this.props.submitQueryChange(e.target.value);
  }

  handleKeyUp(e) {
      if (e.which === 13) {
          global.EventHub.trigger('queryInputSubmit');
      }
  }

  render() {
      return (
        <div className='search-menu__query-input-wrapper'>
          <i className='fa fa-search search-menu__query-input-icon' />
          <input type='text' className='search-menu__query-input' placeholder={this.props.placeholder} ref={this.setQueryField} onKeyUp={this.handleKeyUp} onChange={this.handleChange} value={this.props.query}/>
        </div>
      )
  }
};

SearchMenuQueryInput.defaultProps = {
  query : '',
  submitQueryChange : undefined,
  placeholder : 'z.B. Urlaub Gitarre fröhlich',
  autocompletion : '' 
}

export default SearchMenuQueryInput;