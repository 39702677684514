import Helpers from '~/modules/Helpers.js'

const ToggleLink = function(linkNode, config) {

  let _defaultConfig;

  _defaultConfig = {
      linkToggleClass: 'is-active',
      targetToggleClass: 'hide-element',
      lockBodyScrolling: false,
      bodyLockToggleClass: 'body-locked',
      // internals
      beforeMount: function() {
          // instance is available as instance parameter
      },
      afterMount: function() {
          // instance is available as instance parameter
      },
      afterToggle: function() {

      }
  };

  if (linkNode && linkNode.nodeType === 1) {
      this.link = linkNode;
  } else {
      throw new Error('linkNode for ToggleLink was invalid or not of the correct type');
  }

  this.config = Helpers.createConfigObject(config, _defaultConfig);

  this.targetContainer = undefined;

  this._init();
};

ToggleLink.prototype._init = function() {
  this.config.beforeMount(this);

  if (this._mountElements()) {
      this._addEventListeners();
  }

  this.config.afterMount(this);
};

ToggleLink.prototype._mountElements = function() {
  let targetContainer = document.getElementById(this.link.getAttribute('href').replace('#',''));

  if (targetContainer) {
      this.targetContainer = targetContainer;
      return true;
  }

  return false;
};

ToggleLink.prototype._addEventListeners = function() {

  let openCallback = function(e) {
      e.preventDefault();
      this.link.classList.toggle(this.config.linkToggleClass);
      this.targetContainer.classList.toggle(this.config.targetToggleClass);

      if (this.config.lockBodyScrolling) {
          document.body.classList.toggle(this.config.bodyLockToggleClass);
      }

      this.config.afterToggle();
  };

  this.link.addEventListener('click', openCallback.bind(this));
  this.link.addEventListener('forceOpen', openCallback.bind(this));
};

export default ToggleLink;
