import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import classNames from 'classnames/bind';
import TrackFavorite from '~/components/legacy/TrackFavorite.jsx';
import SoundEffectFavorite from '~/components/legacy/SoundEffectFavorite.jsx';

class TrackFavorites extends Component {

  constructor(props) {
    super(props);

    this.trackFavoritesList = null;

    this.setTrackFavoritesList = element => {
      this.trackFavoritesList = element;
    };

    this.handleClick = this.handleClick.bind(this);
    this.handleCartClick = this.handleCartClick.bind(this);

    this.state = {
      opened: false,
      recentlyUpdated : false,
      data : this.props.data,
      trackFavoritesData : this.props.track_favorites_data,
      soundEffectFavoritesData : this.props.sound_effect_favorites_data,
      cart_items : this.props.cart_items,
      showUsageLicenseSelectForTrackId : []
     };
  }

  componentDidMount() {
      global.EventHub.on('updateTrackFavorites', function(data) {
        let that = this;

        this.setState({trackFavoritesData: data[0], 'recentlyUpdated': true});

        if (data[0].length === 0) {
          this.setState({'opened': false});
        }

        setTimeout(function() {
          this.setState({'recentlyUpdated': false});
        }.bind(this), 1000);
      }.bind(this));

      global.EventHub.on('updateSoundEffectFavorites', function(data) {
        let that = this;

        this.setState({soundEffectFavoritesData: data[0], 'recentlyUpdated': true});
        if (data[0].length === 0) {
            this.setState({'opened': false});
        }
        setTimeout(function() {
            this.setState({'recentlyUpdated': false});
        }.bind(this), 1000);
      }.bind(this));

      global.EventHub.on('updateCart', function(data) {
        let that = this;

        this.setState({cart_items: data[0]});

        if (data[0].length === 0) {
          this.setState({'opened': false});
        }
      }.bind(this));


      global.EventHub.on('menuCartOpened', function() {
          this.setState({'opened': false});
      }.bind(this));

      global.EventHub.on('closePopupMenus', function() {
        this.setState({'opened': false});
        this.setState({showUsageLicenseSelectForTrackId: []})
      }.bind(this));

  }

  handleClick() {
    if (!this.state.opened) {
      global.EventHub.trigger('menuFavoritesOpened');
    }

    this.setState({opened: !this.state.opened});
  }

  handleCartClick(trackId) {
    let stateObject = {},
        indexOfTrackId = this.state.showUsageLicenseSelectForTrackId.indexOf(trackId);

    if (indexOfTrackId === -1) {
        stateObject = {};
        stateObject['showUsageLicenseSelectForTrackId'] = [];
        stateObject['showUsageLicenseSelectForTrackId'].push(trackId);
    } else {
      stateObject = {};
      stateObject['showUsageLicenseSelectForTrackId'] = this.state.showUsageLicenseSelectForTrackId;
      stateObject['showUsageLicenseSelectForTrackId'].splice( stateObject['showUsageLicenseSelectForTrackId'].indexOf(trackId), 1 );
    }

    this.setState(stateObject);
  }

  isUsageLicenseSelectVisible(trackId) {
    if (this.state.showUsageLicenseSelectForTrackId.indexOf(trackId) > -1) {
        return true;
    }

    return false;
  }

  trackIsInCart(trackId, cartItems) {
    let i,
        cartItemsLength = cartItems && cartItems.length ? cartItems.length : 0;

    for (i = 0; i < cartItemsLength; i += 1) {
        if (cartItems[i].track_id === trackId) {
            return true;
        }
    }
    return false;
  }

  soundEffectIsInCart(sound_effect_id, cartItems) {
    let i,
        cartItemsLength = cartItems && cartItems.length ? cartItems.length : 0;

    for (i = 0; i < cartItemsLength; i += 1) {
        if (cartItems[i].sound_effect_id === sound_effect_id) {
            return true;
        }
    }
    return false;
  }

  render() {
    let trackFavorites = [],
        soundEffectFavorites = [],
        favorites = [],
        submitButton,
        classes;

    if (this.state.trackFavoritesData) {
        trackFavorites = this.state.trackFavoritesData.map(function (trackFavorite, index) {
          return (
            <TrackFavorite
              locale={this.props.locale}
              i18n={this.props.i18n}
              id={trackFavorite.track_id}
              title={trackFavorite.title}
              free={trackFavorite.free}
              cart_items={this.state.cart_items}
              inCart={this.trackIsInCart(trackFavorite.track_id, this.state.cart_items)}
              discount={trackFavorite.discount}
              subscribedUsageLicenseTypes={this.props.subscribedUsageLicenseTypes}
              usageLicenseTypes={this.props.usage_license_types.filter(function(usageLicenseType) { return usageLicenseType.for_fx === false })}
              handleCartClick={this.handleCartClick}
              showUsageLicenseSelect={this.isUsageLicenseSelectVisible(trackFavorite.track_id)}
              key={"trackFavorite-" + trackFavorite.track_id + '-' + index}
            />
          );
        }.bind(this));
    }

    if (this.state.soundEffectFavoritesData) {
      soundEffectFavorites = this.state.soundEffectFavoritesData.map(function (soundEffectFavorite, index) {
        return (
          <SoundEffectFavorite
            locale={this.props.locale}
            i18n={this.props.i18n}
            id={soundEffectFavorite.sound_effect_id}
            title={soundEffectFavorite.title}
            free={soundEffectFavorite.free}
            cart_items={this.state.cart_items}
            inCart={this.soundEffectIsInCart(soundEffectFavorite.sound_effect_id, this.state.cart_items)}
            discount={soundEffectFavorite.discount}
            subscribedUsageLicenseTypes={this.props.subscribedUsageLicenseTypes}
            usageLicenseTypes={this.props.usage_license_types.filter(function(usageLicenseType) { return usageLicenseType.for_fx === true })}
            handleCartClick={this.handleCartClick}
            showUsageLicenseSelect={this.isUsageLicenseSelectVisible(soundEffectFavorite.sound_effect_id)}
            key={"sound-effect-favorite-" + soundEffectFavorite.sound_effect_id + '-' + index}
          />
        );
      }.bind(this));
    }

    favorites = soundEffectFavorites.concat(trackFavorites)

    classes = classNames({
      'track-favorites': true,
      'is-open' : this.state.opened,
      'is-closed' : !this.state.opened,
      'is-empty' : favorites.length === 0,
      'is-recently-updated' : this.state.recentlyUpdated
    });

    if (this.state.opened) {
      if (favorites.length > 0) {
        return (
          <div className={classes}>
            <div className='track-favorites__status clearfix' onClick={this.handleClick}>
              <div className='track-favorites__circle'>
                <span className='track-favorites__tracks-count'>{favorites.length}</span>
              </div>
              <span className='track-favorites__symbol'>
                <i className='fa fa-heart'></i>
              </span>
            </div>
            <div className='track-favorites__list' ref={this.setTrackFavoritesList}>
              <p className='track-favorites__list-heading'>{this.props.i18n.heading} ({favorites.length})</p>
              {favorites}
            </div>
          </div>
        )
      } else {
        return (
          <div className={classes}>
            <div className='track-favorites__status clearfix' onClick={this.handleClick}>
              <div className='track-favorites__circle'>
                <span className='track-favorites__tracks-count'>{favorites.length}</span>
              </div>
              <span className='track-favorites__symbol'>
                <i className='fa fa-heart-o'></i>
              </span>
            </div>
          </div>
        )
      }
    } else {
      return (
        <div className={classes}>
          <div className='track-favorites__status clearfix' onClick={favorites.length > 0 ? this.handleClick : (function(){})}>
            <div className='track-favorites__circle'>
              <span className='track-favorites__tracks-count'>{favorites.length}</span>
            </div>
            <span className='track-favorites__symbol'>
              <i className={favorites.length > 0 ? 'fa fa-heart' : 'fa fa-heart-o'}></i>
            </span>
            <div className='track-favorites__list-invisible' ref={this.setTrackFavoritesList}>
              <p className='track-favorites__list-heading'>{this.props.i18n.heading} ({favorites.length})</p>
              {favorites}
            </div>
          </div>
        </div>
      )
    }
  }
};

TrackFavorites.defaultProps = {
  data : [],
  i18n : {
    heading : 'Gemerkte Tracks'
  },
  usage_license_types : []
}

// INIT

let elements = document.querySelectorAll('[data-react-component=TrackFavorites]');

elements.forEach(function(element) {
  let reactProps = JSON.parse(element.dataset.reactProps);

  ReactDOM.render(
    <React.StrictMode>
      <TrackFavorites {...reactProps} />
    </React.StrictMode>,
    element
  );
})

export default TrackFavorites;
