import React, { Component } from 'react';

class SoundEffectBarebone extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className='track track--barebone'>
        <div className='track__play-icon' onClick={this.handleClick}>
          <i className='fa fa-play' />
        </div>
        <div className="track__title-wrapper">
          <span className="track__title">
            <span className="track__id">{Math.random() < 0.5 ? 100 : 10000}</span>
            <span className="track__title-value">{Math.random() < 0.5 ? '_________' : '____________________'}</span>
          </span>
          <span className="track__composer">by <a className="track__composer-name">{Math.random() < 0.5 ? '_________' : '____________________'}</a></span>
        </div>
        <div className="track__length hide-lg-down">
          <span className='track__length-value'>{Math.random() < 0.5 ? '______' : '_________'}</span>
        </div>
        <a href='#' className='track__favorite-link'><span><i className="fa fa-heart-o"></i></span><span className='track__favorite-label'>{this.props.locale === 'de' ? 'merken' : 'remember'}</span></a>
        <div className="track__cart-link">
          <a className="track__action-button-add-to-cart"  href="#"><span className='track__cart-link-icon'><i className="track__cart-link-icon-add fa fa-plus"></i><i className="fa fa-shopping-cart track__cart-link-icon-cart"></i></span></a>
        </div>
      </div>
    )
  }
};

export default SoundEffectBarebone;
