// // import $ from 'jquery';

// $.ajaxSetup({
//   headers: {
//     'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
//   }
// });

import AudioPlayer from '~/modules/AudioPlayer.js';
AudioPlayer.init();

import Cart from '~/components/legacy/Cart.jsx';
import Search from '~/components/legacy/Search.jsx';
import SearchMenuInvisible from '~/components/legacy/SearchMenuInvisible.jsx';
import SoundEffectInfo from '~/components/legacy/SoundEffectInfo.jsx';
import SoundEffectListing from '~/components/legacy/SoundEffectListing.jsx';
import SoundEffectSearchMenuInvisible from '~/components/legacy/SoundEffectSearchMenuInvisible.jsx';
import SoundEffectListingSortInputs from '~/components/legacy/SoundEffectListingSortInputs.jsx';
import SoundEffectSearch from '~/components/legacy/SoundEffectSearch.jsx';
import StaticTrackListing from '~/components/legacy/StaticTrackListing.jsx';
import TrackFavorites from '~/components/legacy/TrackFavorites.jsx';
import TrackInfo from '~/components/legacy/TrackInfo.jsx';
import TrackListing from '~/components/legacy/TrackListing.jsx';
import TrackListingSortInputs from '~/components/legacy/TrackListingSortInputs.jsx';

import confetti from 'canvas-confetti';
window.confetti = confetti;

// To see this message, add the following to the `<head>` section in your
// views/layouts/application.html.erb
//
//    <%= vite_client_tag %>
//    <%= vite_javascript_tag 'application' %>
console.log('Vite ⚡️ Rails')

// If using a TypeScript entrypoint file:
//     <%= vite_typescript_tag 'application' %>
//
// If you want to use .jsx or .tsx, add the extension:
//     <%= vite_javascript_tag 'application.jsx' %>

console.log('Visit the guide for more information: ', 'https://vite-ruby.netlify.app/guide/rails')

// Example: Load Rails libraries in Vite.
//
// import * as Turbo from '@hotwired/turbo'
// Turbo.start()
//
// import ActiveStorage from '@rails/activestorage'
// ActiveStorage.start()
//
// // Import all channels.
// const channels = import.meta.globEager('./**/*_channel.js')

// Example: Import a stylesheet in app/frontend/index.css
// import '~/index.css'

// let cartElements = document.querySelectorAll('[data-react-component=Cart]');

// cartElements.forEach(function(element) {
//   let reactProps = element.dataset.props;

//   ReactDOM.render(
//     <React.StrictMode>
//       <Cart props={reactProps} />
//     </React.StrictMode>,
//     element
//   );
// })

import globalModule from '~/modules/global.js';

window.Audiocrowd = window.Audiocrowd || {};
window.Audiocrowd.Global = globalModule;

document.addEventListener('DOMContentLoaded', function() {
    globalModule.init();
});


import TrackTextGenerator from '~/modules/TrackTextGenerator.js';
window.Audiocrowd.TrackTextGenerator = TrackTextGenerator;
