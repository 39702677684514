import React, { Component } from 'react';
import SearchTabContentItem from '~/components/legacy/SearchTabContentItem.jsx';

class SearchTabContent extends Component {
  isActive(itemId) {
      let i,
          activeItemsLength = this.props.activeItems.length;

      for (i = 0; i < activeItemsLength; i += 1) {
          if (this.props.activeItems[i] === itemId) {
              return true;
          }
      }

      return false;
  }

  render() {

      let popularHeading,
          allHeading,
          activeItems = [];

      this.props.activeItems.map(function (itemId, index) {
          console.log("itemId", itemId);
          activeItems.push(this.props.items.filter(function(el) {
              console.log("el.id", el.id);
              return el.id === itemId;
          }));
      }.bind(this));

      activeItems = activeItems.flat();

      activeItems = activeItems.map(function(item, index) {
          console.log('item', item);
          return (
              <SearchTabContentItem id={item.id} name={item.name} url={item.url} menuName={this.props.name} removeStyling={true} active={this.isActive(item.id)} submitMenuItemClick={this.props.submitMenuItemClick} prio={true} key={'search-menu-cloud-item-' + index}/>
          );
      }.bind(this));

      var prioItems = this.props.items.map(function (item, index) {
          if (typeof item.prio !== 'undefined' && item.prio) {
              return (
                <SearchTabContentItem id={item.id} name={item.name} url={item.url} menuName={this.props.name} active={this.isActive(item.id)} submitMenuItemClick={this.props.submitMenuItemClick} prio={true} key={'search-menu-cloud-item-' + index}/>
              );
          }
      }.bind(this)).filter(function (el) {
          return el != null;
      });


      var items = this.props.items.map(function (item, index) {
          if (typeof item.prio === 'undefined' || !item.prio) {
              return (
                <SearchTabContentItem id={item.id} name={item.name} url={item.url} menuName={this.props.name} active={this.isActive(item.id)} submitMenuItemClick={this.props.submitMenuItemClick} prio={false} key={'search-menu-cloud-item-' + index}/>
              );
          }
      }.bind(this)).filter(function (el) {
          return el != null;
      });

      console.log('activeItems: ', activeItems);
      console.log('prioItems: ', prioItems);
      console.log('items: ', items);

      if (prioItems.length > 0) {
          popularHeading = (<div className="search-tab__content-group-hint">{this.props.locale === 'de' ? 'Beliebt' : 'popular'}</div>);
          allHeading = (<div className="search-tab__content-group-hint">{this.props.locale === 'de' ? 'Alle' : 'all'}</div>);
      }

      return (
        <div className="search-tab__content-wrapper">
          <div className="search-tab__content">
            {activeItems}
            {popularHeading}
            {prioItems}
            {allHeading}
            {items}
          </div>
        </div>
      )

  }
};

SearchTabContent.defaultProps = {
  items: [
    {
      id : '',
      name: '',
      active : false,
      prio: false
    }
  ],
  activeItems : [], // [0, 1, 2]
  name: 'genre'
};

export default SearchTabContent;
