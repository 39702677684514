import React, { Component } from 'react';
// import $ from 'jquery';

class CartItem extends Component{

  constructor(props) {
    super(props);

    this.playTrack = this.playTrack.bind(this);
    this.handleRemoveClick = this.handleRemoveClick.bind(this);

    this.state = {
      playing: false
    };
  }

  componentDidMount() {
    let that = this;

    global.EventHub.on('audioPlayerPlay', function(data) {
      if (data[0].id === that.props.id) {
        that.setState({
          playing : true
        });
      }
    });


    global.EventHub.on('audioPlayerStop', function() {
      if (that.state.playing) {
        that.setState({
          playing : false
        });
      }
    });

    global.EventHub.on('audioPlayerEnded', function() {
      if (that.state.playing) {
        that.setState({
          playing : false
        });
      }
    });
  }

  handleRemoveClick(e) {
    let cartItemId = this.props.id,
        updateUrl;

    e.preventDefault();

    if (this.props.exposure_pack_id) {
        updateUrl = "/cart?exposure_pack_id=" + this.props.exposure_pack_id + "&usage_license_type_id=" + this.props.usageLicenseType.id + "&remove=true";
    } else if (this.props.sound_effect_id) {
        updateUrl = "/cart?sound_effect_id=" + this.props.sound_effect_id + "&usage_license_type_id=" + this.props.usageLicenseType.id + "&remove=true";
    } else {
        updateUrl = "/cart?track_id=" + this.props.id + "&usage_license_type_id=" + this.props.usageLicenseType.id + "&remove=true";
    }

    $.ajax({
      url: updateUrl,
      type: 'POST'
    }).done(function(data) {
        global.EventHub.trigger('updateCart', data)
    }.bind(this))
  }

  playTrack(e) {
    e.preventDefault();
    if (this.props.sound_effect_id) {
      global.EventHub.trigger('playSoundEffect', this.props.sound_effect_id);
    } else {
      global.EventHub.trigger('playTrack', this.props.id);
    }

    global.EventHub.trigger('updateTrackInfo', this.props);

    this.setState({
      playing: !this.state.playing
    });
  }

  render() {
    let price;

    if (this.props.discount) {
      price = this.props.usageLicenseType.formatted_country_discount_gross_price;
    } else if (this.props.includedInSubscription) {
      price = this.props.locale === 'de' ? 'kostenlos' : 'free';
    } else {
      price = this.props.usageLicenseType.formatted_country_gross_price;
    }

    return (
      <div className="cart__item">
        <div className='cart__item-name'>
          <a href="#" onClick={this.playTrack}>
            <span className='cart__item-price'>
              {price}
            </span>
            <div className='cart__item-title-info'>
              <span className='cart__item-play-icon'>
                <i className={this.state.playing ? 'fa fa-pause' : 'fa fa-play' } />
              </span>
              <span className='cart__item__title'>
                <span className='cart__item__id'>
                    {this.props.id}
                </span>
                {this.props.title}
              </span>
              <span className='cart__item-usage-license-type-name'>
                {this.props.usageLicenseType.name}
              </span>
            </div>
          </a>
        </div>
        <a href="#" onClick={this.handleRemoveClick} className='cart__item-remove'>
          <i className='fa fa-trash'></i>
        </a>
      </div>
    )
  }
};

export default CartItem;