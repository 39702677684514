// const $ = require('jquery');

const ProductFetcher = function(buttonNode, config = {}) {

    var _defaultConfig;

    _defaultConfig = {
        // internals
        beforeMount : function() {
            // instance is available as instance parameter
        },
        afterMount : function() {
            // instance is available as instance parameter
        }
    };

    if (buttonNode && buttonNode.nodeType === 1) {
        this.buttonNode = buttonNode;
    } else {
        throw new Error('ButtonNode for ProductFetcher was invalid or not of the correct type');
    }

    this.config = Object.assign({}, _defaultConfig, config);

    this.state = {};

    this._init();
};

ProductFetcher.prototype._init = function() {
    this.config.beforeMount(this);
    this._mountElements();
    this._addEventHandlerForFetchButton();

    this.config.afterMount(this);
};

ProductFetcher.prototype._mountElements = function() {

};

ProductFetcher.prototype._addEventHandlerForFetchButton = function() {
    $(this.buttonNode).on('click', (e) => {
        let url = $('input#fetch_url').val(),
            productId = this._createProductFromInput(url);

        e.preventDefault();

        this._fetchProductData(productId).then((data) => {
            console.log(JSON.parse(data));
            this._populateInputs(JSON.parse(data));
        }).catch((data) => {
            alert(data);
        });
    });
};

ProductFetcher.prototype._createProductFromInput = function(urlString) {

    let productId,
        regex,
        matches;

    if (typeof(urlString) !== "undefined" && typeof(urlString) === "string") {
        regex = /p\/(product)?(\/)?([A-Za-z0-9]+)/;
        matches = urlString.match(regex);
        productId = matches[3];

        console.log(matches)

        return productId;
    }

    return false;
};

ProductFetcher.prototype._fetchProductData = function(productId) {
    return new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest();
        xhr.open("GET", "/e/affiliate_products/get_product_data/" + productId);
        xhr.onload = () => resolve(xhr.responseText);
        xhr.onerror = () => reject(xhr.statusText);
        xhr.send();
    });
};

ProductFetcher.prototype._populateInputs = function(data) {

    // $('input#affiliate_product_url_de').val(data.detailPageUrl);
    $('input#affiliate_product_url_de').val('https://www.amazon.de/dp/' + data.amazonId);
    $('input#affiliate_product_url_en').val('https://www.amazon.com/dp/' + data.amazonId);
    $('input#affiliate_product_amazon_id').val(data.amazonId);


    if ($('input#affiliate_product_title_de')[0].value === "") {
        $('input#affiliate_product_title_de').val(data.title);
    }

    if ($('input#affiliate_product_short_title_de')[0].value === '') {
        $('input#affiliate_product_short_title_de').val(data.title);
    }

    if ($('input#affiliate_product_title_en')[0].value === "") {
      $('input#affiliate_product_title_en').val(data.title);
  }

  if ($('input#affiliate_product_short_title_en')[0].value === '') {
      $('input#affiliate_product_short_title_en').val(data.title);
  }


    if ($('input#affiliate_product_image_response_url')[0].value === '') {
        $('input#affiliate_product_image_response_url').val(data.imageLargeUrl);
    }

    // $('input#product_medium_image_url').val(data.imageMediumUrl);
    // $('input#product_small_image_url').val(data.imageSmallUrl);
    // $('input#product_price').val(data.price);
    // $('input#product_currency_symbol').val(data.currency);
    // $('input#product_amount_saved').val(data.amountSaved);
    // $('input#product_amount_saved_currency_symbol').val(data.amountSavedCurrencySymbol);
};

export default ProductFetcher;