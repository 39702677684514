import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import classNames from 'classnames/bind';

import Pagination from '~/components/legacy/Pagination.jsx';
import SoundEffectBarebone from '~/components/legacy/SoundEffectBarebone.jsx';
import SoundEffect from '~/components/legacy/SoundEffect.jsx';
import UsageLicenseTypeSelectSidebar from '~/components/legacy/UsageLicenseTypeSelectSidebar.jsx';
// import $ from 'jquery';

class SoundEffectListing extends Component {

  // mixins: [SearchInProgressMixin, SoundEffectFavoriteMixin],

  constructor(props) {
    super(props)

    this.handleCartClick = this.handleCartClick.bind(this);
    this.handleFreeDownloadClick = this.handleFreeDownloadClick.bind(this);
    this.handleFavoriteClick = this.handleFavoriteClick.bind(this);

    this.state = {
      showUsageLicenseSelectForSoundEffectId: [],
      showFreeDownloadDialogForSoundEffectId: [],
      data: this.props.data,
      pagination: this.props.pagination,
      active_sound_effect_id: this.props.active_sound_effect_id,
      cart_items: this.props.cart_items,
      currentQuery: {
        soundEffectTags: [],
      },
      searchInProgress: false,
      animationFinished: true,
      soundEffectFavorites: this.props.soundEffectFavorites

    }
  }

  componentDidMount() {
    setTimeout(function() {
      global.EventHub.trigger('requestUpdateSoundEffectListing');
    }, 10)

    global.EventHub.on('updateSoundEffectListing', function (data) {
      this.setState({ data: data[0].sound_effects, pagination: data[0].pagination });
    }.bind(this));

    global.EventHub.on('initialUpdateSoundEffectListing', function (data) {
      this.setState({ data: data[0].sound_effects, pagination: data[0].pagination });
      global.EventHub.trigger('playerInitSoundEffect', data[0].sound_effects[0].id);
    }.bind(this));


    global.EventHub.on('paginationClick', function (data) {
      this.scrollToListStart();
    }.bind(this));

    global.EventHub.on('playSoundEffect', function (data) {
      this.setState({ active_sound_effect_id: data[0], activeSoundEffectVariantId: '' })
    }.bind(this));

    global.EventHub.on('playSoundEffectVariant', function (data) {
      this.setState({ active_sound_effect_id: data[1], activeSoundEffectVariantId: data[0] })
    }.bind(this));

    global.EventHub.on('updateCart', function (data) {
      this.setState({ cart_items: data[0] });
    }.bind(this));

    global.EventHub.on('closePopupMenus', function () {
      this.setState({ showUsageLicenseSelectForSoundEffectId: [], showFreeDownloadDialogForSoundEffectId: [] })
    }.bind(this));

    global.EventHub.on('updateItemIdList', function (payload) {
      this.setState({ currentQuery: payload[0] });
    }.bind(this));



    global.EventHub.on('fireSearch', function () {
      $("html, body").animate({ scrollTop: $('.track-listing').offset().top - 320 }, "slow");
      this.setState({ searchInProgress: true, animationFinished: false });
    }.bind(this));

    global.EventHub.on('fireSearchWithoutAnimation', function () {
      this.setState({ searchInProgress: false, animationFinished: true });
    }.bind(this));

    global.EventHub.on('searchSuccess', function () {
      setTimeout(function () {
        this.setState({ searchInProgress: false });
      }.bind(this), 200);
      setTimeout(function () {
        this.setState({ animationFinished: true });
      }.bind(this), 400);
    }.bind(this));


    global.EventHub.on('updateSoundEffectFavorites', function (data) {
      this.setState({ soundEffectFavorites: data[0] });
    }.bind(this));
  }


  handleFavoriteClick(sound_effect_id) {
    $.ajax({
      url: "/sound_effect_favorite?sound_effect_id=" + sound_effect_id,
      type: 'POST'
    }).done(function (data) {
      if (!(this.soundEffectIsFavorited(sound_effect_id, this.state.soundEffectFavorites))) {
        global.EventHub.trigger('soundEffectFavorited', sound_effect_id);
      }

      global.EventHub.trigger('updateSoundEffectFavorites', data)
    }.bind(this));
  }

  soundEffectIsFavorited(sound_effect_id, favorites) {
    let i,
      favoritesLength = favorites && favorites.length ? favorites.length : 0;

    for (i = 0; i < favoritesLength; i += 1) {
      if (favorites[i].sound_effect_id === sound_effect_id) {
        return true;
      }
    }
    return false;
  }

  handleCartClick(sound_effect_id) {
    let stateObject = {},
      indexOfSoundEffectId = this.state.showUsageLicenseSelectForSoundEffectId.indexOf(sound_effect_id);

    if (indexOfSoundEffectId === -1) {
      stateObject = {};
      stateObject['showUsageLicenseSelectForSoundEffectId'] = [];
      stateObject['showUsageLicenseSelectForSoundEffectId'].push(sound_effect_id);
    } else {
      global.EventHub.trigger('closePopupMenus');
    }

    this.setState(stateObject);
  }

  handleFreeDownloadClick(sound_effect_id) {
    let stateObject = {},
      indexOfSoundEffectId = this.state.showFreeDownloadDialogForSoundEffectId.indexOf(sound_effect_id);

    if (indexOfSoundEffectId === -1) {
      stateObject = {};
      stateObject['showFreeDownloadDialogForSoundEffectId'] = [];
      stateObject['showFreeDownloadDialogForSoundEffectId'].push(sound_effect_id);
    } else {
      global.EventHub.trigger('closePopupMenus');
    }

    this.setState(stateObject);
  }

  scrollToListStart() {
    $('html, body').animate({
      scrollTop: $(this.refs.trackListing).offset().top - 260
    }, 400);
  }

  soundEffectIsInCart(sound_effect_id, cartItems) {
    let i,
      cartItemsLength = cartItems && cartItems.length ? cartItems.length : 0;

    for (i = 0; i < cartItemsLength; i += 1) {
      if (cartItems[i].sound_effect_id === sound_effect_id) {
        return true;
      }
    }
    return false;
  }

  isUsageLicenseSelectVisible(sound_effect_id) {
    if (this.state.showUsageLicenseSelectForSoundEffectId.indexOf(sound_effect_id) > -1) {
      return true;
    }

    return false;
  }


  isFreeDownloadDialogVisible(sound_effect_id) {
    if (this.state.showFreeDownloadDialogForSoundEffectId.indexOf(sound_effect_id) > -1) {
      return true;
    }

    return false;
  }

  shouldRenderUsageLicenseTypeSelect(sound_effect_id) {
    let shouldRender = false;

    for (var i = 0; i < this.state.showUsageLicenseSelectForSoundEffectId.length; i += 1) {
      if (this.state.showUsageLicenseSelectForSoundEffectId[i] === sound_effect_id && typeof this.props.exposure_pack.id === 'undefined') {
        shouldRender = true;
      }
    }

    return shouldRender;
  }

  getContent() {
    return this.props.content.map(function (content, index) {
      return (
        <div className={this.props.content.length > 4 ? 'track-listing__content track-listing__content--long' : 'track-listing__content'} key={'sound_effect_listing_content_' + index}>
          <details className='track-listing__content-details' itemProp="mainEntity" itemScope itemType="https://schema.org/Question" open={content.open ? true : false}>
            <summary className='track-listing__content-title-wrapper' itemProp="name">{content.title}</summary>
            <div className='track-listing__content-description' itemProp="acceptedAnswer" itemScope itemType="https://schema.org/Answer"><div itemProp="text" dangerouslySetInnerHTML={{ __html: content.description }}></div></div>
          </details>
        </div>
      )
    }.bind(this))
  }

  getNameFor(itemName, id) {
    return this.props.itemNames[itemName][id];
  }

  isActiveItem(itemName, id) {
    return (this.state.currentQuery[itemName].indexOf(id) > -1);
  }

  getSelectedUsageLicenseTypeIdsForExposurePack() {
    var ids = [],
      that = this,
      exposurePackId = this.props.exposure_pack.id || that.state.data.exposure_pack.id;
    cart_items = this.state.cart_items || this.props.cart_items;

    cart_items.forEach(function (element) {
      if (element.exposure_pack_id === exposurePackId) {
        ids.push(element.usage_license_type_id);
      }
    });

    return ids;
  }


  render() {
    var activeSoundEffectId = this.state.active_sound_effect_id;
    var activeSoundEffectVariantId = this.state.activeSoundEffectVariantId;
    var content = [];
    var similarSoundEffectHeading;
    var clientListing = [];
    var soundEffects = this.state.data.map(function (soundEffect, index) {
      var nextProps;
      var currentProps = {
        id: soundEffect.id,
        sound_effect_id: soundEffect.id,
        composerName: soundEffect.composer_name,
        composerUrl: soundEffect.composer_url,
        showcaseUrl: soundEffect.showcase_url,
        i18n: this.props.i18n,
        title: soundEffect.title,
        display_title: soundEffect.display_title,
        active: soundEffect.id === activeSoundEffectId,
        activeSoundEffectVariantId: activeSoundEffectVariantId,
        key: "sound-effect-" + soundEffect.id,
        length: soundEffect.length,
        description: this.props.locale === 'de' ? soundEffect.description_de : soundEffect.description_en,
        inCart: this.soundEffectIsInCart(soundEffect.id, this.state.cart_items),
        free: soundEffect.free,
        hideItemIdList: this.props.hideItemIdList,
        hideAddToCart: this.props.hideAddToCart,
        sound_effect_tag_id_list: soundEffect.sound_effect_tag_id_list,
        isActiveItem: this.isActiveItem,
        discount: soundEffect.discount,
        copyrightYear: soundEffect.copyright_year,
        recently_activated: soundEffect.recently_activated,
        usageLicenseTypes: this.props.usageLicenseTypes.filter(function (usageLicenseType) { return usageLicenseType.for_fx === true }),
        subscribedUsageLicenseTypes: this.props.subscribedUsageLicenseTypes,
        pricing_path: this.props.pricing_path,
        cart_items: this.state.cart_items,
        soundEffectFavorites: this.state.soundEffectFavorites,
        favorited: this.soundEffectIsFavorited(soundEffect.id, this.state.soundEffectFavorites),
        handleFavoriteClick: this.handleFavoriteClick,
        showUsageLicenseSelect: this.isUsageLicenseSelectVisible(soundEffect.id),
        showFreeDownloadDialog: this.isFreeDownloadDialogVisible(soundEffect.id),
        handleCartClick: this.handleCartClick,
        handleFreeDownloadClick: this.handleFreeDownloadClick,
        show_download_button: this.props.show_download_button,
        search_in_progress: this.state.searchInProgress,
        exposure_pack: this.props.exposure_pack,
        slim: this.props.slim,
        score: soundEffect.score,
        getNameFor: this.getNameFor,
        freeMode: this.props.freeMode,
        sound_effect_variants: soundEffect.sound_effect_variants,
        sound_effect_image_markup: soundEffect.sound_effect_image_markup,
        youtube_content_id_enabled: soundEffect.youtube_content_id_enabled,
        locale: this.props.locale
      }

      if (typeof this.state.data[index + 1] !== 'undefined') {
        var nextSoundEffect = this.state.data[index + 1];
        var nextProps = {
          id: nextSoundEffect.id,
          sound_effect_id: nextSoundEffect.id,
          composerName: nextSoundEffect.composer_name,
          composerUrl: nextSoundEffect.composer_url,
          showcaseUrl: nextSoundEffect.showcase_url,
          i18n: this.props.i18n,
          title: nextSoundEffect.title,
          display_title: nextSoundEffect.display_title,
          active: nextSoundEffect.id === activeSoundEffectId,
          activeSoundEffectVariantId: activeSoundEffectVariantId,
          key: "sound-effect-" + nextSoundEffect.id,
          length: nextSoundEffect.length,
          description: this.props.locale === 'de' ? nextSoundEffect.description_de : nextSoundEffect.description_en,
          inCart: this.soundEffectIsInCart(nextSoundEffect.id, this.state.cart_items),
          free: nextSoundEffect.free,
          hideItemIdList: this.props.hideItemIdList,
          hideAddToCart: this.props.hideAddToCart,
          sound_effect_tag_id_list: nextSoundEffect.sound_effect_tag_id_list,
          isActiveItem: this.isActiveItem,
          discount: nextSoundEffect.discount,
          copyrightYear: nextSoundEffect.copyright_year,
          recently_activated: nextSoundEffect.recently_activated,
          usageLicenseTypes: this.props.usageLicenseTypes.filter(function (usageLicenseType) { return usageLicenseType.for_fx === true }),
          subscribedUsageLicenseTypes: this.props.subscribedUsageLicenseTypes,
          pricing_path: this.props.pricing_path,
          cart_items: this.state.cart_items,
          soundEffectFavorites: this.state.soundEffectFavorites,
          favorited: this.soundEffectIsFavorited(nextSoundEffect.id, this.state.soundEffectFavorites),
          handleFavoriteClick: this.handleFavoriteClick,
          showUsageLicenseSelect: this.isUsageLicenseSelectVisible(nextSoundEffect.id),
          showFreeDownloadDialog: this.isFreeDownloadDialogVisible(nextSoundEffect.id),
          handleCartClick: this.handleCartClick,
          handleFreeDownloadClick: this.handleFreeDownloadClick,
          show_download_button: this.props.show_download_button,
          exposure_pack: this.props.exposure_pack,
          slim: this.props.slim,
          score: nextSoundEffect.score,
          getNameFor: this.getNameFor,
          freeMode: this.props.freeMode,
          sound_effect_variants: nextSoundEffect.sound_effect_variants,
          handleVariantClick: this.handleVariantClick,
          sound_effect_image_markup: nextSoundEffect.sound_effect_image_markup,
          youtube_content_id_enabled: nextSoundEffect.youtube_content_id_enabled,
          locale: this.props.locale
        }
      }

      if (typeof this.state.data[index - 1] !== 'undefined') {
        var prevSoundEffect = this.state.data[index - 1];
        var prevProps = {
          id: prevSoundEffect.id,
          sound_effect_id: prevSoundEffect.id,
          composerName: prevSoundEffect.composer_name,
          composerUrl: prevSoundEffect.composer_url,
          showcaseUrl: prevSoundEffect.showcase_url,
          i18n: this.props.i18n,
          title: prevSoundEffect.title,
          display_title: prevSoundEffect.display_title,
          active: prevSoundEffect.id === activeSoundEffectId,
          activeSoundEffectVariantId: activeSoundEffectVariantId,
          key: "sound-effect-" + prevSoundEffect.id,
          length: prevSoundEffect.length,
          description: this.props.locale === 'de' ? prevSoundEffect.description_de : prevSoundEffect.description_en,
          inCart: this.soundEffectIsInCart(prevSoundEffect.id, this.state.cart_items),
          free: prevSoundEffect.free,
          hideItemIdList: this.props.hideItemIdList,
          hideAddToCart: this.props.hideAddToCart,
          sound_effect_tag_id_list: prevSoundEffect.sound_effect_tag_id_list,
          isActiveItem: this.isActiveItem,
          discount: prevSoundEffect.discount,
          copyrightYear: prevSoundEffect.copyright_year,
          recently_activated: prevSoundEffect.recently_activated,
          usageLicenseTypes: this.props.usageLicenseTypes.filter(function (usageLicenseType) { return usageLicenseType.for_fx === true }),
          subscribedUsageLicenseTypes: this.props.subscribedUsageLicenseTypes,
          pricing_path: this.props.pricing_path,
          cart_items: this.state.cart_items,
          soundEffectFavorites: this.state.soundEffectFavorites,
          favorited: this.soundEffectIsFavorited(prevSoundEffect.id, this.state.soundEffectFavorites),
          handleFavoriteClick: this.handleFavoriteClick,
          showUsageLicenseSelect: this.isUsageLicenseSelectVisible(prevSoundEffect.id),
          showFreeDownloadDialog: this.isFreeDownloadDialogVisible(prevSoundEffect.id),
          handleCartClick: this.handleCartClick,
          handleFreeDownloadClick: this.handleFreeDownloadClick,
          show_download_button: this.props.show_download_button,
          exposure_pack: this.props.exposure_pack,
          slim: this.props.slim,
          score: prevSoundEffect.score,
          getNameFor: this.getNameFor,
          freeMode: this.props.freeMode,
          sound_effect_variants: prevSoundEffect.sound_effect_variants,
          handleVariantClick: this.handleVariantClick,
          sound_effect_image_markup: prevSoundEffect.sound_effect_image_markup,
          youtube_content_id_enabled: prevSoundEffect.youtube_content_id_enabled,
          locale: this.props.locale
        }
      }

      if (index === 2 && this.props.content && this.props.content.length > 0 && this.state.pagination.current_page === 1) {
        content = this.getContent();
      } else {
        content = [];
      }

      if (index === 0 && this.props.soundEffectShow) {
        similarSoundEffectHeading = <div><hr></hr><h2 className='text-like-h4'>{this.props.locale === 'de' ? 'Weitere ähnliche Sound-Effekte:' : 'Similar sound effects:'}</h2></div>
      }

      // if (index === 10 && this.props.clientListing && this.props.clientListing.length > 0 && this.state.pagination.current_page === 1) {
      //   clientListing = this.props.clientListing.map(function(client, index) {
      //     return (
      //       <div className='track-listing__client' key={'sound_effect_listing_client_' + index}>
      //         {client}
      //       </div>
      //     )
      //   }.bind(this));
      // } else {
      //   clientListing = [];
      // }

      return (
        <div key={'sound_effect_wrapper_' + soundEffect.id}>
          <SoundEffect {...currentProps} next_sound_effect={nextProps} prev_sound_effect={prevProps} key={'sound_effect_' + soundEffect.id} />
          {similarSoundEffectHeading && index === 0 ? similarSoundEffectHeading : ''}
          {content.length === 0 ? '' : (<div className='track-listing__content-wrapper' itemScope itemType="https://schema.org/FAQPage"><h2 className='text-like-h4'>{this.props.locale === 'de' ? 'Oft gestellte Fragen' : 'FAQ'}</h2><div className='track-listing__content-symbol'><i className='fa fa-info'></i></div><div className='track-listing__contents'>{content}</div></div>)}
          {clientListing.length === 0 ? '' : (<div className='track-listing__client-wrapper'><h2 className='text-like-h4'>{this.props.locale === 'de' ? 'Diese Marken vertrauen auf unsere Komponisten' : 'These brands rely on our composers'}</h2><div className='track-listing__clients'>{clientListing}</div></div>)}
        </div>
      );

    }.bind(this));

    var pagination = function () {
      if (this.props.show_pagination && this.state.pagination.total_count > 10) {
        return (
          <Pagination data={this.state.pagination} i18n={this.props.i18n} genre_id={this.props.genre_id} composer_page_permalink={this.props.composer_page_permalink} />
        )
      }
    }.bind(this);

    var spinner = function () {
      if (this.state.searchInProgress) {
        return (
          <div className='loading-bars'>
            <span className='loading-bars__element'></span>
            <span className='loading-bars__element'></span>
            <span className='loading-bars__element'></span>
            <span className='loading-bars__element'></span>
            <span className='loading-bars__element'></span>
            <span className='loading-bars__element'></span>
            <span className='loading-bars__element'></span>
            <span className='loading-bars__element'></span>
            <span className='loading-bars__element'></span>
            <span className='loading-bars__element'></span>
            <span className='loading-bars__element'></span>
            <span className='loading-bars__element'></span>
            <span className='loading-bars__element'></span>
            <span className='loading-bars__element'></span>
            <span className='loading-bars__element'></span>
          </div>
        )
      }
    }.bind(this);

    var smiley = function () {
      return (
        <div className='track-listing-smiley'>
          <div className='track-listing-smiley-icon'>
            <i className='fa fa-frown-o' />
          </div>
        </div>
      )
    }.bind(this);

    // var trackBarebone = function() {
    //   return (
    //     <div className='track track--barebone'>
    //       <div className='sound_effect__play-icon' onClick={this.handleClick}>
    //         <i className='fa fa-play' />
    //       </div>
    //       <div className="sound_effect__title-wrapper">
    //         <span className="sound_effect__title">
    //           <span className="sound_effect__id">{Math.random() < 0.5 ? 100 : 10000}</span>
    //           <span className="sound_effect__title-value">{Math.random() < 0.5 ? '_________' : '____________________'}</span>
    //         </span>
    //         <span className="sound_effect__composer">by <a className="sound_effect__composer-name">{Math.random() < 0.5 ? '_________' : '____________________'}</a></span>
    //       </div>
    //       <div className="sound_effect__length hide-lg-down">
    //         <span className='sound_effect__length-value'>{Math.random() < 0.5 ? '______' : '_________'}</span>
    //       </div>
    //       <a href='#' className='sound_effect__favorite-link'><span><i className="fa fa-heart-o"></i></span><span className='sound_effect__favorite-label'>{this.props.locale === 'de' ? 'merken' : 'remember'}</span></a>
    //       <a className="sound_effect__layout-download" target="_blank" title="Layout-Download">>
    //           <i className='fa fa-download'></i>
    //           <span className='sound_effect__download-link-label'>Demo</span>
    //       </a>
    //       <div className="sound_effect__cart-link">
    //         <a className="sound_effect__action-button-add-to-cart"  href="#"><span className='sound_effect__cart-link-icon'><i className="sound_effect__cart-link-icon-add fa fa-plus"></i><i className="fa fa-shopping-cart sound_effect__cart-link-icon-cart"></i></span></a>
    //       </div>
    //     </div>
    //   )
    // }.bind(this);

    var classes = classNames({
      'track-listing': true,
      'track-listing--slim': this.props.slim,
      'track-listing-with-pagination': this.props.show_pagination,
      'is-spinning': this.state.searchInProgress,
      'is-empty': this.state.data.length === 0,
      'track-listing-animation-finished': this.state.animationFinished,
      'track-listing--track-show': this.props.soundEffectShow
    });

    var soundEffectsDuration = 0;

    for (let i = 0; i < this.state.data.length; i += 1) {
      soundEffectsDuration += this.state.data[i].length;
    }

    if (typeof this.props.exposure_pack !== 'undefined' && this.props.exposure_pack.id) {
      return (
        <div className='row'>
          <div className='show-md-down col3 col6-md offset3-md col12-sm search-tabs-wrapper'>
            <UsageLicenseTypeSelectSidebar
              addToCart={this.props.i18n.add_to_cart}
              free={this.props.free}
              discount={this.props.discount}
              exposure_pack={this.props.exposure_pack}
              usageLicenseTypes={this.props.usageLicenseTypes.filter(function (usageLicenseType) { return usageLicenseType.for_fx === true })}
              updateCart={this.updateCart}
              pricing_path={this.props.pricing_path}
              selectedUsageLicenseTypeIds={this.getSelectedUsageLicenseTypeIdsForExposurePack()}
              i18n={this.props.i18n}
              visible={this.props.showUsageLicenseSelect}
              position='bottom'
              show_download_button={this.props.show_download_button}
              title={this.props.exposure_pack.title}
              soundEffectCount={soundEffects.length}
              totalDuration={this.props.exposure_pack.duration} />
          </div>
          <div className='col9 col12-md-down'>
            <div className={classes} ref='trackListing'>
              {(this.state.data.length === 0 && (typeof this.state.pagination.current_page === 'undefined')) ? this.getContent() : ''}
              {(tracks.length === 0 && this.state.data.length === 0 && (typeof this.state.pagination.current_page === 'undefined')) ? this.getContent() : ''}
              {tracks.length > 0 ? tracks : (this.state.data.length == 0 ? '' : I18n.t('no_songs_available'))}
              {pagination()}
              {spinner()}
              {smiley()}
            </div>
          </div>
          <div className='col3 col6-md offset3-md col12-sm search-tabs-wrapper'>
            <UsageLicenseTypeSelectSidebar
              addToCart={this.props.i18n.add_to_cart}
              free={this.props.free}
              discount={this.props.discount}
              exposure_pack={this.props.exposure_pack}
              usageLicenseTypes={this.props.usageLicenseTypes.filter(function (usageLicenseType) { return usageLicenseType.for_fx === true })}
              updateCart={this.updateCart}
              pricing_path={this.props.pricing_path}
              selectedUsageLicenseTypeIds={this.getSelectedUsageLicenseTypeIdsForExposurePack()}
              i18n={this.props.i18n}
              visible={this.props.showUsageLicenseSelect}
              position='bottom'
              show_download_button={this.props.show_download_button}
              title={this.props.exposure_pack.title}
              trackCount={this.state.pagination.total_count}
              totalDuration={this.props.exposure_pack.duration} />
          </div>
        </div>

      )

    } else {
      if (this.props.showHeading) {
        return (
          <div className={classes} ref='trackListing'>
            <div className="track-listing__count"><span className='track-listing__count-value'>{this.state.pagination.total_count}</span> {this.state.pagination.total_count === 1 ? 'sound effect' : 'sound effects'}</div>
            {(soundEffects.length === 0 && this.state.data.length === 0 && (typeof this.state.pagination.current_page === 'undefined')) ? <SoundEffectBarebone /> : ''}
            {(soundEffects.length === 0 && this.state.data.length === 0 && (typeof this.state.pagination.current_page === 'undefined')) ? <SoundEffectBarebone /> : ''}
            {(soundEffects.length === 0 && this.state.data.length === 0 && (typeof this.state.pagination.current_page === 'undefined')) ? <SoundEffectBarebone /> : ''}
            {(soundEffects.length === 0 && this.state.data.length === 0 && (typeof this.state.pagination.current_page === 'undefined')) ? (<div className='track-listing__content-wrapper' itemScope itemType="https://schema.org/FAQPage"><h2 className='text-like-h4'>{this.props.locale === 'de' ? 'Oft gestellte Fragen' : 'FAQ'}</h2><div className='track-listing__content-symbol'><i className='fa fa-info'></i></div><div className='track-listing__contents'>{this.getContent()}</div></div>) : ''}
            {(soundEffects.length === 0 && this.state.data.length === 0 && (typeof this.state.pagination.current_page === 'undefined')) ? <SoundEffectBarebone /> : ''}
            {(soundEffects.length === 0 && this.state.data.length === 0 && (typeof this.state.pagination.current_page === 'undefined')) ? <SoundEffectBarebone /> : ''}
            {(soundEffects.length === 0 && this.state.data.length === 0 && (typeof this.state.pagination.current_page === 'undefined')) ? <SoundEffectBarebone /> : ''}
            {(soundEffects.length === 0 && this.state.data.length === 0 && (typeof this.state.pagination.current_page === 'undefined')) ? <SoundEffectBarebone /> : ''}
            {(soundEffects.length === 0 && this.state.data.length === 0 && (typeof this.state.pagination.current_page === 'undefined')) ? <SoundEffectBarebone /> : ''}
            {(soundEffects.length === 0 && this.state.data.length === 0 && (typeof this.state.pagination.current_page === 'undefined')) ? <SoundEffectBarebone /> : ''}
            {soundEffects.length > 0 ? soundEffects : (this.props.data.length == 0 ? '' : I18n.t('no_songs_available'))}
            {pagination()}
            {spinner()}
            {smiley()}
          </div>
        )
      } else {
        return (
          <div className={classes} ref='trackListing'>
            {(soundEffects.length === 0 && this.state.data.length === 0 && (typeof this.state.pagination.current_page === 'undefined')) ? <SoundEffectBarebone /> : ''}
            {(soundEffects.length === 0 && this.state.data.length === 0 && (typeof this.state.pagination.current_page === 'undefined')) ? <SoundEffectBarebone /> : ''}
            {(soundEffects.length === 0 && this.state.data.length === 0 && (typeof this.state.pagination.current_page === 'undefined')) ? <SoundEffectBarebone /> : ''}
            {(soundEffects.length === 0 && this.state.data.length === 0 && (typeof this.state.pagination.current_page === 'undefined')) ? (<div className='track-listing__content-wrapper' itemScope itemType="https://schema.org/FAQPage"><h2 className='text-like-h4'>{this.props.locale === 'de' ? 'Oft gestellte Fragen' : 'FAQ'}</h2><div className='track-listing__content-symbol'><i className='fa fa-info'></i></div><div className='track-listing__contents'>{this.getContent()}</div></div>) : ''}
            {(soundEffects.length === 0 && this.state.data.length === 0 && (typeof this.state.pagination.current_page === 'undefined')) ? <SoundEffectBarebone /> : ''}
            {(soundEffects.length === 0 && this.state.data.length === 0 && (typeof this.state.pagination.current_page === 'undefined')) ? <SoundEffectBarebone /> : ''}
            {(soundEffects.length === 0 && this.state.data.length === 0 && (typeof this.state.pagination.current_page === 'undefined')) ? <SoundEffectBarebone /> : ''}
            {(soundEffects.length === 0 && this.state.data.length === 0 && (typeof this.state.pagination.current_page === 'undefined')) ? <SoundEffectBarebone /> : ''}
            {(soundEffects.length === 0 && this.state.data.length === 0 && (typeof this.state.pagination.current_page === 'undefined')) ? <SoundEffectBarebone /> : ''}
            {(soundEffects.length === 0 && this.state.data.length === 0 && (typeof this.state.pagination.current_page === 'undefined')) ? <SoundEffectBarebone /> : ''}
            {soundEffects.length > 0 ? soundEffects : (this.props.data.length == 0 ? '' : I18n.t('no_songs_available'))}
            {spinner()}
            {smiley()}
            {pagination()}
          </div>
        )
      }
    }
  }
};

SoundEffectListing.defaultProps = {
  showHeading: false,
  active_sound_effect_id: 0,
  show_pagination: true,
  composer_page_permalink: false,
  totalCount: 250,
  slim: false,
  pricing_path: '',
  freeMode: false,
  i18n: {
    next: 'Next',
    previous: 'Previous',
    free: 'free',
    recently_created: 'new'
  },
  pagination: {
    current_page: 1,
    next_page: '',
    prev_page: '',
    total_pages: 1,
    total_count: 10
  },
  usageLicenseTypes: [],
  subscribedUsageLicenseTypes: [],
  content: [],
  show_download_button: false,
  hideItemIdList: false,
  hideAddToCart: false,
  soundEffectShow: false,
  soundEffectFavorites: []
}

// INIT
let elements = document.querySelectorAll('[data-react-component=SoundEffectListing]');

elements.forEach(function(element) {
  let reactProps = JSON.parse(element.dataset.reactProps);

  ReactDOM.render(
    <React.StrictMode>
      <SoundEffectListing {...reactProps} />
    </React.StrictMode>,
    element
  );
})

export default SoundEffectListing;

