import React, { Component } from 'react';
import classNames from 'classnames/bind';

import UsageLicenseTypeSelectItemSidebar from '~/components/legacy/UsageLicenseTypeSelectItemSidebar.jsx' ;

class UsageLicenseTypeSelectSidebar extends Component {

  constructor(props) {
    super(props)

    this.handleClick = this.handleClick.bind(this);

    this.state = {
      selectedItemId: 0
    }
  }


  updateCart(usageLicenseTypeId, exposurePackId) {
    var trackId = this.props.id,
      i,
      updateUrl;

    if (exposurePackId) {
      updateUrl = "/cart?exposure_pack_id=" + exposurePackId + "&usage_license_type_id=" + usageLicenseTypeId;
    } else {
      updateUrl = "/cart?track_id=" + trackId + "&usage_license_type_id=" + usageLicenseTypeId;
    }

    $.ajax({
      url: updateUrl,
      type: 'POST'
    }).done(function (data) {

      global.EventHub.trigger('updateCart', data)


      for (i = 0; i < data.length; i += 1) {
        if (data[i].track_id === trackId) {
          if (typeof plausible === 'function') {
            plausible('track_add_cart', { props: { track_id: trackId, usage_license_type_id: usageLicenseTypeId, location: window.location.href } });
          }
          ahoy.track('track_add_cart', { track_id: trackId, usage_license_type_id: usageLicenseTypeId, location: window.location.href, categorization_processed: false })
        }
      }


    }.bind(this));
  }


  handleClick(itemId) {
    if (typeof this.props.exposure_pack !== 'undefined' && this.props.exposure_pack.id) {
      this.updateCart(itemId, this.props.exposure_pack.id);
    } else {
      this.updateCart(itemId);
    }
  }

  usageLicenseTypeIsSelected(usageLicenseTypeId) {
    var selected = false;

    this.props.selectedUsageLicenseTypeIds.forEach(function (element) {
      if (element === usageLicenseTypeId) {
        selected = true;
      }
    });

    return selected;
  }

  render() {
    var classes = classNames({
      'usage-license-type-select-sidebar': true
    }),
      usageLicenseTypes = this.props.usageLicenseTypes.map(function (usageLicenseType, index) {
        return (
          <UsageLicenseTypeSelectItemSidebar
            locale={this.props.locale}
            id={usageLicenseType.id}
            name={usageLicenseType.name}
            description={usageLicenseType.formatted_description}
            grossPrice={usageLicenseType.formatted_country_gross_price}
            discountGrossPrice={usageLicenseType.formatted_country_discount_gross_price}
            selected={this.usageLicenseTypeIsSelected(usageLicenseType.id)}
            handleClick={this.handleClick}
            free={this.props.free}
            discount={this.props.discount}
            i18n={this.props.i18n}
            key={"usage-license-type-" + usageLicenseType.id} />
        );
      }.bind(this)),
      checkoutButton = <span />,
      pricingInfoLink;

    if (this.props.selectedUsageLicenseTypeIds.length > 0) {
      checkoutButton = <a className='text-green text-center margin-top10 usage-license-type-select__checkout-link' href='/c/checkouts/new' title={this.props.i18n.checkout_now}><span className='fa fa-arrow-right padding-right10'></span>{this.props.i18n.checkout_now}</a>;
    } else {
      // checkoutButton = <a className='button button--disabled button--full usage-license-type-select__checkout-link'>{this.props.i18n.checkout_now}</a>;
    }

    return (
      <div className='panel'>
        <div className={classes}>
          <div className='usage-license-type-select-sidebar__cover'>
            <img src={this.props.exposure_pack.cover_image_path}></img>
            <div className='usage-license-type-select-sidebar__title'>
              <div className='usage-license-type-select-sidebar__title-hint'>Beschallungspaket</div>
              {this.props.title}
            </div>
          </div>
          <div className='usage-license-type-select-sidebar__price'>
            {usageLicenseTypes}
          </div>
          <div className='row'>
            <div className='col12 text-center padding-top10'>
              {checkoutButton}
            </div>
          </div>
          <p className='margin-bottom5'><strong>{this.props.locale === 'de' ? 'Lieferumfang:' : 'Contents:'}</strong></p>
          <ul className='usage-license-type-select-sidebar__feature-list'>
            <li>{this.props.trackCount} Tracks / {this.props.totalDuration} {this.props.locale === 'de' ? 'Minuten Spielzeit' : 'minutes playing time'}</li>
            <li>Download-Format: MP3</li>
            <li>{this.props.locale === 'de' ? 'GEMA-Freistellungsdokument (PDF)' : 'License document (PDF)'}</li>
            <li>{this.props.locale === 'de' ? 'Rechnung (PDF)' : 'Invoice (PDF)'}</li>
          </ul>
          <p className='margin-bottom5'><strong>Info:</strong></p>
          <ul className='usage-license-type-select-sidebar__feature-list'>
            <li>{this.props.locale === 'de' ? 'Keine Folgekosten / Keine GEMA-Gebühren' : 'No follow up costs / no royalty collection'}</li>
            <li>{this.props.locale === 'de' ? 'Preis pro Filiale' : 'Price per branch'}</li>
          </ul>
        </div>
      </div>
    )
  }
};

export default UsageLicenseTypeSelectSidebar;