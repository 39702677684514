const Helpers = {};

Helpers.mergeObjects = function(configObject, defaultObject) {
    var mergedObject = {};

    mergedObject = this.copyObjectPropertiesFromTo(mergedObject, defaultObject);
    mergedObject = this.copyObjectPropertiesFromTo(mergedObject, configObject);

    return mergedObject;
};

/**
 * Robust solution to identify different types of numbers
 *
 * @see http://stackoverflow.com/questions/18082/validate-numbers-in-javascript-isnumeric
 *
 * @function isNumber
 * @memberof! Absolventa.Helpers
 * @param {numeric} possiblyANumber
 * @return {boolean}
 */
Helpers.isNumber = function(possiblyANumber) {
    return !isNaN(parseFloat(possiblyANumber)) && isFinite(possiblyANumber);
};

Helpers.copyObjectPropertiesFromTo = function (targetObject, senderObject) {
    var prop;

    for (prop in senderObject) {
        try {
            // Property in destination object set; update its value.
            if (senderObject[prop].constructor === Object) {
                targetObject[prop] = this.mergeObjects(targetObject[prop], senderObject[prop]);
            } else {
                targetObject[prop] = senderObject[prop];
            }
        } catch (e) {
            // Property in destination object not set; create it and set its value.
            targetObject[prop] = senderObject[prop];
        }
    }

    return targetObject;
};

Helpers.createConfigObject = function(configObject, defaultObject) {
    var mergedObject = {};


    if (configObject === null || typeof configObject !== 'object') {
        configObject = {};
    }

    mergedObject = this.mergeObjects(configObject, defaultObject);

    return mergedObject;
};

Helpers._addEventListener = function(obj, evt, fnc) {
    /**
    * Cross Browser helper to addEventListener.
    *
    * @param {HTMLElement} obj The Element to attach event to.
    * @param {string} evt The event that will trigger the binded function.
    * @param {function(event)} fnc The function to bind to the element.
    * @return {boolean} true if it was successfuly binded.
    */

    // W3C model
    if (obj.addEventListener) {
        obj.addEventListener(evt, fnc, false);
        return true;
    }
    // Microsoft model
    if (obj.attachEvent) {
        obj.attachEvent("on" + evt, fnc);
        return true;
    }

    return false;
};

Helpers._removeEventListener = function(obj, type, fnc) {
    if (obj.removeEventListener) {
      obj.removeEventListener(type, fnc, false);
    } else if (obj.detachEvent) {
      obj.detachEvent("on" + type, fnc);
    }
};

Helpers.insertAfter = function(referenceNode, newNode) {
    referenceNode.parentNode.insertBefore(newNode, referenceNode.nextSibling);
};

Helpers._generateSelectorStringArray = function(selector) {
    var selectors = [];

    // check for param availability
    if (selector !== undefined) {

        // convert to array, if param is a string
        if (typeof selector === 'string') {
            selectors.push(selector);
        }

        // is param an array?
        if (Object.prototype.toString.call(selector) === '[object Array]') {
            selectors = selectors.concat(selector);
        }
    }

    return selectors;
};

Helpers.sanitizeFileName = function(fileName) {
    const umlautMap = {
        'ä': 'a',
        'ö': 'o',
        'ü': 'u',
        'Ä': 'A',
        'Ö': 'O',
        'Ü': 'U',
        'ß': 'ss',
    };

    // Replace umlauts
    let sanitizedFileName = fileName.replace(/[\ä\ö\ü\Ä\Ö\Ü\ß]/g, function(key) {
        return umlautMap[key];
    });

    // Replace spaces and other special characters with underscores
    sanitizedFileName = sanitizedFileName.replace(/[^a-zA-Z0-9\._]+/g, '_');

    return sanitizedFileName;
};


export default Helpers;