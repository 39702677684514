import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import classNames from 'classnames/bind';

import Pagination from '~/components/legacy/Pagination.jsx';
import TrackBarebone from '~/components/legacy/TrackBarebone.jsx';
import Track from '~/components/legacy/Track.jsx';
import UsageLicenseTypeSelectSidebar from '~/components/legacy/UsageLicenseTypeSelectSidebar.jsx';
// import $ from 'jquery';

class TrackListing extends Component {

  constructor(props) {
    super(props);

    this.trackListing = null;

    this.setTrackListing = element => {
      this.trackListing = element;
    };

    this.handleFavoriteClick = this.handleFavoriteClick.bind(this);
    this.handleCartClick = this.handleCartClick.bind(this);
    this.handleFreeDownloadClick = this.handleFreeDownloadClick.bind(this);

    this.state = {
      showUsageLicenseSelectForTrackId : [],
      showFreeDownloadDialogForTrackId : [],
      data : this.props.data,
      pagination : this.props.pagination,
      active_track_id : this.props.active_track_id,
      cart_items: this.props.cart_items,
      currentQuery : {
        genres : [],
        tags : [],
        moods : [],
        includePro: 2
      },
      trackFavorites : this.props.trackFavorites,

      searchInProgress : false,
      animationFinished : true
     };
  }

  componentDidMount() {
    setTimeout(function() {
      global.EventHub.trigger('requestUpdateTrackListing');
    }, 10)

    global.EventHub.on('updateTrackListing', function(data) {
      this.setState({data: data[0].tracks, pagination: data[0].pagination});
    }.bind(this));

    global.EventHub.on('initialUpdateTrackListing', function(data) {
      this.setState({data: data[0].tracks, pagination: data[0].pagination});
      global.EventHub.trigger('playerInitTrack', data[0].tracks[0].id);
    }.bind(this));


    global.EventHub.on('paginationClick', function(data) {
        this.scrollToListStart();
    }.bind(this));

    global.EventHub.on('playTrack', function(data) {
        this.setState({active_track_id : data[0], activeTrackVariantId: ''})
    }.bind(this));

    global.EventHub.on('playTrackVariant', function(data) {
      this.setState({active_track_id : data[1], activeTrackVariantId: data[0]})
    }.bind(this));

    global.EventHub.on('updateCart', function(data) {
        this.setState({cart_items: data[0]});
    }.bind(this));

    global.EventHub.on('closePopupMenus', function() {
      this.setState({showUsageLicenseSelectForTrackId: [], showFreeDownloadDialogForTrackId: []})
    }.bind(this));

    global.EventHub.on('updateItemIdList', function(payload) {
      this.setState({currentQuery : payload[0] });
    }.bind(this));

    global.EventHub.on('fireSearch', function() {
      // $("html, body").animate({ scrollTop: $('.track-listing').offset().top - 320 }, "slow");
      this.setState({searchInProgress: true, animationFinished: false});
    }.bind(this));

    global.EventHub.on('fireSearchWithoutAnimation', function() {
      this.setState({searchInProgress: false, animationFinished: true});
    }.bind(this));

    global.EventHub.on('searchSuccess', function() {
        setTimeout(function() {
            this.setState({searchInProgress: false});
        }.bind(this), 200);
        setTimeout(function() {
            this.setState({animationFinished: true});
        }.bind(this), 400);
    }.bind(this));

    global.EventHub.on('updateTrackFavorites', function(data) {
      this.setState({trackFavorites: data[0]});
    }.bind(this));
  }

  handleFavoriteClick(trackId) {
    $.ajax({
      url: "/track_favorite?track_id=" + trackId,
      type: 'POST'
    }).done(function(data) {

        if (!(this.trackIsFavorited(trackId, this.state.trackFavorites))) {
            global.EventHub.trigger('trackFavorited', trackId);
        }

        global.EventHub.trigger('updateTrackFavorites', data)
    }.bind(this));
  }

  trackIsFavorited(trackId, favorites) {
      let i,
          favoritesLength = favorites && favorites.length ? favorites.length : 0;

      for (i = 0; i < favoritesLength; i += 1) {
          if (favorites[i].track_id === trackId) {
              return true;
          }
      }
      return false;
  }

  trackIsPurchased(trackId) {
    return !!this.props.purchasedTrackIds[trackId];
  }

  trackPurchaseCount(trackId) {
    if (this.trackIsPurchased(trackId)) {
      return this.props.purchasedTrackIds[trackId];
    } else {
      return 0;
    }
  }


  handleCartClick(trackId) {
      let stateObject = {},
          indexOfTrackId = this.state.showUsageLicenseSelectForTrackId.indexOf(trackId);

      if (indexOfTrackId === -1) {
          stateObject = {};
          stateObject['showUsageLicenseSelectForTrackId'] = [];
          stateObject['showUsageLicenseSelectForTrackId'].push(trackId);
      } else {
          global.EventHub.trigger('closePopupMenus');
      }

      this.setState(stateObject);
  }

  handleFreeDownloadClick(trackId) {
    let stateObject = {},
        indexOfTrackId = this.state.showFreeDownloadDialogForTrackId.indexOf(trackId);

    if (indexOfTrackId === -1) {
        stateObject = {};
        stateObject['showFreeDownloadDialogForTrackId'] = [];
        stateObject['showFreeDownloadDialogForTrackId'].push(trackId);
    } else {
        global.EventHub.trigger('closePopupMenus');
    }

    this.setState(stateObject);
  }

  scrollToListStart() {
      $('html, body').animate({
          scrollTop: $('.track-listing').offset().top - 260
      }, 400);
  }

  trackIsInCart(trackId, cartItems) {
      let i,
          cartItemsLength = cartItems && cartItems.length ? cartItems.length : 0;

      for (i = 0; i < cartItemsLength; i += 1) {
          if (cartItems[i].track_id === trackId) {
              return true;
          }
      }
      return false;
  }

  isUsageLicenseSelectVisible(trackId) {
      if (this.state.showUsageLicenseSelectForTrackId.indexOf(trackId) > -1) {
          return true;
      }

      return false;
  }

  isFreeDownloadDialogVisible(trackId) {
    if (this.state.showFreeDownloadDialogForTrackId.indexOf(trackId) > -1) {
      return true;
    }

    return false;
  }

  shouldRenderUsageLicenseTypeSelect(trackId) {
      let shouldRender = false;

      for (let i = 0; i < this.state.showUsageLicenseSelectForTrackId.length; i += 1) {
          if (this.state.showUsageLicenseSelectForTrackId[i] === trackId && typeof this.props.exposure_pack.id === 'undefined') {
             shouldRender = true;
          }
      }

      return shouldRender;
  }

  getContent() {
      return this.props.content.map(function(content, index) {
        return (
          <div className={this.props.content.length > 4 ? 'track-listing__content track-listing__content--long' : 'track-listing__content'} key={'track_listing_content_' + index}>
            <details className='track-listing__content-details' itemProp="mainEntity" itemScope itemType="https://schema.org/Question" open={content.open ? true : false}>
              <summary className='track-listing__content-title-wrapper' itemProp="name">{content.title}</summary>
              <div className='track-listing__content-description' itemProp="acceptedAnswer" itemScope itemType="https://schema.org/Answer"><div itemProp="text" dangerouslySetInnerHTML={{__html: content.description}}></div></div>
            </details>
          </div>
        )
      }.bind(this))
  }

  getNameFor(itemName, id) {
      return this.props.itemNames[itemName][id];
  }

  isActiveItem(itemName, id) {
    return (this.state.currentQuery[itemName].indexOf(id) > -1);
  }

  getSelectedUsageLicenseTypeIdsForExposurePack() {
    let ids = [],
        that = this,
        exposurePackId = this.props.exposure_pack.id || that.state.data.exposure_pack.id,
        cart_items = this.state.cart_items || this.props.cart_items;

    cart_items.forEach(function(element) {
        if (element.exposure_pack_id === exposurePackId) {
            ids.push(element.usage_license_type_id);
        }
    });

    return ids;
  }


  render() {
      let activeTrackId = this.state.active_track_id;
      let activeTrackVariantId = this.state.activeTrackVariantId;
      let content = [];
      let similarTrackHeading;
      let clientListing = [];
      let prevProps;
      let nextProps;
      let currentProps;

      let tracks = this.state.data.map(function (track, index) {
              currentProps = {
                  id : track.id,
                  composerName: track.composer_name,
                  composerUrl: track.composer_url,
                  showcaseUrl: track.showcase_url,
                  i18n : this.props.i18n,
                  title : track.title,
                  display_title : track.display_title,
                  active : track.id === activeTrackId,
                  activeTrackVariantId : activeTrackVariantId,
                  key : "track-" + track.id,
                  length : track.length,
                  short_description : track.short_description,
                  inCart : this.trackIsInCart(track.id, this.state.cart_items),
                  free : track.free,
                  hideItemIdList: this.props.hideItemIdList,
                  hideAddToCart: this.props.hideAddToCart,
                  genre_id_list : track.genre_id_list,
                  tag_id_list : track.tag_id_list,
                  mood_id_list : track.mood_id_list,
                  isActiveItem : this.isActiveItem,
                  discount : track.discount,
                  copyrightYear : track.copyright_year,
                  recently_activated : track.recently_activated,
                  usageLicenseTypes : this.props.usageLicenseTypes.filter(function(usageLicenseType) { return usageLicenseType.for_fx === false }),
                  subscribedUsageLicenseTypes: this.props.subscribedUsageLicenseTypes,
                  pricing_path : this.props.pricing_path,
                  cart_items : this.state.cart_items,
                  trackFavorites : this.state.trackFavorites,
                  favorited : this.trackIsFavorited(track.id, this.state.trackFavorites),
                  purchased : this.trackIsPurchased(track.id),
                  purchaseCount : this.trackPurchaseCount(track.id),
                  handleFavoriteClick : this.handleFavoriteClick,
                  showUsageLicenseSelect : this.isUsageLicenseSelectVisible(track.id),
                  showFreeDownloadDialog : this.isFreeDownloadDialogVisible(track.id),
                  handleCartClick : this.handleCartClick,
                  handleFreeDownloadClick: this.handleFreeDownloadClick,
                  show_download_button : this.props.show_download_button,
                  search_in_progress: this.state.searchInProgress,
                  exposure_pack: this.props.exposure_pack,
                  slim: this.props.slim,
                  score: track.score,
                  getNameFor: this.getNameFor,
                  freeMode: this.props.freeMode,
                  track_variants: track.track_variants,
                  track_image_markup: track.track_image,
                  youtube_content_id_enabled: track.youtube_content_id_enabled,
                  royaltyFree : track.royalty_free,
                  pro_short_name : track.pro_short_name,
                  included_media_bundles : track.included_media_bundles,
                  locale: this.props.locale
              }

              if (typeof this.state.data[index+1] !== 'undefined') {
                  let nextTrack = this.state.data[index+1];
                  nextProps = {
                      id : nextTrack.id,
                      composerName: nextTrack.composer_name,
                      composerUrl: nextTrack.composer_url,
                      showcaseUrl: nextTrack.showcase_url,
                      i18n : this.props.i18n,
                      title : nextTrack.title,
                      display_title : nextTrack.display_title,
                      active : nextTrack.id === activeTrackId,
                      activeTrackVariantId : activeTrackVariantId,
                      key : "track-" + nextTrack.id,
                      length : nextTrack.length,
                      short_description : nextTrack.short_description,
                      inCart : this.trackIsInCart(nextTrack.id, this.state.cart_items),
                      free : nextTrack.free,
                      hideItemIdList: this.props.hideItemIdList,
                      hideAddToCart: this.props.hideAddToCart,
                      genre_id_list : nextTrack.genre_id_list,
                      tag_id_list : nextTrack.tag_id_list,
                      mood_id_list : nextTrack.mood_id_list,
                      isActiveItem : this.isActiveItem,
                      discount : nextTrack.discount,
                      copyrightYear : nextTrack.copyright_year,
                      recently_activated : nextTrack.recently_activated,
                      usageLicenseTypes : this.props.usageLicenseTypes.filter(function(usageLicenseType) { return usageLicenseType.for_fx === false }),
                      subscribedUsageLicenseTypes: this.props.subscribedUsageLicenseTypes,
                      pricing_path : this.props.pricing_path,
                      cart_items : this.state.cart_items,
                      trackFavorites : this.state.trackFavorites,
                      favorited : this.trackIsFavorited(nextTrack.id, this.state.trackFavorites),
                      purchased : this.trackIsPurchased(nextTrack.id),
                      purchaseCount : this.trackPurchaseCount(nextTrack.id),
                      handleFavoriteClick : this.handleFavoriteClick,
                      showUsageLicenseSelect : this.isUsageLicenseSelectVisible(nextTrack.id),
                      showFreeDownloadDialog : this.isFreeDownloadDialogVisible(nextTrack.id),
                      handleCartClick : this.handleCartClick,
                      handleFreeDownloadClick: this.handleFreeDownloadClick,
                      show_download_button : this.props.show_download_button,
                      exposure_pack: this.props.exposure_pack,
                      slim: this.props.slim,
                      score: nextTrack.score,
                      getNameFor: this.getNameFor,
                      freeMode: this.props.freeMode,
                      track_variants: nextTrack.track_variants,
                      handleVariantClick: this.handleVariantClick,
                      track_image_markup: nextTrack.track_image,
                      youtube_content_id_enabled: nextTrack.youtube_content_id_enabled,
                      royaltyFree : nextTrack.royalty_free,
                      pro_short_name : nextTrack.pro_short_name,
                      included_media_bundles : nextTrack.included_media_bundles,
                      locale: this.props.locale
                  }
              }

              if (typeof this.state.data[index-1] !== 'undefined') {
                  let prevTrack = this.state.data[index-1];
                  prevProps = {
                      id : prevTrack.id,
                      composerName: prevTrack.composer_name,
                      composerUrl: prevTrack.composer_url,
                      showcaseUrl: prevTrack.showcase_url,
                      i18n : this.props.i18n,
                      title : prevTrack.title,
                      display_title : prevTrack.display_title,
                      active : prevTrack.id === activeTrackId,
                      activeTrackVariantId : activeTrackVariantId,
                      key : "track-" + prevTrack.id,
                      length : prevTrack.length,
                      short_description : prevTrack.short_description,
                      inCart : this.trackIsInCart(prevTrack.id, this.state.cart_items),
                      free : prevTrack.free,
                      hideItemIdList: this.props.hideItemIdList,
                      hideAddToCart: this.props.hideAddToCart,
                      genre_id_list : prevTrack.genre_id_list,
                      tag_id_list : prevTrack.tag_id_list,
                      mood_id_list : prevTrack.mood_id_list,
                      isActiveItem : this.isActiveItem,
                      discount : prevTrack.discount,
                      copyrightYear : prevTrack.copyright_year,
                      recently_activated : prevTrack.recently_activated,
                      usageLicenseTypes : this.props.usageLicenseTypes.filter(function(usageLicenseType) { return usageLicenseType.for_fx === false }),
                      subscribedUsageLicenseTypes: this.props.subscribedUsageLicenseTypes,
                      pricing_path : this.props.pricing_path,
                      cart_items : this.state.cart_items,
                      trackFavorites : this.state.trackFavorites,
                      favorited : this.trackIsFavorited(prevTrack.id, this.state.trackFavorites),
                      purchased : this.trackIsPurchased(prevTrack.id),
                      purchaseCount : this.trackPurchaseCount(prevTrack.id),
                      handleFavoriteClick : this.handleFavoriteClick,
                      showUsageLicenseSelect : this.isUsageLicenseSelectVisible(prevTrack.id),
                      showFreeDownloadDialog : this.isFreeDownloadDialogVisible(prevTrack.id),
                      handleCartClick : this.handleCartClick,
                      handleFreeDownloadClick: this.handleFreeDownloadClick,
                      show_download_button : this.props.show_download_button,
                      exposure_pack: this.props.exposure_pack,
                      slim: this.props.slim,
                      score: prevTrack.score,
                      getNameFor: this.getNameFor,
                      freeMode: this.props.freeMode,
                      track_variants: prevTrack.track_variants,
                      handleVariantClick: this.handleVariantClick,
                      track_image_markup: prevTrack.track_image,
                      youtube_content_id_enabled: prevTrack.youtube_content_id_enabled,
                      royaltyFree : prevTrack.royalty_free,
                      pro_short_name : prevTrack.pro_short_name,
                      included_media_bundles : prevTrack.included_media_bundles,
                      locale: this.props.locale
                  }
              }

              if (index === 2 && this.props.content && this.props.content.length > 0 && this.state.pagination.current_page === 1) {
                content = this.getContent();
              } else {
                content = [];
              }

              if (index === 0 && this.props.trackShow) {
                similarTrackHeading = <div><hr></hr><h2 className='text-like-h4'>{this.props.locale === 'de' ? 'Weitere ähnliche Tracks:' : 'Similar tracks:'}</h2></div>
              }

              // if (index === 10 && this.props.clientListing && this.props.clientListing.length > 0 && this.state.pagination.current_page === 1) {
              //   clientListing = this.props.clientListing.map(function(client, index) {
              //     return (
              //       <div className='track-listing__client' key={'track_listing_client_' + index}>
              //         {client}
              //       </div>
              //     )
              //   }.bind(this));
              // } else {
              //   clientListing = [];
              // }

              return (
                <div key={'track_wrapper_' + track.id}>
                  <Track {...currentProps} next_track={nextProps} prev_track={prevProps} key={'track_' + track.id}/>
                  {similarTrackHeading && index === 0 ? similarTrackHeading : ''}
                  {content.length === 0 ? '' : (<div className='track-listing__content-wrapper' itemScope itemType="https://schema.org/FAQPage"><h2 className='text-like-h4'>{this.props.locale === 'de' ? 'Oft gestellte Fragen' : 'FAQ'}</h2><div className='track-listing__content-symbol'><i className='fa fa-info'></i></div><div className='track-listing__contents'>{content}</div></div>)}
                  {clientListing.length === 0 ? '' : (<div className='track-listing__client-wrapper'><h2 className='text-like-h4'>{this.props.locale === 'de' ? 'Diese Marken vertrauen auf unsere Komponisten' : 'These brands rely on our composers'}</h2><div className='track-listing__clients'>{clientListing}</div></div>)}
                </div>
              );

          }.bind(this));

      let pagination = function() {
        if (this.props.show_pagination && this.state.pagination.total_count > 10) {
            return (
              <Pagination data={this.state.pagination} i18n={this.props.i18n} genre_id={this.props.genre_id} composer_page_permalink={this.props.composer_page_permalink}/>
            )
        }
      }.bind(this);

      let spinner = function() {
        if (this.state.searchInProgress) {
          return (
            <div className='loading-bars'>
              <span className='loading-bars__element'></span>
              <span className='loading-bars__element'></span>
              <span className='loading-bars__element'></span>
              <span className='loading-bars__element'></span>
              <span className='loading-bars__element'></span>
              <span className='loading-bars__element'></span>
              <span className='loading-bars__element'></span>
              <span className='loading-bars__element'></span>
              <span className='loading-bars__element'></span>
              <span className='loading-bars__element'></span>
              <span className='loading-bars__element'></span>
              <span className='loading-bars__element'></span>
              <span className='loading-bars__element'></span>
              <span className='loading-bars__element'></span>
              <span className='loading-bars__element'></span>
            </div>
          )
        }
      }.bind(this);

      let smiley = function() {
        return (
          <div className='track-listing-smiley'>
            <div className='track-listing-smiley-icon'>
              <i className='fa fa-frown-o' />
            </div>
          </div>
        )
      }.bind(this);

      let proStateHint = (<span className="track-listing__pro-state"></span>);

      if (this.state.currentQuery.includePro === 0) {
        proStateHint = (<span className="track-listing__pro-state">{this.props.locale === 'de' ? 'GEMA-frei und P.R.O.' : 'P.R.O. and royalty free'}</span>);
      }

      if (this.state.currentQuery.includePro === 1) {
        proStateHint = (<span className="track-listing__pro-state">{this.props.locale === 'de' ? 'P.R.O. (GEMA-pflichtig)' : 'P.R.O.'}</span>);
      }

      if (this.state.currentQuery.includePro === 2) {
        proStateHint = (<span className="track-listing__pro-state">{this.props.locale === 'de' ? 'GEMA-frei' : 'Royalty free'}</span>);
      }

      let classes = classNames({
            'track-listing': true,
            'track-listing--slim': this.props.slim,
            'track-listing-with-pagination': this.props.show_pagination,
            'is-spinning': this.state.searchInProgress,
            'is-empty': this.state.data.length === 0,
            'track-listing-animation-finished': this.state.animationFinished,
            'track-listing--track-show': this.props.trackShow
          });

      let tracksDuration = 0;

      for (let i = 0; i < this.state.data.length; i+=1) {
          tracksDuration += this.state.data[i].length;
      }

    if (typeof this.props.exposure_pack !== 'undefined' && this.props.exposure_pack.id) {
        return (
          <div className='row'>
            <div className='show-md-down col3 col6-md offset3-md col12-sm search-tabs-wrapper'>
              <UsageLicenseTypeSelectSidebar
                  addToCart={this.props.i18n.add_to_cart}
                  free={this.props.free}
                  discount={this.props.discount}
                  exposure_pack={this.props.exposure_pack}
                  usageLicenseTypes={this.props.usageLicenseTypes.filter(function(usageLicenseType) { return usageLicenseType.for_fx === false })}
                  updateCart={this.updateCart}
                  pricing_path={this.props.pricing_path}
                  selectedUsageLicenseTypeIds={this.getSelectedUsageLicenseTypeIdsForExposurePack()}
                  i18n={this.props.i18n}
                  visible={this.props.showUsageLicenseSelect}
                  position='bottom'
                  show_download_button={this.props.show_download_button}
                  title={this.props.exposure_pack.title}
                  trackCount={tracks.length}
                  totalDuration={this.props.exposure_pack.duration} />
            </div>
            <div className='col9 col12-md-down'>
              <div className={classes} ref={this.setTrackListing}>
                {(this.state.data.length === 0 && (typeof this.state.pagination.current_page === 'undefined')) ? this.getContent() : ''}
                {(tracks.length === 0 && this.state.data.length === 0 && (typeof this.state.pagination.current_page === 'undefined')) ? this.getContent() : ''}
                {tracks.length > 0 ? tracks : (this.state.data.length == 0 ? '' : I18n.t('no_songs_available'))}
                {pagination()}
                {spinner()}
                {smiley()}
              </div>
            </div>
            <div className='col3 col6-md offset3-md col12-sm search-tabs-wrapper'>
              <UsageLicenseTypeSelectSidebar
                  addToCart={this.props.i18n.add_to_cart}
                  free={this.props.free}
                  discount={this.props.discount}
                  exposure_pack={this.props.exposure_pack}
                  usageLicenseTypes={this.props.usageLicenseTypes.filter(function(usageLicenseType) { return usageLicenseType.for_fx === false })}
                  updateCart={this.updateCart}
                  pricing_path={this.props.pricing_path}
                  selectedUsageLicenseTypeIds={this.getSelectedUsageLicenseTypeIdsForExposurePack()}
                  i18n={this.props.i18n}
                  visible={this.props.showUsageLicenseSelect}
                  position='bottom'
                  show_download_button={this.props.show_download_button}
                  title={this.props.exposure_pack.title}
                  trackCount={this.state.pagination.total_count}
                  totalDuration={this.props.exposure_pack.duration} />
            </div>
          </div>
        )
    } else {
      if (this.props.showHeading) {
        return (
          <div className={classes} ref={this.setTrackListing}>
            <div className="track-listing__count"><span className='track-listing__count-value'>{this.state.pagination.total_count}</span> {this.state.pagination.total_count === 1 ? 'Track' : 'Tracks'}{proStateHint}</div>
            {(tracks.length === 0 && this.state.data.length === 0 && (typeof this.state.pagination.current_page === 'undefined')) ? <TrackBarebone locale={this.props.locale}/> : ''}
            {(tracks.length === 0 && this.state.data.length === 0 && (typeof this.state.pagination.current_page === 'undefined')) ? <TrackBarebone locale={this.props.locale}/> : ''}
            {(tracks.length === 0 && this.state.data.length === 0 && (typeof this.state.pagination.current_page === 'undefined')) ? <TrackBarebone locale={this.props.locale}/> : ''}
            {(tracks.length === 0 && this.state.data.length === 0 && (typeof this.state.pagination.current_page === 'undefined')) ? (<div className='track-listing__content-wrapper' itemScope itemType="https://schema.org/FAQPage"><h2 className='text-like-h4'>{this.props.locale === 'de' ? 'Oft gestellte Fragen' : 'FAQ'}</h2><div className='track-listing__content-symbol'><i className='fa fa-info'></i></div><div className='track-listing__contents'>{this.getContent()}</div></div>) : ''}
            {(tracks.length === 0 && this.state.data.length === 0 && (typeof this.state.pagination.current_page === 'undefined')) ? <TrackBarebone locale={this.props.locale}/> : ''}
            {(tracks.length === 0 && this.state.data.length === 0 && (typeof this.state.pagination.current_page === 'undefined')) ? <TrackBarebone locale={this.props.locale}/> : ''}
            {(tracks.length === 0 && this.state.data.length === 0 && (typeof this.state.pagination.current_page === 'undefined')) ? <TrackBarebone locale={this.props.locale}/> : ''}
            {(tracks.length === 0 && this.state.data.length === 0 && (typeof this.state.pagination.current_page === 'undefined')) ? <TrackBarebone locale={this.props.locale}/> : ''}
            {(tracks.length === 0 && this.state.data.length === 0 && (typeof this.state.pagination.current_page === 'undefined')) ? <TrackBarebone locale={this.props.locale}/> : ''}
            {(tracks.length === 0 && this.state.data.length === 0 && (typeof this.state.pagination.current_page === 'undefined')) ? <TrackBarebone locale={this.props.locale}/> : ''}
            {tracks.length > 0 ? tracks : (this.props.data.length == 0 ? '' : I18n.t('no_songs_available'))}
            {pagination()}
            {spinner()}
            {smiley()}
          </div>
        )
      } else {
        return (
          <div className={classes} ref={this.setTrackListing}>
            {(tracks.length === 0 && this.state.data.length === 0 && (typeof this.state.pagination.current_page === 'undefined')) ? <TrackBarebone locale={this.props.locale}/> : ''}
            {(tracks.length === 0 && this.state.data.length === 0 && (typeof this.state.pagination.current_page === 'undefined')) ? <TrackBarebone locale={this.props.locale}/> : ''}
            {(tracks.length === 0 && this.state.data.length === 0 && (typeof this.state.pagination.current_page === 'undefined')) ? <TrackBarebone locale={this.props.locale}/> : ''}
            {(tracks.length === 0 && this.state.data.length === 0 && (typeof this.state.pagination.current_page === 'undefined')) ? (<div className='track-listing__content-wrapper' itemScope itemType="https://schema.org/FAQPage"><h2 className='text-like-h4'>{this.props.locale === 'de' ? 'Oft gestellte Fragen' : 'FAQ'}</h2><div className='track-listing__content-symbol'><i className='fa fa-info'></i></div><div className='track-listing__contents'>{this.getContent()}</div></div>) : ''}
            {(tracks.length === 0 && this.state.data.length === 0 && (typeof this.state.pagination.current_page === 'undefined')) ? <TrackBarebone locale={this.props.locale}/> : ''}
            {(tracks.length === 0 && this.state.data.length === 0 && (typeof this.state.pagination.current_page === 'undefined')) ? <TrackBarebone locale={this.props.locale}/> : ''}
            {(tracks.length === 0 && this.state.data.length === 0 && (typeof this.state.pagination.current_page === 'undefined')) ? <TrackBarebone locale={this.props.locale}/> : ''}
            {(tracks.length === 0 && this.state.data.length === 0 && (typeof this.state.pagination.current_page === 'undefined')) ? <TrackBarebone locale={this.props.locale}/> : ''}
            {(tracks.length === 0 && this.state.data.length === 0 && (typeof this.state.pagination.current_page === 'undefined')) ? <TrackBarebone locale={this.props.locale}/> : ''}
            {(tracks.length === 0 && this.state.data.length === 0 && (typeof this.state.pagination.current_page === 'undefined')) ? <TrackBarebone locale={this.props.locale}/> : ''}
            {tracks.length > 0 ? tracks : (this.props.data.length == 0 ? '' : I18n.t('no_songs_available'))}
            {spinner()}
            {smiley()}
            {pagination()}
          </div>
        )
      }
    }
  }
}

TrackListing.defaultProps = {
  showHeading: false,
  active_track_id: 0,
  show_pagination : true,
  composer_page_permalink: false,
  totalCount : 250,
  slim : false,
  pricing_path: '',
  freeMode: false,
  hideAddToCart: false,
  i18n: {
    next: 'Next',
    previous: 'Previous',
    free: 'free',
    recently_created: 'new'
  },
  pagination: {
    current_page: 1,
    next_page: '',
    prev_page: '',
    total_pages: 1,
    total_count: 10
  },
  usageLicenseTypes : [],
  subscribedUsageLicenseTypes: [],
  content : [],
  show_download_button : false,
  hideItemIdList: false,
  trackShow: false,
  trackFavorites : [],
  data : [],
  purchasedTrackIds: []
}


// INIT
let elements = document.querySelectorAll('[data-react-component=TrackListing]');

elements.forEach(function(element) {
  let reactProps = JSON.parse(element.dataset.reactProps);

  ReactDOM.render(
    <React.StrictMode>
      <TrackListing {...reactProps} />
    </React.StrictMode>,
    element
  );
})

export default TrackListing;
