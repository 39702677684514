// <div data-action="initTrackTextGenerator">
//   <div data-action="trackTextGeneratorOutput"></div>
// </div>

import Helpers from '~/modules/Helpers.js';

const TrackTextGenerator = function(config) {
    let _defaultConfig;

    _defaultConfig = {
        selectorString : '[data-action~="initTrackTextGenerator"]',
        beforeLoad : function(instance) {

        },
        afterLoad : function(instance) {

        }
    };

    this.config = Helpers.createConfigObject(config, _defaultConfig);

    // elements
    this.formContainer = undefined;
    this.targetContainer = undefined;
    this.outputDeContainerShort = undefined;
    this.outputEnContainerShort = undefined;
    this.outputDeContainerLong = undefined;
    this.outputEnContainerLong = undefined;
    this.sloganInputDe = undefined;
    this.sloganInputEn = undefined;
    this.generatorButton = undefined;
    this.copyButton = undefined;
    this.formData = {
        track: {
            title: '',
            tag_ids: [],
            instrument_ids: [],
            genre_ids: [],
            mood_ids: [],
            tag_ids: [],
            tempo: undefined,
            loop: false
        }
    }

    this.queryString = '';
    this.response = {};

    this._init();
};

TrackTextGenerator.prototype._init = function() {
    var container = document.querySelector(this.config.selectorString);

    this.config.beforeLoad(this);

    if (container) {
        this.formContainer = container;
        this.outputDeContainerShort = document.querySelector('[data-action~=trackTextGeneratorOutputDe]');
        this.outputEnContainerShort = document.querySelector('[data-action~=trackTextGeneratorOutputEn]');
        this.outputDeContainerLong = document.querySelector('[data-action~=trackTextGeneratorOutputDeLong]');
        this.outputEnContainerLong = document.querySelector('[data-action~=trackTextGeneratorOutputEnLong]');
        this.sloganInputDe = document.querySelector('[data-action~=trackTextGeneratorSloganInputDe]');
        this.sloganInputEn = document.querySelector('[data-action~=trackTextGeneratorSloganInputEn]');
        this.outputEnContainerLong = document.querySelector('[data-action~=trackTextGeneratorOutputEnLong]');
        this.generatorButton = document.querySelector('[data-action~=trackTextGeneratorButton]');
        this.generatorButton.addEventListener('click', function(e) {
            e.preventDefault();
            this.gatherFormData();
            this.sendData();
        }.bind(this));
        this.copyButton = document.querySelector('[data-action~=trackTextCopyButton]');
        this.copyButton.addEventListener('click', function() {
            document.querySelector('#track_translations_attributes_1_short_description').value = this.outputDeContainerShort.innerHTML;
            document.querySelector('#track_translations_attributes_1_description').value = this.outputDeContainerLong.innerHTML;
            document.querySelector('#track_translations_attributes_2_short_description').value = this.outputEnContainerShort.innerHTML;
            document.querySelector('#track_translations_attributes_2_description').value = this.outputEnContainerLong.innerHTML;
        }.bind(this));

        this.gatherFormData();
        this.sendData();
    }

    this.config.afterLoad(this);
};

TrackTextGenerator.prototype.gatherFormData = function() {
    this.formData['track']['title'] = encodeURI(document.querySelector('#track_title').value);
    this.formData['track']['tempo'] = parseInt(document.querySelector('#track_tempo').value, 10);
    this.formData['track']['loop'] = !!document.querySelector('#track_loop').value;

    var genreInputs = document.querySelectorAll('[name="track[genre_ids][]"');
    genreInputs.forEach(function(input) {
        if (input.checked === true) {
            this.formData['track']['genre_ids'].push({name: '[genre_ids][]', value: parseInt(input.value, 10)});
        }
    }.bind(this));

    var tagInputs = document.querySelectorAll('[name="track[tag_ids][]"');
    tagInputs.forEach(function(input) {
        if (input.checked === true) {
            this.formData['track']['tag_ids'].push({name: '[tag_ids][]', value: parseInt(input.value, 10)});
        }
    }.bind(this));

    var moodInputs = document.querySelectorAll('[name="track[mood_ids][]"');
    moodInputs.forEach(function(input) {
        if (input.checked === true) {
            this.formData['track']['mood_ids'].push({name: '[mood_ids][]', value: parseInt(input.value, 10)});
        }
    }.bind(this));

    var instrumentInputs = document.querySelectorAll('.track_instrument_roles_instrument_id select');
    instrumentInputs.forEach(function(input) {
        this.formData['track']['instrument_ids'].push({name: '[instrument_ids][]', value: parseInt(input.value, 10)});
    }.bind(this));

};



TrackTextGenerator.prototype.sendData = function() {
    var that = this;
    var requestUrl = window.location.href.includes('/e/') ? "/e/tracks/fetch_track_text?" : "/a/tracks/fetch_track_text?"
    $.ajax({
        url: requestUrl + this.buildParamString(), // it should be mapped in routes.rb in rails
        method: "GET",
        dataType: "json"
    }).success(function(data) {
        var sloganDe = that.sloganInputDe.value;
        var sloganEn = that.sloganInputEn.value;
        that.outputDeContainerShort.innerHTML = sloganDe ? sloganDe + ' ' + data["de_short"] : data["de_short"];
        that.outputEnContainerShort.innerHTML = sloganEn ? sloganEn + ' ' + data["en_short"] : data["en_short"];
        that.outputDeContainerLong.innerHTML = sloganDe ? sloganDe + ' ' + data["de_long"] : data["de_long"];
        that.outputEnContainerLong.innerHTML = sloganEn ? sloganEn + ' ' + data["en_long"] : data["en_long"];
    });
};

TrackTextGenerator.prototype.buildParamString = function() {
    var string = '',
        index = 0;

    for (var property in this.formData['track']) {
        if (this.formData['track'].hasOwnProperty(property)) {
            if (index !== 0) {
                string += '&'
            }
            if (property === 'title' || property === 'tempo' || property === 'loop') {
                string += (property + '=' + this.formData['track'][property]);
            } else {
                var propertyIds = []
                var propertyName = []
                this.formData['track'][property].forEach(function(propertyObject) {
                    propertyName = propertyObject['name'];
                    propertyIds.push(propertyObject['value'])
                })
                string += (propertyName + '=' + propertyIds);
            }

            index++
        }
    }

    return string;
};

export default TrackTextGenerator;