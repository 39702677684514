import React, { Component } from 'react';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import confetti from 'canvas-confetti';

import UsageLicenseTypeSelectItem from '~/components/legacy/UsageLicenseTypeSelectItem.jsx';

class UsageLicenseTypeSelect extends Component {

  constructor(props) {
    super(props);

    this.handleClick = this.handleClick.bind(this);
    this.handleUsageLicenseTypeViewChange = this.handleUsageLicenseTypeViewChange.bind(this);

    this.state = {
      selectedItemId : 0,
      viewSoundExposureLicenseTypes: false      
    }
  }

  handleClick(itemId, forFx) {
      confetti({
        particleCount: 200,
        startVelocity: 30,
        spread: 360
      });

      if (forFx) {
        this.props.updateCart(itemId, undefined, true);
      } else {
        this.props.updateCart(itemId);
      }
  }

  handleUsageLicenseTypeViewChange(e) {
    e.preventDefault();

    this.setState({
        viewSoundExposureLicenseTypes : !this.state.viewSoundExposureLicenseTypes
    });
  }

  usageLicenseTypeIsSelected(usageLicenseTypeId) {
      let selected = false;

      this.props.selectedUsageLicenseTypeIds.forEach(function(element) {
          if (element === usageLicenseTypeId) {
              selected = true;
          }
      });

      return selected;
  }

  getUsageLicenseTypeCount(usageLicenseTypeId) {
    let count = 0;

    this.props.selectedUsageLicenseTypeIds.forEach(function(element) {
        if (element === usageLicenseTypeId) {
            count++;
        }
    });

    return count;
  }

  render() {
      let classes = classNames({
            'usage-license-type-select': true,
            'usage-license-type-select--active': this.props.visible,
            'usage-license-type-select--top': this.props.position === 'top',
            'usage-license-type-select--top-showcase': this.props.position === 'top-showcase',
            'usage-license-type-select--showcase': this.props.position === 'showcase',
            'usage-license-type-select--bottom': this.props.position === 'bottom',
            'usage-license-type-select--overlay': this.props.position === 'overlay',
            'usage-license-type-select--cart': this.props.position === 'cart'
          }),
          usageLicenseTypes = this.props.usageLicenseTypes
              .filter(function (usageLicenseType) {
                return usageLicenseType.for_sound_exposure === this.state.viewSoundExposureLicenseTypes
              }.bind(this))
              .map(function (usageLicenseType, index) {
                return (
                  <UsageLicenseTypeSelectItem
                    locale={this.props.locale}
                    id={usageLicenseType.id}
                    name={usageLicenseType.name}
                    description={usageLicenseType.formatted_description}
                    grossPrice={usageLicenseType.formatted_country_gross_price}
                    discountGrossPrice={usageLicenseType.formatted_country_discount_gross_price}
                    includedInSubscription={this.props.subscribedUsageLicenseTypes.includes(usageLicenseType.id)}
                    selected={this.usageLicenseTypeIsSelected(usageLicenseType.id)}
                    count={this.getUsageLicenseTypeCount(usageLicenseType.id)}
                    handleClick={this.handleClick}
                    free={this.props.free}
                    discount={this.props.discount}
                    i18n={this.props.i18n}
                    forFx={usageLicenseType.for_fx}
                    key={"usage-license-type-" + usageLicenseType.id} />
              );
          }.bind(this)),
          checkoutButton = <span />,
          pricingInfoLink,
          licensesViewText;

      if (this.props.selectedUsageLicenseTypeIds.length > 0) {
          if (this.props.show_download_button) {
              checkoutButton = (<div className='row'><div className="col12"><a className='button button--secondary button--full usage-license-type-select__checkout-link' href='/c/checkouts/new' title='Download'>Download</a></div></div>);
          } else {
              checkoutButton = (<div className='row'><div className="col12"><a className='button button--secondary button--full usage-license-type-select__checkout-link' href='/c/checkouts/new' title='Download'>{this.props.locale === 'de' ? 'Zur Kasse' : 'Checkout'}</a></div></div>);
          }
      }

      pricingInfoLink = (
        <div className='usage-license-type-select__price-info'>
          <a href={this.props.pricing_path} className='button button--full' target='_blank'>{this.props.locale === 'de' ? 'Lizenz-Infos' : 'license infos'}</a>
        </div>
      )

      if (this.props.locale === 'de') {
          if (this.state.viewSoundExposureLicenseTypes) {
              licensesViewText = 'Projekt-Lizenzen';
          } else {
              licensesViewText = 'Event und Beschallungs-Lizenzen';
          }
      } else {
          if (this.state.viewSoundExposureLicenseTypes) {
              licensesViewText = 'Show project licenses';
          } else {
              licensesViewText = 'Show event and in-store licenses';
          }
      }

      var header;

      if (this.props.position === 'overlay') {
          header = (<div className='usage-license-type-select__header'>{this.props.locale === 'de' ? 'Wählen Sie Ihre Lizenz(en) für ' + (this.props.trackTitle) + ' aus:' : 'Choose your license(s) for ' + (this.props.trackTitle) + ':'}<div onClick={this.props.handleCartClick} className='usage-license-type-select__close'><span className='fa fa-times'></span></div></div>)
      }

      return (
        <div className={classes}>
          {header}
          <div className='usage-license-type-select__list-wrapper'>
            <div className='usage-license-type-select__tab-wrapper'>
              <div className={this.state.viewSoundExposureLicenseTypes ? 'usage-license-type-select__tab' : 'usage-license-type-select__tab usage-license-type-select__tab--active'}>
                <a href='#' onClick={this.handleUsageLicenseTypeViewChange}>{this.props.locale === 'de' ? 'Projekt-Lizenzen' : 'Project licenses'}</a>
              </div>
              <div className={!this.state.viewSoundExposureLicenseTypes ? 'usage-license-type-select__tab' : 'usage-license-type-select__tab usage-license-type-select__tab--active'}>
                <a href='#' onClick={this.handleUsageLicenseTypeViewChange}>{this.props.locale === 'de' ? 'Event und Beschallungs-Lizenzen' : 'Event and in-store licenses'}</a>
              </div>
            </div>
            {/* <div className='usage-license-type-select__hint'>
              <strong className=''>Bitte wählen Sie eine Lizenz aus:</strong>
            </div> */}
            {usageLicenseTypes}
            <div className='usage-license-type-select__license-assistant-hint'>
              {this.props.locale === 'de' ? (
                <strong className=''>Nicht sicher, welche Lizenz die Richtige ist? Nutzen Sie einfach unseren <a href='/lizenz-assistent' target='_blank'>Lizenz-Assistent</a>.</strong>
              ):(
                <strong className=''>Not sure which license is right for you? Simply use our <a href='/license-assistant' target='_blank'>license assistant</a>.</strong>
              )}
              
            </div>
          </div>
          {checkoutButton}
          <div className='row usage-license-type-select__button-wrapper'>
            <div className='col5'>
              <div onClick={this.props.handleCartClick} className='button button--ghost button--full margin-top10'>{this.props.locale === 'de' ? 'Schließen' : 'Close'}</div>
            </div>
            <div className='col7'>
              {pricingInfoLink}
            </div>
          </div>

        </div>
      )
  }
};

UsageLicenseTypeSelect.defaultProps = {
  subscribedUsageLicenseTypes : []
};

UsageLicenseTypeSelect.propTypes = {
  locale: PropTypes.string
};

export default UsageLicenseTypeSelect;
