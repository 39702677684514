import React, { Component } from 'react';
import classNames from 'classnames/bind';

import SearchTabName from '~/components/legacy/SearchTabName.jsx';

class SearchTabs extends Component {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);

    this.state = {
      activeTab : 0
    }
  }

  componentDidMount() {
      this.setState({
          activeTab : this.props.activeTab
      });

      global.EventHub.on('fireSearch', function() {
        this.setState({
            activeTab : 0
        });
      }.bind(this));

      global.EventHub.on('closePopupMenus', function() {
        this.setState({activeTab: 0});
      }.bind(this));
  }

  handleChange(newIndex) {
      this.setState({
          activeTab : this.state.activeTab === newIndex ? 0 : newIndex
      });

      global.EventHub.trigger('searchTabNavClicked');
  }

  // getTabNavChildren() {
  //     var children = this.props.children,
  //         activeTabIndex = this.state.activeTab,
  //         index = 0,
  //         handleChange = this.handleChange;

  //     return React.Children.map(children, function(child) {
  //         index += 1;

  //         return (
  //           <SearchTabNavItem
  //             title={child.props.title}
  //             highlighted={child.props.highlighted}
  //             counter={child.props.counter}
  //             iconClassName={child.props.iconClassName}
  //             ref={'search-tab-nav-item-' + index}
  //             index={index}
  //             handleChange={handleChange}
  //             active={index === activeTabIndex} />
  //         )
  //     });
  // }

  getTabContentChildren() {
      var children = this.props.children,
          activeTabIndex = this.state.activeTab,
          index = 0,
          handleChange = this.handleChange;

      return React.Children.map(children, function(child) {

          index += 1;

          if (child.props.itemNames) {
              return React.Children.map(child.props.children, function(tabContent) {
                  var classesSearchTab = classNames({
                    'search-tab' : true,
                    'search-tab--last' : (index === children.length),
                    'search-tab--instruments' : (child.props.itemNames === 'instruments'),
                    'search-tab--moods' : (child.props.itemNames === 'moods'),
                    'search-tab--genres' : (child.props.itemNames === 'genres')
                  });

                  return (
                    <div className={classesSearchTab}>
                      <SearchTabName
                        title={child.props.title}
                        highlighted={child.props.highlighted}
                        counter={child.props.counter}
                        iconClassName={child.props.iconClassName}
                        ref={'search-tab-nav-item-' + index}
                        index={index}
                        activeItems={child.props.activeItems}
                        proFilter={child.props.proFilter}
                        items={child.props.items}
                        handleChange={handleChange}
                        active={index === activeTabIndex}
                        locale={child.props.locale}
                        />
                      {index === activeTabIndex ? tabContent : ''}
                    </div>
                  )
              });
          } else {
              // length inputs & query input
              return child;
          }


      });
  }

                  // <SearchTab ref={'tab-nav-item-' + index} index={index} handleChange={handleChange} active={index === activeTabIndex}>
                  //   {tabContent}
                  // </SearchTab>

  render() {
      let classes = classNames({
            'search-tabs' : true,
            'search-tabs--horizontal' : this.props.alignment === 'horizontal',
            'search-tabs--vertical' : this.props.alignment === 'vertical'
          });

      return (
        <div className={classes}>
          {this.getTabContentChildren()}
        </div>
      )

  }
};

SearchTabs.defaultProps = {
  activeTab : 0,
  alignment : 'horizontal' // 'horizontal' or 'vertical'
}

export default SearchTabs;
