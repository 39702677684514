// import $ from 'jquery';

const AudioPlayer = {
    currentTrackData: {}, // gets updated through initTrack
    visualiser : undefined,
    player: {
        width : $('#player_wrapper').data('width')
    }, // mediaelement.js object
    playing : false,

    init: function () {
        global.EventHub.on('mountAudioPlayer', this.mount.bind(this));

        global.EventHub.on('playerInitTrack', function(id) {
            this.initTrack(id);
        }.bind(this));
    },

    mount: function() {
        this.create_waveform_preload_container();
        this.initPlayer();
        this.addEventListeners();
    },

    create_waveform_preload_container : function() {
      $('<div id="waveform_preload_container"></div>').appendTo($('#player_wrapper'));
      $('#waveform_preload_container').css({
          'overflow' : 'hidden',
          'width' : '0',
          'height' : '0'
      });
    },

    initPlayer: function () {
        var that = this,
            initialTrackId = $('audio source').data('track-initial'),
            initialSpeakerAudioId = $('audio source').data('speaker-audio-initial'),
            initialSoundEffectId = $('audio source').data('sound-effect-initial');

        this.player = new MediaElementPlayer('player', {
            features: ['playpause','current','progress','duration','volume'],
            // shows debug errors on screen
            enablePluginDebug: false,
            // remove or reorder to change plugin priority
            plugins: ['flash', 'silverlight'],
            // specify to force MediaElement to use a particular video or audio type
            type: '',
            // // default if the <video width> is not specified
            defaultAudioWidth: that.player.width,
            // default if the <video height> is not specified
            defaultAudioHeight: 140,
            autosizeProgress: false,
            audioVolume: 'vertical',
            // rate in milliseconds for Flash and Silverlight to fire the timeupdate event
            // larger number is less accurate, but less strain on plugin->JavaScript bridge
            timerRate: 1,
            // method that fires when the Flash or Silverlight object is ready
            // // path to Flash and Silverlight plugins
            // pluginPath: '/myjsfiles/',
            // // name of flash file
            // flashName: 'flashmediaelement.swf',
            // // name of silverlight file
            // silverlightName: 'silverlightmediaelement.xap',
            success: function (mediaElement, domObject) {
                var isChrome = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);

                // Get waveform for @track
                $('span.mejs__time-total, span.mejs__time-loaded, span.mejs__time-current').hide();
                $('.mejs__audio, .mejs__controls').css({
                    'background' : 'none'
                });

                // add event listener
                mediaElement.addEventListener('play', function(e) {
                    global.EventHub.trigger('audioPlayerPlay', window.AudioPlayer.currentTrackData);
                    that.playing = true;
                }, false);
                mediaElement.addEventListener('pause', function(e) {
                    global.EventHub.trigger('audioPlayerStop');
                    that.playing = false;
                }, false);
                mediaElement.addEventListener('ended', function(e) {
                    global.EventHub.trigger('audioPlayerEnded');
                }, false);

                if (initialTrackId) {
                    that.initTrack(initialTrackId);
                }

                if (initialSpeakerAudioId) {
                    that.initSpeakerAudio(initialSpeakerAudioId);
                }

                if (initialSoundEffectId) {
                    that.initSoundEffect(initialSoundEffectId);
                }
            },
            // fires when a problem is detected
            error: function () {

            }
        });
    },

    initTrack: function (track_id) {
        var that = this; // object

        $.ajax({
            url: "/tracks/" + track_id,
            dataType: 'json',
            success: function (data) {
                that.currentTrackData = data;
                that.updatePlayerWaveform(data);
                that.player.setSrc(data.stream_url);
                global.EventHub.trigger('updateTrackInfo', data);
            }
        });
    },

    initSpeakerAudio: function (speakerAudioId) {
        var that = this; // object
        $.ajax({
            url: "/speaker_audios/" + speakerAudioId,
            dataType: 'json',
            success: function (data) {
                that.currentTrackData = data;
                that.updatePlayerWaveform(data);
                that.player.setSrc(data.stream_url);
                global.EventHub.trigger('updateTrackInfo', data);
            }
        });
    },

    initSoundEffect: function (sound_effect_id) {
        var that = this; // object
        $.ajax({
            url: "/sound_effects/" + sound_effect_id,
            dataType: 'json',
            success: function (data) {
                that.currentTrackData = data;
                that.updatePlayerWaveform(data);
                that.player.setSrc(data.stream_url);
                global.EventHub.trigger('updateTrackInfo', data);
            }
        });
    },

    initAndPlayTrack: function (track_id) {
        var that = this,
            trackId = track_id[0]; // object

        if (this.currentTrackData.id === trackId) {
            if (this.playing) {
                this.player.pause();
            } else {
                this.player.setSrc(this.currentTrackData.stream_url);
                this.player.play()
            }
        } else {
            $.ajax({
                url: "/tracks/" + trackId,
                dataType: 'json',
                success: function (data) {
                    that.currentTrackData = data;
                    that.updatePlayerWaveform(data);
                    that.playTrack(data.stream_url);
                    global.EventHub.trigger('updateTrackInfo', data);
                }
            });
        }
    },

    initAndPlayTrackVariant: function (trackVariantId) {
        var that = this; // object

        $.ajax({
            url: "/track_variants/" + trackVariantId,
            dataType: 'json',
            success: function (data) {
                that.currentTrackData = data.track_data;
                that.updatePlayerWaveform(data.track_variant_data);
                that.playTrack(data.track_variant_data.stream_url);
                global.EventHub.trigger('updateTrackVariantInfo', data.track_data, data.track_variant_data);
            }
        });
    },

    initAndPlaySoundEffect: function (sound_effect_id) {
        var that = this,
            sound_effect_id = sound_effect_id[0]; // object

        if (this.currentTrackData.id === sound_effect_id) {
            if (this.playing) {
                this.player.pause();
            } else {
                this.player.setSrc(this.currentTrackData.stream_url);
                this.player.play()
            }
        } else {
            $.ajax({
                url: "/sound-effects/" + sound_effect_id,
                dataType: 'json',
                success: function (data) {
                    that.currentTrackData = data;
                    that.updatePlayerWaveform(data);
                    that.playTrack(data.stream_url);
                    global.EventHub.trigger('updateTrackInfo', data);
                }
            });
        }
    },

    initAndPlaySoundEffectVariant: function (soundEffectVariantId) {
        var that = this; // object

        $.ajax({
            url: "/sound_effect_variants/" + soundEffectVariantId,
            dataType: 'json',
            success: function (data) {
                that.currentTrackData = data.sound_effect_data;
                that.updatePlayerWaveform(data.sound_effect_variant_data);
                that.playTrack(data.sound_effect_variant_data.stream_url);
                global.EventHub.trigger('updateSoundEffectVariantInfo', data.sound_effect_data, data.sound_effect_variant_data);
            }
        });
    },

    initAndPlaySpeakerAudio: function (speakerAudioId) {
        var that = this,
            speakerAudioId = speakerAudioId[0]; // object

        if (this.currentTrackData.id === speakerAudioId) {
            if (this.playing) {
                this.player.pause();
            } else {
                this.player.setSrc(this.currentTrackData.stream_url);
                this.player.play()
            }
        } else {
            $.ajax({
                url: "/speaker_audios/" + speakerAudioId,
                dataType: 'json',
                success: function (data) {
                    that.currentTrackData = data;
                    that.updatePlayerWaveform(data);
                    that.playTrack(data.stream_url);
                    global.EventHub.trigger('updateTrackInfo', data);
                }
            });
        }
    },

    initAndPlayVoiceRecording: function (voiceRecordingId) {
        var that = this,
            voiceRecordingId = voiceRecordingId[0]; // object

        if (this.currentTrackData.id === voiceRecordingId) {
            if (this.playing) {
                this.player.pause();
            } else {
                this.player.setSrc(this.currentTrackData.stream_url);
                this.player.play()
            }
        } else {
            $.ajax({
                url: "/voice_recordings/" + voiceRecordingId,
                dataType: 'json',
                success: function (data) {
                    that.currentTrackData = data;
                    that.updatePlayerWaveform(data);
                    that.playTrack(data.stream_url);
                    global.EventHub.trigger('updateTrackInfo', data);
                }
            });
        }
    },

    updatePlayerWaveform: function (data) {
        let $waveformContainers = $('span.mejs__time-total, span.mejs__time-loaded, span.mejs__time-current'),
            imgcount = 3,
            $preloadWaveformContainer = $('#waveform_preload_container'),
            switchLength = 400,
            that = this;

        $waveformContainers.stop(true,true);
        $preloadWaveformContainer.empty();

        // Append dummy images
        $('#waveform_preload_container').append('<img src="' + data.waveform_inactive + '" />');
        $('#waveform_preload_container').append('<img src="' + data.waveform_loaded + '" />');
        $('#waveform_preload_container').append('<img src="' + data.waveform_played + '" />');

        $waveformContainers.hide();

        
        $('#waveform_preload_container img').on('load', function() {
            imgcount--;
            if (imgcount < 1) {
                // console.log('all waveform images loaded!');
                $('#waveform_preload_container').fadeIn(switchLength, function() {
                    $('span.mejs__time-total').css({
                        'background' : 'url(' + data.waveform_inactive + ')',
                        'background-size' : that.player.width + 'px 200px'
                    });
                    $('span.mejs__time-loaded').css({
                        'background' : 'url(' + data.waveform_loaded + ')',
                        'background-size' : that.player.width + 'px 200px'
                    });
                    $('span.mejs__time-current').css({
                        'background' : 'url(' + data.waveform_played + ')',
                        'background-size' : that.player.width + 'px 200px'
                    });
                    $waveformContainers.fadeIn(switchLength);
                    imgcount = 3;
                });
            }
        });
    },

    playTrack: function (stream_url) {
        this.player.pause();
        this.player.setSrc(stream_url);
        this.player.load();
        this.player.play();
    },

    addEventListeners: function () {
        global.EventHub.on('playTrack', function() {
            var trackId = arguments[0];

            if (trackId) {
                this.initAndPlayTrack(trackId);
            }

            // scroll into view
            // $('html, body').animate({
            //     scrollTop: $('#track-' + trackId).offset().top - 100
            // }, 300);

        }.bind(this));

        global.EventHub.on('playTrackVariant', function() {
            var trackVariantId = arguments[0][0];
            var trackId = arguments[0][1];

            if (trackVariantId) {
                this.initAndPlayTrackVariant(trackVariantId);
            }

            // scroll into view
            // $('html, body').animate({
            //     scrollTop: $('#track-' + trackId).offset().top - 100
            // }, 300);

        }.bind(this));

        global.EventHub.on('playSoundEffect', function() {
            var sound_effect_id = arguments[0];

            if (sound_effect_id) {
                this.initAndPlaySoundEffect(sound_effect_id);
            }

            // scroll into view
            // $('html, body').animate({
            //     scrollTop: $('#track-' + trackId).offset().top - 100
            // }, 300);

        }.bind(this));

        global.EventHub.on('playSoundEffectVariant', function() {
            var soundEffectVariantId = arguments[0][0];
            var sound_effect_id = arguments[0][1];

            if (soundEffectVariantId) {
                this.initAndPlaySoundEffectVariant(soundEffectVariantId);
            }

            // scroll into view
            // $('html, body').animate({
            //     scrollTop: $('#track-' + trackId).offset().top - 100
            // }, 300);

        }.bind(this));

        global.EventHub.on('playSpeakerAudio', function() {
            var speakerAudioId = arguments[0];

            if (speakerAudioId) {
                this.initAndPlaySpeakerAudio(speakerAudioId);
            }

            // // scroll into view
            // $('html, body').animate({
            //     scrollTop: $('[data-play-speaker-audio-id=' + speakerAudioId + ']').offset().top - 100
            // }, 300);

        }.bind(this));

        global.EventHub.on('playVoiceRecording', function() {
            var voiceRecordingId = arguments[0];

            if (voiceRecordingId) {
                this.initAndPlayVoiceRecording(voiceRecordingId);
            }

            // // scroll into view
            // $('html, body').animate({
            //     scrollTop: $('[data-play-speaker-audio-id=' + speakerAudioId + ']').offset().top - 100
            // }, 300);

        }.bind(this));
    }
};

window.AudioPlayer = AudioPlayer;

export default AudioPlayer;
