const LazyLoad = function() {
  let _defaultConfig;

  _defaultConfig = {
      loadPixelThreshold: 200, // e.g. load image 100pxs before element gets into viewport
      throttleTime: 200,
      loadingCheckInterval: 500,
      maxLoadingRetries: 15,
      switchAttributes:[
        {
          attrName : 'src',
          dataAttrName : 'lazyLoadSrc' // camelcase gets transformed to kebap case via .dataset api
        },
        {
          attrName : 'srcset',
          dataAttrName : 'lazyLoadSrcset'
        }
      ],
      backgroundDataAttribute: 'lazyLoadBgSrc'
  };

  this.config = _defaultConfig;

  this.elements = [];
  this.backgroundElements = []; // elements, that lazy load css background image
  this.invisibleElements = [];

  this._init();
};

LazyLoad.prototype._init = function() {
  this._populateElements();
  this._addScrollObservers();
  setTimeout(this._checkAndUpdateDisplay.bind(this), 100);
};

LazyLoad.prototype._populateElements = function() {
  this.elements = document.querySelectorAll('[data-lazy-load-src], [data-lazy-load-bg-src]');
  this.invisibleElements = Array.from(this.elements);

  let i,
      elementsLength = this.elements.length;

  for (i = 0; i < elementsLength; i += 1) {
      this.elements[i].className += ' lazy-load--waiting';
  }

};

LazyLoad.prototype._addScrollObservers = function() {
  let i,
      elementsLength = this.elements.length;

  for (i = 0; i < elementsLength; i += 1) {
      this._addScrollObserver(this.elements[i]);
  }
};

LazyLoad.prototype._addScrollObserver = function(element) {
  let doc = element.ownerDocument,
      win = doc.defaultView || doc.parentWindow,
      lastScrollPosition,
      scrollingCallback;

  scrollingCallback = this._throttle(function() {
      let currentScrollTopPosition = window.pageYOffset || document.documentElement.scrollTop,
          scrollingDirection;

      if (currentScrollTopPosition > lastScrollPosition) {
           scrollingDirection = 'down';
       } else if (currentScrollTopPosition < lastScrollPosition){
          scrollingDirection = 'up';
       }

      if (this._isInvisible(element) && this._isInViewport(element, win, scrollingDirection)) {
          this._displayElement(element);
      }

      lastScrollPosition = currentScrollTopPosition;

  }.bind(this), this.config.throttleTime);

  win.addEventListener('scroll', scrollingCallback);
};

LazyLoad.prototype._checkAndUpdateDisplay = function(loadingRetryIndex) {
  let elements = this.elements,
      elementsLength = this.elements.length,
      i;

  if (typeof loadingRetryIndex === 'undefined') {
      loadingRetryIndex = 0;
  }

  for (i = 0; i < elementsLength; i += 1) {

      if (typeof elements[i] === 'undefined' && loadingRetryIndex < this.config.maxLoadingRetries) {
          setTimeout(function() {
              this._checkAndUpdateDisplay(loadingRetryIndex + 1);
          }.bind(this), this.config.loadingCheckInterval);

          break;
      }

      if (this._isInvisible(elements[i]) && this._isInViewport(elements[i], window)) {
          this._displayElement(elements[i]);
      }
  }
};

LazyLoad.prototype._isInViewport = function(element, win, scrollingDirection) {
  let windowHeight = Math.max(document.documentElement.clientHeight, win.innerHeight || 0),
      elemRect = element.getBoundingClientRect();

  if (scrollingDirection === 'up') {
      if (
          elemRect.bottom > 0 - this.config.loadPixelThreshold &&
          elemRect.bottom < (win.innerHeight || document.documentElement.clientHeight) + elemRect.height + this.config.loadPixelThreshold &&
          elemRect.left >= 0 &&
          elemRect.right <= (win.innerWidth || document.documentElement.clientWidth) &&
          elemRect.width !== 0 &&
          elemRect.height !== 0
          ) {
          return true;
      }

      return false;
  }

  if (
      elemRect.top - windowHeight <= this.config.loadPixelThreshold &&
      elemRect.bottom + this.config.loadPixelThreshold >= 0 &&
      elemRect.left >= 0 &&
      elemRect.right <= (win.innerWidth || document.documentElement.clientWidth) &&
      elemRect.width !== 0 &&
      elemRect.height !== 0
      ) {
      return true;
  }

  return false;
};

LazyLoad.prototype._isInvisible = function(node) {
  let invisibleElements = this.invisibleElements,
      invisibleElementsLength = invisibleElements.length,
      isInvisible = false,
      i;

  for (i = 0; i < invisibleElementsLength; i += 1) {
      if (invisibleElements[i] === node) {
         isInvisible = true;
         break;
      }
  }

  return isInvisible;
};

LazyLoad.prototype._displayElement = function(element) {
  this._switchAttributes(element);
  this._removeFromInvisibleElements(element);
};

LazyLoad.prototype._switchAttributes = function(element) {
  let switchAttributes = this.config.switchAttributes,
      switchAttributesLength = this.config.switchAttributes.length,
      i,
      dataAttributeValue;

  for (i = 0; i < switchAttributesLength; i += 1) {
      dataAttributeValue = element.dataset[switchAttributes[i]['dataAttrName']];

      if (dataAttributeValue) {
        element.setAttribute(switchAttributes[i]['attrName'], dataAttributeValue);
      }
  }

  if (element.dataset[this.config.backgroundDataAttribute]) {
      element.style.backgroundImage = 'url(' + element.dataset[this.config.backgroundDataAttribute] + ')';
  }
};

LazyLoad.prototype._removeFromInvisibleElements = function(element) {
  let invisibleElements = this.invisibleElements,
      index;

  index = invisibleElements.indexOf(element);

  if (index > -1) {
      invisibleElements.splice(index, 1);
  }

  this.invisibleElements = invisibleElements;
};

LazyLoad.prototype._throttle = function(fn, throttleTime, scope) {
  let last,
      deferTimer;

  if (typeof threshold === 'undefined') {
      throttleTime = this.config.throttleTime;
  }

  return function () {
      let context = scope || this,
          now = +new Date,
          args = arguments;

      if (last && now < last + throttleTime) {
          clearTimeout(deferTimer);

          deferTimer = setTimeout(function () {
              last = now;
              fn.apply(context, args);
          }, throttleTime);
      } else {
          last = now;
          fn.apply(context, args);
      }
  };
};

export default LazyLoad;
