import React, { Component } from 'react';

class Pagination extends Component {

  constructor(props) {
    super(props);

    this.handleNextClick = this.handleNextClick.bind(this);
    this.handlePrevClick = this.handlePrevClick.bind(this);
  }

  catchClick(e) {
      e.preventDefault();
  }

  handleNextClick(e) {
    e.preventDefault();

    if (this.props.data.current_page < this.props.data.total_pages) {
      global.EventHub.trigger('paginationClick', this.props.data.current_page + 1);
    }
  }

  handlePrevClick(e) {
    e.preventDefault();

    global.EventHub.trigger('paginationClick', this.props.data.current_page - 1);
  }

  buildNextLinkHref() {
    let hrefText = '',
        currentParams = this.props.data.current_query;

    if (this.props.data.current_page < this.props.data.total_pages) {
      hrefText = currentParams.replace(/page=(\d+)/, 'page=' + (this.props.data.current_page + 1));
      if (hrefText.indexOf('page=') < 0) {
        if (hrefText.indexOf('?') > -1) {
          hrefText += '&page=' + (this.props.data.current_page + 1);
        } else {
          hrefText += '?page=' + (this.props.data.current_page + 1);
        }
      }
    } else {
      hrefText = currentParams;
    }

    return (this.props.data.path || (window.location ? window.location.pathname : '/')) + hrefText;
  }

  buildPrevLinkHref() {
      let hrefText = '',
          currentParams = this.props.data.current_query;

      if (this.props.data.current_page > 1) {
          if (this.props.data.current_page === 2) {
              // remove page param
              hrefText = currentParams.replace(/page=(\d+)/, '');
              // remove trailing '?' if necessary
              if (hrefText.slice(-1) === '?') {
                  hrefText = hrefText.slice(0, -1);
              }
              if (hrefText.slice(-1) === '&') {
                  hrefText = hrefText.slice(0, -1);
              }
          } else {
              hrefText = currentParams.replace(/page=(\d+)/, 'page=' + (this.props.data.current_page - 1));
          }

      } else {
          hrefText = currentParams;
      }

      return (this.props.data.path || (window.location ? window.location.pathname : '/')) + hrefText;
  }

  render() {

      let prevLink = function() {
          if (this.props.data.current_page > 1) {
              return (
                  <div className='pagination__link pagination__link--prev'><a href={this.buildPrevLinkHref()} className='button button--full' onClick={this.handlePrevClick}><i className='fa fa-chevron-left'></i><span className='pagination__link-label'>{this.props.i18n.previous}</span></a></div>
              )
          } else {
              return (
                  <div className='pagination__link pagination__link--prev pagination__link--inactive'>&nbsp;</div>
              )
          }
      }.bind(this);

      let nextLink = function() {
          if (this.props.data.current_page < this.props.data.total_pages) {
              return (
                  <div className='pagination__link pagination__link--next'><a href={this.buildNextLinkHref()} className='button button--full' onClick={this.handleNextClick}><span className='pagination__link-label'>{this.props.i18n.next}</span><i className='fa fa-chevron-right'></i></a></div>
              )
          } else {
              return (
                  <div className='pagination__link pagination__link--next pagination__link--inactive'>&nbsp;</div>
              )
          }
      }.bind(this);

      let currentPage = function() {
          if (!(this.props.data.total_pages === 1 && this.props.data.current_page === 1)) {
              if (this.props.data.total_pages > 1) {
                return (
                  <div className='pagination__link pagination__page-info'>{this.props.data.current_page} / {this.props.data.total_pages}</div>
                )
              } else {
                return (
                  <div className='pagination__link pagination__page-info pagination__link--inactive'>{this.props.data.current_page}</div>
                )
              }
          }

      }.bind(this);

      return (
        <div className='row pagination-wrapper'>
          <div className='col12'>
            <div className='row pagination'>
              <div className='col4'>
                {prevLink()}
              </div>
              <div className='col4 text-center'>
                {currentPage()}
              </div>
              <div className='col4 text-right'>
                {nextLink()}
              </div>
            </div>
          </div>
        </div>
      )
  }
};

export default Pagination;
