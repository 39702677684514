import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

class SoundEffectListingSortInputs extends Component {
  constructor(props) {
    super(props);

    this.handleDateDescClick = this.handleDateDescClick.bind(this);
    this.handleLengthAscClick = this.handleLengthAscClick.bind(this);
    this.handleLengthDescClick = this.handleLengthDescClick.bind(this);
    this.handlePopularityDescClick = this.handlePopularityDescClick.bind(this);

    this.state = {
      sortBy: this.props.sortBy,
      sortDirection: this.props.sortDirection
    }
  }

  handleDateDescClick() {
    let stateObject = { sortDirection: 'desc', sortBy: 'date' };

    global.EventHub.trigger('changeSort', stateObject);
    this.setState(stateObject)
  }

  handleLengthAscClick() {
    let stateObject = { sortDirection: 'asc', sortBy: 'length' };

    global.EventHub.trigger('changeSort', stateObject);
    this.setState(stateObject)
  }

  handleLengthDescClick() {
    let stateObject = { sortDirection: 'desc', sortBy: 'length' };

    global.EventHub.trigger('changeSort', stateObject);
    this.setState(stateObject)
  }

  handlePopularityDescClick() {
    let stateObject = { sortDirection: 'desc', sortBy: 'popularity' };

    global.EventHub.trigger('changeSort', stateObject);
    this.setState(stateObject)
  }

  getClassName(sortBy, sortDirection) {
    if (sortBy === this.state.sortBy && sortDirection === this.state.sortDirection) {
      return 'sort-inputs__item--active';
    }

    return '';
  }

  render() {
    return (
      <div className='sort-inputs'>
        <div className='row'>
          <div className='col12'>
            <ul className='inline-list sort-inputs__list'>
              <li className='sort-inputs__list-item-hide-small'><span className='sort-inputs__label'>{this.props.locale === 'de' ? 'Sortierung:' : 'Sort:'}</span></li>
              <li><a onClick={this.handlePopularityDescClick} className={'sort-inputs__item ' + this.getClassName('popularity', 'desc')}>{this.props.locale === 'de' ? 'Beliebtheit' : 'popularity'}</a></li>
              <li className='sort-inputs__list-item-hide-medium'><a onClick={this.handleLengthDescClick} className={'sort-inputs__item ' + this.getClassName('length', 'desc')}>{this.props.locale === 'de' ? 'Dauer (absteigend)' : 'duration (longest first)'}</a></li>
              <li className='sort-inputs__list-item-hide-medium'><a onClick={this.handleLengthAscClick} className={'sort-inputs__item ' + this.getClassName('length', 'asc')}>{this.props.locale === 'de' ? 'Dauer (aufsteigend)' : 'duration (shortest first)'}</a></li>
              <li><a onClick={this.handleDateDescClick} className={'sort-inputs__item ' + this.getClassName('date', 'desc')}>{this.props.locale === 'de' ? 'Neueste' : 'most recent'}</a></li>
            </ul>
          </div>
        </div>
      </div>
    )
  }
};

SoundEffectListingSortInputs.defaultProps = {
  sortBy: 'popularity',
  sortDirection: 'desc'
}

SoundEffectListingSortInputs.propTypes = {
  locale: PropTypes.string
};

// INIT

let elements = document.querySelectorAll('[data-react-component=SoundEffectListingSortInputs]');

elements.forEach(function(element) {
  let reactProps = JSON.parse(element.dataset.reactProps);

  ReactDOM.render(
    <React.StrictMode>
      <SoundEffectListingSortInputs {...reactProps} />
    </React.StrictMode>,
    element
  );
})


export default SoundEffectListingSortInputs;
