import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import classNames from 'classnames/bind';
// import $ from 'jquery';
import ahoy from 'ahoy.js';
import confetti from 'canvas-confetti';

import UsageLicenseTypeSelect from '~/components/legacy/UsageLicenseTypeSelect.jsx';

class SoundEffectInfo extends Component {

  // mixins : [UsageLicenseTypeSelectMixin, SoundEffectFavoriteMixin],

  constructor(props) {
    super(props);

    this.handleLayoutDownloadClick = this.handleLayoutDownloadClick.bind(this);
    this.handleCartLinkClick = this.handleCartLinkClick.bind(this);
    this.triggerDemoDownload = this.triggerDemoDownload.bind(this);
    this.delegateFavoriteClick = this.delegateFavoriteClick.bind(this);
    this.handleShareClick = this.handleShareClick.bind(this);
    this.handleCartClick = this.handleCartClick.bind(this);
    this.handleFavoriteClick = this.handleFavoriteClick.bind(this);


    this.state = {
      data: this.props.data,
      displayCopyHint: false,
      shareTrackingFired: false,
      layoutDownloadTrackingFired: false,
      isVariant: false,
      variantTitle: '',
      showUsageLicenseSelect: false,
      soundEffectFavorites: this.props.soundEffectFavorites
    }
  }

  componentDidMount() {
    global.EventHub.on('updateSoundEffectInfo', function (data) {
      this.setState({ data: data[0], isVariant: false, variantTitle: '' })
    }.bind(this));

    global.EventHub.on('updateSoundEffectVariantInfo', function (data) {
      this.setState({ data: data[0], isVariant: true, variantTitle: data[1].title })
    }.bind(this));

    global.EventHub.on('updateCart', function (data) {
      this.setState({ cart_items: data[0] });
    }.bind(this));

    global.on('updateSoundEffectFavorites', function (data) {
      this.setState({ soundEffectFavorites: data[0] });
    }.bind(this));
  }

  handleFavoriteClick(sound_effect_id) {
    $.ajax({
      url: "/sound_effect_favorite?sound_effect_id=" + sound_effect_id,
      type: 'POST'
    }).done(function (data) {
      if (!(this.soundEffectIsFavorited(sound_effect_id, this.state.soundEffectFavorites))) {
        global.EventHub.trigger('soundEffectFavorited', sound_effect_id);
      }

      global.EventHub.trigger('updateSoundEffectFavorites', data)
    }.bind(this));
  }

  soundEffectIsFavorited(sound_effect_id, favorites) {
    let i,
      favoritesLength = favorites && favorites.length ? favorites.length : 0;

    for (i = 0; i < favoritesLength; i += 1) {
      if (favorites[i].sound_effect_id === sound_effect_id) {
        return true;
      }
    }
    return false;
  }

  updateCart(usageLicenseTypeId, exposurePackId, forFx) {
    let itemId = this.props.id || this.state.data.id,
      i,
      updateUrl;

    if (exposurePackId) {
      updateUrl = "/cart?exposure_pack_id=" + exposurePackId + "&usage_license_type_id=" + usageLicenseTypeId;
    } else if (forFx) {
      updateUrl = "/cart?sound_effect_id=" + itemId + "&usage_license_type_id=" + usageLicenseTypeId;
    } else {
      updateUrl = "/cart?track_id=" + itemId + "&usage_license_type_id=" + usageLicenseTypeId;
    }

    $.ajax({
      url: updateUrl,
      type: 'POST'
    }).done(function (data) {

      global.EventHub.trigger('updateCart', data)

      for (i = 0; i < data.length; i += 1) {
        if (data[i].track_id === itemId) {
          if (typeof plausible === 'function') {
            plausible('track_add_cart', { props: { track_id: itemId, usage_license_type_id: usageLicenseTypeId, location: window.location.href } });
          }

          ahoy.track('track_add_cart', { track_id: itemId, usage_license_type_id: usageLicenseTypeId, location: window.location.href, categorization_processed: false })
        } else if (data[i].sound_effect_id === itemId) {
          if (typeof plausible === 'function') {
            plausible('sound_effect_add_cart', { props: { sound_effect_id: itemId, usage_license_type_id: usageLicenseTypeId, location: window.location.href } });
          }

          ahoy.track('sound_effect_add_cart', { sound_effect_id: itemId, usage_license_type_id: usageLicenseTypeId, location: window.location.href, categorization_processed: false })
        }
      }

      if (typeof fbq === 'function') {
        fbq('track', 'AddToWishlist', {
          content_ids: itemId.toString(),
          content_type: 'product',
        });
      }

    }.bind(this));
  }

  handleCartClick(e) {
    e.preventDefault();
    this.props.handleCartClick(this.props.id);
  }

  getSelectedUsageLicenseTypeIds() {
    var ids = [],
      that = this,
      trackId = this.props.id || that.state.data.id,
      cart_items = this.state.cart_items || this.props.cart_items;

    cart_items.forEach(function (element) {
      if (element.track_id === trackId) {
        ids.push(element.usage_license_type_id);
      }
    });

    return ids;
  }


  handleLayoutDownloadClick(e) {
    this.triggerDemoDownload();

    if (typeof confetti !== 'undefined') {
      var rect = e.target.getBoundingClientRect();
      console.log(rect.top, rect.right, rect.bottom, rect.left);

      confetti({
        particleCount: 200,
        startVelocity: 30,
        angle: 90,
        spread: 60,
        shapes: ['circle'],
        origin: {
          x: (rect.left + 10) / window.innerWidth,
          // since they fall down, start a bit higher than random
          y: (rect.top + 20) / window.innerHeight
        },
        colors: ['#e97900', '#d77812', '#ffd6aa', '#43ce00', '#63de00', '#a4d08f', '#52489C', '#635b9a', '#baafff']
      });
    }
  }

  handleCartLinkClick(e) {
    e.preventDefault();
    this.setState({ showUsageLicenseSelect: !this.state.showUsageLicenseSelect });
  }

  triggerDemoDownload() {
    var link = document.createElement("a"),
      uri = '/c/sound_effects/' + this.state.data.id + '/download_layout',
      isChrome = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor),
      isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;

    // If you don't know the name or want to use
    // the webserver default set name = ''

    link.href = uri

    if ($('[data-logged-in]').length === 0) {
      // not logged in
      link.setAttribute('target', '_blank');
    } else {
      // logged in
      if (!isChrome && !isFirefox) {
        link.setAttribute('download', '');
      }
    }

    if (!this.state.layoutDownloadTrackingFired) {
      if (typeof plausible === 'function') {
        plausible(this.props.freeMode ? 'sound_effect_layout_download_free' : 'sound_effect_layout_download', { props: { sound_effect_id: this.state.data.id, location: window.location.href } });
      }

      ahoy.track(this.props.freeMode ? 'sound_effect_layout_download_free' : 'sound_effect_layout_download', { sound_effect_id: this.state.data.id, location: window.location.href, categorization_processed: false })
      this.setState({ layoutDownloadTrackingFired: true });
    }

    document.body.appendChild(link);

    link.click();
    link.remove();
  }


  delegateFavoriteClick(e) {
    e.preventDefault();

    this.handleFavoriteClick(this.state.data.id);

    if (!(this.soundEffectIsFavorited(this.state.data.id, this.state.soundEffectFavorites)) && typeof confetti !== 'undefined') {
      var rect = e.target.getBoundingClientRect();
      console.log(rect.top, rect.right, rect.bottom, rect.left);

      confetti({
        particleCount: 200,
        startVelocity: 30,
        angle: 90,
        spread: 60,
        shapes: ['circle'],
        origin: {
          x: (rect.left + 10) / window.innerWidth,
          // since they fall down, start a bit higher than random
          y: (rect.top + 20) / window.innerHeight
        },
        colors: ['#e97900', '#d77812', '#ffd6aa', '#43ce00', '#63de00', '#a4d08f', '#52489C', '#635b9a', '#baafff']
      });
    }
  }

  handleShareClick(e) {
    e.preventDefault();

    if (this.state.displayCopyHint) {
      this.setState({ displayCopyHint: false });
    } else {
      if (!this.state.shareTrackingFired) {
        if (typeof plausible === 'function') {
          plausible('sound_effect_share', { props: { sound_effect_id: this.state.data.id, location: window.location.href } });
        }

        ahoy.track('sound_effect_share', { sound_effect_id: this.state.data.id, location: window.location.href, categorization_processed: false })
      }

      if (navigator.share) {
        // Web Share API is supported
        navigator.share({
          title: (this.state.data.display_title || this.state.data.title) + ' | ' + (I18n.locale === 'de' ? 'GEMAfreie Musik auf audiocrowd.net' : 'Royalty-free music on audiocrowd.net'),
          url: this.state.data.showcase_url + '?utm_source=share_link&utm_medium=referral'
        })
        this.setState({ shareTrackingFired: true });
      } else {
        // Fallback
        this.copyToClipboard(this.state.data.showcase_url + '?utm_source=share_link&utm_medium=referral');
        this.setState({ displayCopyHint: true, shareTrackingFired: true }, function () {
          setTimeout(function () {
            this.setState({ displayCopyHint: false })
          }.bind(this), 5000)
        });
      }

      if (typeof confetti !== 'undefined') {
        var rect = e.target.getBoundingClientRect();
        console.log(rect.top, rect.right, rect.bottom, rect.left);

        confetti({
          particleCount: 200,
          startVelocity: 30,
          angle: 90,
          spread: 60,
          shapes: ['circle'],
          origin: {
            x: (rect.left + 10) / window.innerWidth,
            // since they fall down, start a bit higher than random
            y: (rect.top + 20) / window.innerHeight
          },
          colors: ['#e97900', '#d77812', '#ffd6aa', '#43ce00', '#63de00', '#a4d08f', '#52489C', '#635b9a', '#baafff']
        });
      }
    }
  }


  copyToClipboard(str) {
    var el = document.createElement('textarea');
    el.value = str;
    el.setAttribute('readonly', '');
    el.style.position = 'absolute';
    el.style.left = '-9999px';
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
  }

  // handleClick: function(e) {
  //     var that = this;
  //     e.preventDefault();
  //     $.ajax({
  //         url: "/cart?track_id=" + this.state.data.id + (this.trackIsInCart(this.state.data.id, this.props.tracks_in_cart) ? '' : '&add=1'),
  //         type: 'POST'
  //     }).done(function(data) {
  //         global.EventHub.trigger('updateCart', data)
  //     }.bind(this));
  // },

  // trackIsInCart : function(trackId, tracks) {
  //     var i,
  //         tracksLength = tracks && tracks.length ? tracks.length : 0;

  //     for (i = 0; i < tracksLength; i += 1) {
  //         if (tracks[i].track_id === trackId) {
  //             return true;
  //         }
  //     }
  //     return false;
  // },

  getUsageLicenseSelect() {
    var usageLicenseTypes = [];

    if (this.state.data.sound_effect_id) {
      usageLicenseTypes = this.props.usageLicenseTypes.filter(function (usageLicenseType) { return usageLicenseType.for_fx === true });
    } else {
      usageLicenseTypes = this.props.usageLicenseTypes.filter(function (usageLicenseType) { return usageLicenseType.for_fx === false });
    }

    return (
      <UsageLicenseTypeSelect
        addToCart={this.props.i18n.add_to_cart}
        discount={this.state.data.discount}
        subscribedUsageLicenseTypes={this.props.subscribedUsageLicenseTypes}
        usageLicenseTypes={usageLicenseTypes}
        updateCart={this.updateCart}
        pricing_path={this.props.pricing_path}
        selectedUsageLicenseTypeIds={this.getSelectedUsageLicenseTypeIds()}
        i18n={this.props.i18n}
        visible={this.state.showUsageLicenseSelect}
        position={this.props.usageLicensePosition}
        handleCartClick={this.handleCartLinkClick}
        trackTitle={this.state.data.display_title || this.state.data.title} />
    )
  }

  render() {
    var classes = classNames({
      'track-info': true,
      'track-info--hide-cart': this.props.hide_add_to_cart
    }),
      loop,
      variableTempo,
      cartLink,
      toCheckoutLink,
      instruments,
      genres,
      moods,
      tags,
      trackTitle,
      layoutDownloadLink,
      showcaseLink,
      favoriteLink,
      showCaseLink,
      copyHint,
      price,
      shareLink;

    // if (this.props.usageLicenseTypes.length > 0) {
    //     price = <span className='track-info__price'>ab {this.props.usageLicenseTypes[0].formatted_gross_price_eur}</span>;

    //     if (this.state.data.discount) {
    //        price = <span className='track-info__price'>ab {this.props.usageLicenseTypes[0].formatted_discount_gross_price_eur}</span>;
    //     }

    //     if (this.state.data.free) {
    //       price = <span className='track-info__price'>{this.props.i18n.free}</span>;
    //     }
    // }

    if (this.state.data) {

      if (this.state.data.tags) {
        tags = this.state.data.sound_effect_tags.map(function (tag, index) {
          return (
            <span className="track-info__label track-info__label-label track-info__label-tag">{tag}</span>
          );
        });
      }

      if (this.state.displayCopyHint && !navigator.share) {
        copyHint = <div className="track-info__copy-hint">
          <i className='track-info__copy-hint-icon fa fa-check'></i>
          <span className='track-info__copy-hint-text'>{I18n.locale === 'de' ? 'Die Track-URL wurde in die Zwischenablage kopiert.' : 'The track URL was copied to your clipboard.'}</span>
        </div>;
      }

      trackTitle = <a className='track-info__title-link' href={this.state.data.showcase_url} title={this.state.data.display_title || this.state.data.title} target='_blank'>{this.state.data.display_title || this.state.data.title}{this.state.isVariant && this.state.variantTitle.length > 0 ? (' (' + this.state.variantTitle + ')') : ''}</a>;

      if (this.props.show_meta && !this.props.hideActions) {
        cartLink = <a className="track-info__cart-link track-info__cart-link-add" href="#" onClick={this.handleCartLinkClick}><span className='track-info__cart-link-icons'><i className="fa fa-plus"></i><i className="fa fa-shopping-cart"></i></span></a>

        layoutDownloadLink = (<a className="track-info__download-link" onClick={this.handleLayoutDownloadClick} title={this.props.i18n.layout_download}>
          <div className='tooltip'>
            <i className='fa fa-download'></i>
            <span className='track-info__download-link-label'>{this.props.i18n.layout_download}</span>
            <div className='tooltip__content'>{I18n.locale === 'de' ? 'DEMO-Download: Wenn Sie die Musik erst ausprobieren möchten, können Sie eine Demo-Version herunterladen.' : 'Demo download: If you want to try the music first, you can download a demo version.'}</div>
          </div>
        </a>);
      }

      if (!this.props.hideActions) {
        if (this.soundEffectIsFavorited(this.state.data.sound_effect_id, this.state.soundEffectFavorites)) {
          favoriteLink = <a className='track-info__favorite-link track-info__favorite-link--favorited' onClick={this.delegateFavoriteClick}><i className='fa fa-heart'></i><span className='track-info__favorite-link-label'>{I18n.locale === 'de' ? 'gemerkt' : 'remembered'}</span></a>;
        } else {
          favoriteLink = <a className='track-info__favorite-link' onClick={this.delegateFavoriteClick}><i className='fa fa-heart-o'></i><span className='track-info__favorite-link-label'>{I18n.locale === 'de' ? 'merken' : 'remember'}</span></a>;
        }

        shareLink = (
          <div className="track-info__showcase-link" title={this.state.data.title} onClick={this.handleShareClick}>
            {copyHint}
            <i className="fa fa-share-alt"></i>
            <span className='track-info__showcase-link-label'>{I18n.locale === 'de' ? 'Teilen' : 'Share'}</span>
          </div>
        );
      }


      return (
        <div className={classes}>
          <span className='track-info__title'>
            <span className='track-info__id'>
              {this.state.data.id}
            </span>
            {trackTitle}
          </span>
          {favoriteLink}
          {cartLink}
          {shareLink}
          {this.getUsageLicenseSelect()}
        </div>
      );
    } else {
      return (<div></div>);
    }
  }
};

SoundEffectInfo.defaultProps = {
  data: {
    tracks_in_cart: [],
    is_in_cart: false,
    sound_effect_tags: [],
    showcase_url: ''
  },
  i18n: {
    add_to_cart: 'add to cart',
    checkout_now: 'checkout now',
    layout_download: 'layout download'
  },
  checkout_path: 'c/checkouts/new',
  open_in_new_tab: 'open in new tab',
  show_meta: true,
  usageLicensePosition: 'overlay',
  freeMode: false,
  subscribedUsageLicenseTypes: [],
  hideActions: false,
  cart_items: [],
  soundEffectFavorites: []

}

export default SoundEffectInfo;
