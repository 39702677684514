import React, { Component } from 'react';
import SearchTabProFilterContentItem from '~/components/legacy/SearchTabProFilterContentItem.jsx';

class SearchTabProFilterContent extends Component {
  constructor(props) {
    super(props);
  }

  isProFilterActive(category, value) {
      let isActive = false;

      this.props.activeItems.forEach(function(activeItem) {
          if (activeItem.name === category && activeItem.value === value) {
            isActive = true;
          }
      });

      return isActive;
  }

  render() {
      let items = this.props.items.map(function (item, index) {
        return (
          <SearchTabProFilterContentItem key={item.category + '_' + item.value + '_' + index} name={item.category} heading={I18n.locale == 'de' ? item.nameDe : item.nameEn} labels={I18n.locale == 'de' ? item.labelsDe : item.labelsEn} submitProFilterChange={this.props.submitProFilterChange} isProFilterChecked={this.props.isProFilterChecked} active={this.isProFilterActive(item.category, item.value)} value={item.value} />
        );
      }.bind(this)).filter(function (el) {
          return el != null;
      });


      return (
        <div className="search-tab__content-wrapper">
          <div className="search-tab__content search-tab__content--filter">
            {items}
          </div>
          <br />
          <div>
            {this.props.locale === 'de' ? 'Tipp: Mit unserem Lizenz-Assistent finden Sie heraus, welche Einstellung am Besten zu Ihrem Projekt passt.' : "Tip: Use our License Wizard to find out which setting fits your project best."}
            <a target='_blank' className='inline-block margin-left5' href={this.props.locale === 'de' ? '/lizenz-assistent' : 'license-assistant'}>{this.props.locale === 'de' ? 'Zum Lizenz-Assistent' : 'To the license assistant'}</a>
          </div>
        </div>
      )

  }
};

SearchTabProFilterContent.defaultProps = {
  items: [
    {
        id : '',
        name: '',
        active : false,
        prio: false
    }
  ],
  activeItems : [], // [0, 1, 2]
  name: 'proFilters'
};

export default SearchTabProFilterContent;
