import React, { Component } from 'react';
import classNames from 'classnames/bind';

class SearchTab extends Component {

  constructor(props) {
    super(props)

    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(e) {
      e.preventDefault();
      this.props.handleChange(this.props.index);
  }

  render() {
    let classes = classNames({
          'search-tab' : true,
          'search-tab--active' : this.props.active === true,
          'search-tab--last' :  this.props.last === true,
        }),
        tabContent;

    tabContent = this.props.children;

    return (
      <div className={classes}>
        {tabContent}
      </div>
    );
  }
};

export default SearchTab;
