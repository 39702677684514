import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import classNames from 'classnames/bind';
// import $ from 'jquery';

import StaticTrack from '~/components/legacy/StaticTrack.jsx'

class StaticTrackListing extends Component {

  // mixins: [SearchInProgressMixin, TrackFavoriteMixin],

  constructor(props) {
    super(props)

    this.handleCartClick = this.handleCartClick.bind(this)
    this.handleFreeDownloadClick = this.handleFreeDownloadClick.bind(this)
    this.handleFavoriteClick = this.handleFavoriteClick.bind(this)

    this.state = {
      showUsageLicenseSelectForTrackId: [],
      showFreeDownloadDialogForTrackId: [],
      data: this.props.data,
      pagination: this.props.pagination,
      active_track_id: this.props.active_track_id,
      cart_items: this.props.cart_items,
      currentQuery: {
        genres: [],
        tags: [],
        moods: []
      },
      searchInProgress: false,
      animationFinished: true,
      trackFavorites: this.props.trackFavorites
    }
  }

  componentDidMount() {
    global.EventHub.on('playTrack', function (data) {
      this.setState({ active_track_id: data[0], activeTrackVariantId: '' })
    }.bind(this));

    global.EventHub.on('playTrackVariant', function (data) {
      this.setState({ active_track_id: data[1], activeTrackVariantId: data[0] })
    }.bind(this));


    global.EventHub.on('updateCart', function (data) {
      this.setState({ cart_items: data[0] });
    }.bind(this));

    global.EventHub.on('closePopupMenus', function () {
      this.setState({ showUsageLicenseSelectForTrackId: [], showFreeDownloadDialogForTrackId: [] })
    }.bind(this));

    global.EventHub.on('fireSearch', function () {
      $("html, body").animate({ scrollTop: $('.track-listing').offset().top - 320 }, "slow");
      this.setState({ searchInProgress: true, animationFinished: false });
    }.bind(this));

    global.EventHub.on('fireSearchWithoutAnimation', function () {
      this.setState({ searchInProgress: false, animationFinished: true });
    }.bind(this));


    global.EventHub.on('searchSuccess', function () {
      setTimeout(function () {
        this.setState({ searchInProgress: false });
      }.bind(this), 200);
      setTimeout(function () {
        this.setState({ animationFinished: true });
      }.bind(this), 400);
    }.bind(this));

    global.EventHub.on('updateTrackFavorites', function (data) {
      this.setState({ trackFavorites: data[0] });
    }.bind(this));
  }

  handleCartClick(trackId) {
    let stateObject = {},
      indexOfTrackId = this.state.showUsageLicenseSelectForTrackId.indexOf(trackId);

    if (indexOfTrackId === -1) {
      stateObject = {};
      stateObject['showUsageLicenseSelectForTrackId'] = [];
      stateObject['showUsageLicenseSelectForTrackId'].push(trackId);
    } else {
      global.EventHub.trigger('closePopupMenus');
    }

    this.setState(stateObject);
  }

  handleFreeDownloadClick(trackId) {
    let stateObject = {},
      indexOfTrackId = this.state.showFreeDownloadDialogForTrackId.indexOf(trackId);

    if (indexOfTrackId === -1) {
      stateObject = {};
      stateObject['showFreeDownloadDialogForTrackId'] = [];
      stateObject['showFreeDownloadDialogForTrackId'].push(trackId);
    } else {
      global.EventHub.trigger('closePopupMenus');
    }

    this.setState(stateObject);
  }

  trackIsInCart(trackId, cartItems) {
    let i,
      cartItemsLength = cartItems && cartItems.length ? cartItems.length : 0;

    for (i = 0; i < cartItemsLength; i += 1) {
      if (cartItems[i].track_id === trackId) {
        return true;
      }
    }
    return false;
  }

  isUsageLicenseSelectVisible(trackId) {
    if (this.state.showUsageLicenseSelectForTrackId.indexOf(trackId) > -1) {
      return true;
    }

    return false;
  }

  isFreeDownloadDialogVisible(trackId) {
    if (this.state.showFreeDownloadDialogForTrackId.indexOf(trackId) > -1) {
      return true;
    }

    return false;
  }

  shouldRenderUsageLicenseTypeSelect(trackId) {
    let shouldRender = false;

    for (var i = 0; i < this.state.showUsageLicenseSelectForTrackId.length; i += 1) {
      if (this.state.showUsageLicenseSelectForTrackId[i] === trackId && typeof this.props.exposure_pack.id === 'undefined') {
        shouldRender = true;
      }
    }

    return shouldRender;
  }

  getContent() {
    return this.props.content.map(function (content, index) {
      return (
        <div className={this.props.content.length > 4 ? 'track-listing__content track-listing__content--long' : 'track-listing__content'} key={'track_listing_content_' + index}>
          <details className='track-listing__content-details' itemProp="mainEntity" itemScope itemType="https://schema.org/Question" open={content.open ? true : false}>
            <summary className='track-listing__content-title-wrapper' itemProp="name">{content.title}</summary>
            <div className='track-listing__content-description' itemProp="acceptedAnswer" itemScope itemType="https://schema.org/Answer"><div itemProp="text" dangerouslySetInnerHTML={{ __html: content.description }}></div></div>
          </details>
        </div>
      )
    }.bind(this))
  }

  getNameFor(itemName, id) {
    return this.props.itemNames[itemName][id];
  }

  isActiveItem(itemName, id) {
    return (this.state.currentQuery[itemName].indexOf(id) > -1);
  }

  getSelectedUsageLicenseTypeIdsForExposurePack() {
    let ids = [],
      that = this,
      exposurePackId = this.props.exposure_pack.id || that.props.data.exposure_pack.id,
      cart_items = this.state.cart_items || this.props.cart_items;

    cart_items.forEach(function (element) {
      if (element.exposure_pack_id === exposurePackId) {
        ids.push(element.usage_license_type_id);
      }
    });

    return ids;
  }

  handleFavoriteClick(trackId) {
    $.ajax({
      url: "/track_favorite?track_id=" + trackId,
      type: 'POST'
    }).done(function (data) {

      if (!(this.trackIsFavorited(trackId, this.state.trackFavorites))) {
        global.EventHub.trigger('trackFavorited', trackId);
      }

      global.EventHub.trigger('updateTrackFavorites', data)
    }.bind(this));
  }

  trackIsFavorited(trackId, favorites) {
    let i,
      favoritesLength = favorites && favorites.length ? favorites.length : 0;

    for (i = 0; i < favoritesLength; i += 1) {
      if (favorites[i].track_id === trackId) {
        return true;
      }
    }
    return false;
  }

  render() {

    var activeTrackId = this.state.active_track_id;
    var activeTrackVariantId = this.state.activeTrackVariantId;
    var content = [];
    var similarTrackHeading;
    var clientListing = [];
    var tracks = this.props.data.map(function (track, index) {
      var nextProps;
      var currentProps = {
        id: track.id,
        composerName: track.composer_name,
        composerUrl: track.composer_url,
        showcaseUrl: track.showcase_url,
        i18n: this.props.i18n,
        title: track.title,
        display_title: track.display_title,
        active: track.id === activeTrackId,
        activeTrackVariantId: activeTrackVariantId,
        key: "track-" + track.id,
        length: track.length,
        short_description: track.short_description,
        inCart: this.trackIsInCart(track.id, this.state.cart_items),
        free: track.free,
        hideItemIdList: this.props.hideItemIdList,
        genre_id_list: track.genre_id_list,
        tag_id_list: track.tag_id_list,
        mood_id_list: track.mood_id_list,
        isActiveItem: this.isActiveItem,
        discount: track.discount,
        copyrightYear: track.copyright_year,
        recently_activated: track.recently_activated,
        usageLicenseTypes: this.props.usageLicenseTypes.filter(function (usageLicenseType) { return usageLicenseType.for_fx === false }),
        subscribedUsageLicenseTypes: this.props.subscribedUsageLicenseTypes,
        pricing_path: this.props.pricing_path,
        cart_items: this.state.cart_items,
        trackFavorites: this.state.trackFavorites,
        favorited: this.trackIsFavorited(track.id, this.state.trackFavorites),
        handleFavoriteClick: this.handleFavoriteClick,
        showUsageLicenseSelect: this.isUsageLicenseSelectVisible(track.id),
        showFreeDownloadDialog: this.isFreeDownloadDialogVisible(track.id),
        handleCartClick: this.handleCartClick,
        handleFreeDownloadClick: this.handleFreeDownloadClick,
        show_download_button: this.props.show_download_button,
        exposure_pack: this.props.exposure_pack,
        score: track.score,
        getNameFor: this.getNameFor,
        freeMode: this.props.freeMode,
        slim: this.props.slim,
        track_variants: track.track_variants,
        track_image_markup: track.track_image,
        youtube_content_id_enabled: track.youtube_content_id_enabled,
        locale: this.props.locale
      }

      if (typeof this.props.data[index + 1] !== 'undefined') {
        var nextTrack = this.props.data[index + 1];
        var nextProps = {
          id: nextTrack.id,
          composerName: nextTrack.composer_name,
          composerUrl: nextTrack.composer_url,
          showcaseUrl: nextTrack.showcase_url,
          i18n: this.props.i18n,
          title: nextTrack.title,
          display_title: nextTrack.display_title,
          active: nextTrack.id === activeTrackId,
          activeTrackVariantId: activeTrackVariantId,
          key: "track-" + nextTrack.id,
          length: nextTrack.length,
          short_description: nextTrack.short_description,
          inCart: this.trackIsInCart(nextTrack.id, this.state.cart_items),
          free: nextTrack.free,
          hideItemIdList: this.props.hideItemIdList,
          genre_id_list: nextTrack.genre_id_list,
          tag_id_list: nextTrack.tag_id_list,
          mood_id_list: nextTrack.mood_id_list,
          isActiveItem: this.isActiveItem,
          discount: nextTrack.discount,
          copyrightYear: nextTrack.copyright_year,
          recently_activated: nextTrack.recently_activated,
          usageLicenseTypes: this.props.usageLicenseTypes.filter(function (usageLicenseType) { return usageLicenseType.for_fx === false }),
          subscribedUsageLicenseTypes: this.props.subscribedUsageLicenseTypes,
          pricing_path: this.props.pricing_path,
          cart_items: this.state.cart_items,
          trackFavorites: this.state.trackFavorites,
          favorited: this.trackIsFavorited(nextTrack.id, this.state.trackFavorites),
          handleFavoriteClick: this.handleFavoriteClick,
          showUsageLicenseSelect: this.isUsageLicenseSelectVisible(nextTrack.id),
          showFreeDownloadDialog: this.isFreeDownloadDialogVisible(nextTrack.id),
          handleCartClick: this.handleCartClick,
          handleFreeDownloadClick: this.handleFreeDownloadClick,
          show_download_button: this.props.show_download_button,
          exposure_pack: this.props.exposure_pack,
          score: nextTrack.score,
          getNameFor: this.getNameFor,
          freeMode: this.props.freeMode,
          slim: this.props.slim,
          track_variants: nextTrack.track_variants,
          handleVariantClick: this.handleVariantClick,
          track_image_markup: nextTrack.track_image,
          youtube_content_id_enabled: nextTrack.youtube_content_id_enabled,
          locale: this.props.locale
        }
      }

      if (typeof this.props.data[index - 1] !== 'undefined') {
        var prevTrack = this.props.data[index - 1];
        var prevProps = {
          id: prevTrack.id,
          composerName: prevTrack.composer_name,
          composerUrl: prevTrack.composer_url,
          showcaseUrl: prevTrack.showcase_url,
          i18n: this.props.i18n,
          title: prevTrack.title,
          display_title: prevTrack.display_title,
          active: prevTrack.id === activeTrackId,
          activeTrackVariantId: activeTrackVariantId,
          key: "track-" + prevTrack.id,
          length: prevTrack.length,
          short_description: prevTrack.short_description,
          inCart: this.trackIsInCart(prevTrack.id, this.state.cart_items),
          free: prevTrack.free,
          hideItemIdList: this.props.hideItemIdList,
          genre_id_list: prevTrack.genre_id_list,
          tag_id_list: prevTrack.tag_id_list,
          mood_id_list: prevTrack.mood_id_list,
          isActiveItem: this.isActiveItem,
          discount: prevTrack.discount,
          copyrightYear: prevTrack.copyright_year,
          recently_activated: prevTrack.recently_activated,
          usageLicenseTypes: this.props.usageLicenseTypes.filter(function (usageLicenseType) { return usageLicenseType.for_fx === false }),
          subscribedUsageLicenseTypes: this.props.subscribedUsageLicenseTypes,
          pricing_path: this.props.pricing_path,
          cart_items: this.state.cart_items,
          trackFavorites: this.state.trackFavorites,
          favorited: this.trackIsFavorited(prevTrack.id, this.state.trackFavorites),
          handleFavoriteClick: this.handleFavoriteClick,
          showUsageLicenseSelect: this.isUsageLicenseSelectVisible(prevTrack.id),
          showFreeDownloadDialog: this.isFreeDownloadDialogVisible(prevTrack.id),
          handleCartClick: this.handleCartClick,
          handleFreeDownloadClick: this.handleFreeDownloadClick,
          show_download_button: this.props.show_download_button,
          exposure_pack: this.props.exposure_pack,
          score: prevTrack.score,
          getNameFor: this.getNameFor,
          freeMode: this.props.freeMode,
          slim: this.props.slim,
          track_variants: prevTrack.track_variants,
          handleVariantClick: this.handleVariantClick,
          track_image_markup: prevTrack.track_image,
          youtube_content_id_enabled: prevTrack.youtube_content_id_enabled,
          locale: this.props.locale
        }
      }

      return (
        <div key={'track_wrapper_' + track.id}>
          <StaticTrack {...currentProps} next_track={nextProps} prev_track={prevProps} key={'track_static_list' + track.id} />
          {similarTrackHeading && index === 0 ? similarTrackHeading : ''}
          {content.length === 0 ? '' : (<div className='track-listing__content-wrapper' itemScope itemType="https://schema.org/FAQPage"><h2 className='text-like-h4'>{I18n.locale === 'de' ? 'Oft gestellte Fragen' : 'FAQ'}</h2><div className='track-listing__content-symbol'><i className='fa fa-info'></i></div><div className='track-listing__contents'>{content}</div></div>)}
          {clientListing.length === 0 ? '' : (<div className='track-listing__client-wrapper'><h2 className='text-like-h4'>{I18n.locale === 'de' ? 'Diese Marken vertrauen auf unsere Komponisten' : 'These brands rely on our composers'}</h2><div className='track-listing__clients'>{clientListing}</div></div>)}
        </div>
      );

    }.bind(this));

    var classes = classNames({
      'static-track-listing': true,
      'is-empty': this.props.data.length === 0
    });

    return (
      <div className={classes} ref='staticTrackListing'>
        {tracks.length > 0 ? tracks : (this.props.data.length == 0 ? '' : I18n.t('no_songs_available'))}
      </div>
    )
  }
};

StaticTrackListing.defaultProps = {
  active_track_id: 0,
  composer_page_permalink: false,
  totalCount: 250,
  pricing_path: '',
  freeMode: false,
  slim: false,
  i18n: {
    next: 'Next',
    previous: 'Previous',
    free: 'free',
    recently_created: 'new'
  },
  pagination: {
    current_page: 1,
    next_page: '',
    prev_page: '',
    total_pages: 1,
    total_count: 10
  },
  usageLicenseTypes: [],
  subscribedUsageLicenseTypes: [],
  content: [],
  show_download_button: false,
  hideItemIdList: false,
  trackFavorites: [],
  locale: 'de'
};

// INIT
let elements = document.querySelectorAll('[data-react-component=StaticTrackListing]');

elements.forEach(function (element) {
  let reactProps = JSON.parse(element.dataset.reactProps);

  ReactDOM.render(
    <React.StrictMode>
      <StaticTrackListing {...reactProps} />
    </React.StrictMode>,
    element
  );
})


export default StaticTrackListing;

