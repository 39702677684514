import React, { Component } from 'react';
import classNames from 'classnames/bind';
// // import $ from 'jquery';
import confetti from 'canvas-confetti';
import ahoy from 'ahoy.js';

import UsageLicenseTypeSelect from '~/components/legacy/UsageLicenseTypeSelect.jsx';

class SoundEffect extends Component {

  // mixins : [UsageLicenseTypeSelectMixin],

  constructor(props) {
    super(props)

    this.handleClick = this.handleClick.bind(this);
    this.handleShareClick = this.handleShareClick.bind(this);
    this.handlefreeDownloadBuyInShopClick = this.handlefreeDownloadBuyInShopClick.bind(this);
    this.handlefreeDownloadAgreeClick = this.handlefreeDownloadAgreeClick.bind(this);
    this.handleNextClick = this.handleNextClick.bind(this);
    this.handlePrevClick = this.handlePrevClick.bind(this);
    this.handleFavoriteClick = this.handleFavoriteClick.bind(this);
    this.handleFreeDownloadClick = this.handleFreeDownloadClick.bind(this);
    this.handleMouseEnter = this.handleMouseEnter.bind(this);
    this.handleMouseLeave = this.handleMouseLeave.bind(this);
    this.handleVariantClick = this.handleVariantClick.bind(this);
    this.handleCartClick = this.handleCartClick.bind(this);
    this.updateCart = this.updateCart.bind(this);

    this.state = {
      playing: false,
      isHovered: false,
      showUsageLicenseSelect: false
    }
  }

  componentDidMount() {
    let that = this;

    global.EventHub.on('updateSoundEffectInfo', function (data) {
      if (data[0].id !== that.props.id) {
        that.setState({
          playing: false
        });
      }
    });

    global.EventHub.on('updateSoundEffectVariantInfo', function (data) {
      if (data[0].id !== that.props.id) {
        that.setState({
          playing: false
        });
      }
    });


    global.EventHub.on('audioPlayerPlay', function (data) {
      if (data[0].id === that.props.id) {
        that.setState({
          playing: true
        });
      }
    });


    global.EventHub.on('audioPlayerStop', function () {
      if (that.state.playing) {
        that.setState({
          playing: false
        });
      }
    });

    global.EventHub.on('audioPlayerEnded', function () {
      if (that.state.playing) {
        that.setState({
          playing: false
        });
      }
    });
  }


  updateCart(usageLicenseTypeId, exposurePackId, forFx) {
    let itemId = this.props.id || this.state.data.id,
      i,
      updateUrl;

    if (exposurePackId) {
      updateUrl = "/cart?exposure_pack_id=" + exposurePackId + "&usage_license_type_id=" + usageLicenseTypeId;
    } else if (forFx) {
      updateUrl = "/cart?sound_effect_id=" + itemId + "&usage_license_type_id=" + usageLicenseTypeId;
    } else {
      updateUrl = "/cart?track_id=" + itemId + "&usage_license_type_id=" + usageLicenseTypeId;
    }

    $.ajax({
      url: updateUrl,
      type: 'POST'
    }).done(function (data) {

      global.EventHub.trigger('updateCart', data)

      for (i = 0; i < data.length; i += 1) {
        if (data[i].track_id === itemId) {
          if (typeof plausible === 'function') {
            plausible('track_add_cart', { props: { track_id: itemId, usage_license_type_id: usageLicenseTypeId, location: window.location.href } });
          }

          ahoy.track('track_add_cart', { track_id: itemId, usage_license_type_id: usageLicenseTypeId, location: window.location.href, categorization_processed: false })
        } else if (data[i].sound_effect_id === itemId) {
          if (typeof plausible === 'function') {
            plausible('sound_effect_add_cart', { props: { sound_effect_id: itemId, usage_license_type_id: usageLicenseTypeId, location: window.location.href } });
          }

          ahoy.track('sound_effect_add_cart', { sound_effect_id: itemId, usage_license_type_id: usageLicenseTypeId, location: window.location.href, categorization_processed: false })
        }
      }

      if (typeof fbq === 'function') {
        fbq('track', 'AddToWishlist', {
          content_ids: itemId.toString(),
          content_type: 'product',
        });
      }

    }.bind(this));
  }

  handleCartClick(e) {
    e.preventDefault();
    this.props.handleCartClick(this.props.id);
  }

  getSelectedUsageLicenseTypeIds() {
    var ids = [],
      that = this,
      trackId = this.props.id || that.state.data.id,
      cart_items = this.state.cart_items || this.props.cart_items;

    cart_items.forEach(function (element) {
      if (element.track_id === trackId) {
        ids.push(element.usage_license_type_id);
      }
    });

    return ids;
  }





  handleClick(e) {
    e.preventDefault();
    global.EventHub.trigger('playSoundEffect', this.props.id);
    global.EventHub.trigger('updateSoundEffectInfo', this.props);
    this.setState({
      playing: !this.state.playing
    });
  }

  handleShareClick(e) {
    e.preventDefault();

    if (this.state.displayCopyHint) {
      this.setState({ displayCopyHint: false });
    } else {
      if (!this.state.shareTrackingFired) {
        if (typeof plausible === 'function') {
          plausible('sound_effect_share', { props: { sound_effect_id: this.props.id, location: window.location.href } });
        }

        ahoy.track('sound_effect_share', { sound_effect_id: this.props.id, location: window.location.href, categorization_processed: false });
      }

      if (navigator.share) {
        // Web Share API is supported
        navigator.share({
          title: (this.props.display_title || this.props.title) + ' | ' + (this.props.locale === 'de' ? 'GEMAfreie Musik auf audiocrowd.net' : 'Royalty-free music on audiocrowd.net'),
          url: this.props.showcaseUrl + '?utm_source=share_link_teaser&utm_medium=referral'
        })
        this.setState({ shareTrackingFired: true });
      } else {
        // Fallback
        this.copyToClipboard(this.props.showcaseUrl + '?utm_source=share_link_teaser&utm_medium=referral');
        this.setState({ displayCopyHint: true, shareTrackingFired: true }, function () {
          setTimeout(function () {
            this.setState({ displayCopyHint: false })
          }.bind(this), 5000)
        });
      }

      if (typeof confetti !== 'undefined') {
        var rect = e.target.getBoundingClientRect();
        console.log(rect.top, rect.right, rect.bottom, rect.left);

        confetti({
          particleCount: 200,
          startVelocity: 30,
          angle: 90,
          spread: 60,
          shapes: ['circle'],
          origin: {
            x: (rect.left + 10) / window.innerWidth,
            // since they fall down, start a bit higher than random
            y: (rect.top + 20) / window.innerHeight
          },
          colors: ['#e97900', '#d77812', '#ffd6aa', '#43ce00', '#63de00', '#a4d08f', '#52489C', '#635b9a', '#baafff']
        });
      }
    }
  }

  copyToClipboard(str) {
    var el = document.createElement('textarea');
    el.value = str;
    el.setAttribute('readonly', '');
    el.style.position = 'absolute';
    el.style.left = '-9999px';
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
  }

  triggerDemoDownload() {
    var link = document.createElement("a"),
      uri = '/c/sound_effects/' + this.props.id + '/download_layout',
      isChrome = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor),
      isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;

    // If you don't know the name or want to use
    // the webserver default set name = ''

    link.href = uri

    if ($('[data-logged-in]').length === 0) {
      // not logged in
      link.setAttribute('target', '_blank');
    } else {
      // logged in
      if (!isChrome && !isFirefox) {
        link.setAttribute('download', '');
      }
    }

    if (typeof plausible === 'function') {
      plausible('sound_effect_layout_download', { props: { sound_effect_id: this.props.id, location: window.location.href } });
    }

    ahoy.track('sound_effect_layout_download', { sound_effect_id: this.props.id, location: window.location.href, categorization_processed: false })

    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  handlefreeDownloadBuyInShopClick(e) {
    e.preventDefault();
    var win = window.open(this.props.showcaseUrl, '_blank');
    // var win = window.open((this.props.locale === 'de' ? ('http://localhost:3000/tracks/' + this.props.id + '/free_download') : ('http://localhost:3000/tracks/' + this.props.id + '/free_download')), '_blank');
    win.focus();

    console.log('handle buy in shop click');
  }

  handlefreeDownloadAgreeClick(e) {
    e.preventDefault();

    if (typeof plausible === 'function') {
      plausible('sound_effect_download_free', { props: { track_id: this.props.id, location: window.location.href } });
    }

    ahoy.track('sound_effect_download_free', { track_id: this.props.id, location: window.location.href, categorization_processed: false })

    var win = window.open((this.props.locale === 'de' ? ('/sound_effects/' + this.props.id + '/free_download') : ('/sound_effects/' + this.props.id + '/free_download')), '_blank');
    // var win = window.open((this.props.locale === 'de' ? ('http://localhost:3000/tracks/' + this.props.id + '/free_download') : ('http://localhost:3000/tracks/' + this.props.id + '/free_download')), '_blank');
    win.focus();
  }

  handlefreeDownloadRejectClick(e) {
    e.preventDefault();
    console.log('handle reject click');
  }

  handleNextClick(e) {
    e.preventDefault();
    global.EventHub.trigger('playSoundEffect', this.props.next_sound_effect.id);
    global.EventHub.trigger('updateSoundEffectInfo', this.props.next_sound_effect);
    this.setState({
      playing: !this.state.playing
    });
  }

  handlePrevClick(e) {
    e.preventDefault();
    global.EventHub.trigger('playSoundEffect', this.props.prev_sound_effect.id);
    global.EventHub.trigger('updateSoundEffectInfo', this.props.prev_sound_effect);
    this.setState({
      playing: !this.state.playing
    });
  }

  handleFavoriteClick(e) {
    e.preventDefault();

    this.props.handleFavoriteClick(this.props.id);

    if (!this.props.favorited && typeof confetti !== 'undefined') {
      var rect = e.target.getBoundingClientRect();
      console.log(rect.top, rect.right, rect.bottom, rect.left);

      confetti({
        particleCount: 200,
        startVelocity: 30,
        angle: 90,
        spread: 60,
        shapes: ['circle'],
        origin: {
          x: (rect.left + 10) / window.innerWidth,
          // since they fall down, start a bit higher than random
          y: (rect.top + 20) / window.innerHeight
        },
        colors: ['#e97900', '#d77812', '#ffd6aa', '#43ce00', '#63de00', '#a4d08f', '#52489C', '#635b9a', '#baafff']
      });
    }
  }

  handleFreeDownloadClick(e) {
    e.preventDefault();
    this.props.handleFreeDownloadClick(this.props.id);
  }

  handleFreeDownloadDialogClick(e) {
    e.preventDefault();
  }

  handlefreeDownloadRejectClick(e) {
    e.preventDefault();
    global.EventHub.trigger('closePopupMenus');
  }


  handleSoundEffectTagClick(soundEffectTagId, e) {
    e.preventDefault();
    global.EventHub.trigger('soundEffectTagClick', soundEffectTagId);
  }

  handleMouseEnter(e) {
    e.preventDefault();
    this.setState({
      isHovered: true
    });
  }

  handleMouseLeave(e) {
    e.preventDefault();
    this.setState({
      isHovered: false
    });
  }

  handleVariantClick(variantId, sound_effect_id, variantTitle, e) {
    e.preventDefault();
    global.EventHub.trigger('playSoundEffectVariant', variantId, sound_effect_id);
    this.setState({
      playing: false
    });
  }


  truncate(text, count) {
    return (text.length > count) ? text.substr(0, count - 1) + '...' : text;
  }


  render() {
    let classes = classNames({
      'track': true,
      'track--active': this.props.active,
      'track--slim': this.props.slim,
      'track--free': this.props.free,
      'track--new': this.props.recently_activated,
      'track--favorited': this.props.favorited,
      'track--license-select-open': this.props.showUsageLicenseSelect,
      'track--search-in-progress': this.props.search_in_progress,
      'track--playing': this.state.playing && !(parseInt(this.props.activeSoundEffectVariantId, 10) > 0),
      'track--variant-playing': this.state.playing && (parseInt(this.props.activeSoundEffectVariantId, 10) > 0),
      'track--within-exposure-pack': typeof this.props.exposure_pack !== 'undefined' && this.props.exposure_pack.id
    }),
      cartLink,
      favoriteLink,
      recentlyCreatedLabel,
      cartLinkIcon,
      usageLicenseTypeSelect,
      soundEffectLabels,
      nextButton,
      prevButton,
      composerInfo,
      soundEffectTagItems = [];

    var layoutDownloadLink,
      shareLink,
      freeDownloadDialog,
      copyHint;

    if (this.state.displayCopyHint && !navigator.share) {
      copyHint = <div className="track__copy-hint">
        <div className="track__copy-hint-content">
          <i className='track__copy-hint-icon fa fa-check'></i>
          <span className='track__copy-hint-text'>{this.props.locale === 'de' ? 'Die Track-URL wurde in die Zwischenablage kopiert.' : 'The track URL was copied to your clipboard.'}</span>
        </div>
      </div>;
    }


    if (this.props.showFreeDownloadDialog) {
      freeDownloadDialog = (
        <div className='track__free-download-dialog-bg' onClick={this.handleFreeDownloadDialogClick}>
          <div className='track__free-download-dialog-wrapper'>
            <a href='#' className='track__free-download-dialog-close' title='Close free download dialog' onClick={this.handlefreeDownloadRejectClick}><i className='fa fa-times'></i></a>
            <div className='row'>
              <div className='col12'>
                <div className='text-like-h2'><span className='text-primary'>#{this.props.id} - {this.props.display_title || this.props.title}</span> - Free Download starten</div>
              </div>
            </div>

            <div className='row'>
              <div className='col7 col12-md-down'>
                <div className='text-like-h4'>Bitte verlinken und nennen Sie audiocrowd!</div>
                <ul>

                  <li className='track__free-download-dialog-text'>{this.props.locale === 'de' ? 'Nennen Sie audiocrowd mit Internetadresse https://de.audiocrowd.net in Ihrem Video-Abspann oder Outro!' : 'Name audiocrowd with internet address https://en.audiocrowd.net in your video credits or outro!'}</li>
                  <li className='track__free-download-dialog-text'>
                    {this.props.locale === 'de' ? 'Bitte denken Sie daran, audiocrowd mit einem Link als Quelle anzugeben!' : 'Please remember to mention audiocrowd with a link as source!'}
                  </li>
                  <li className='track__free-download-dialog-text'>{this.props.locale === 'de' ? 'Ist eine Verlinkung nicht möglich, ist der Einsatz der kostenlosen Musik nicht gestattet.' : 'If a link is not possible, the use of free music is not permitted.'}</li>
                </ul>
              </div>
              <div className='col5 col12-md-down'>
                <strong>Beispiel HTML-Code:</strong>
                <br></br>
                <textarea rows='4' value="<a href='https://de.audiocrowd.net' target='_blank' title='GEMAfreie Musik von audiocrowd'>GEMAfreie Musik von audiocrowd</a>"></textarea>
                <br></br>
                <div className='text-like-h4'>Lieber ohne Verlinkung nutzen?</div>
                <p className='track__free-download-dialog-text'>
                  {this.props.locale === 'de' ? 'Möchten Sie keinen Link setzen? Dann können Sie den Musiktitel einfach im Shop lizenzieren.' : "You don't want to set a link? Then you can simply license the music title in the shop."}
                </p>
              </div>
            </div>
            <div className='row'>
              <div className='col7 col12-md-down margin-bottom20-md-down'>
                <a href='#' className='button button--full' onClick={this.handlefreeDownloadAgreeClick}>Ja, ich setze einen Link!</a>
              </div>
              <div className='col5 col12-md-down'>
                <a href={this.props.showcaseUrl} className='button button--ghost button--full' target='_blank' onClick={this.handlefreeDownloadBuyInShopClick}>{this.props.locale === 'de' ? 'Zum Shop' : 'To shop'}</a>
              </div>
            </div>
          </div>
        </div>
      )
    }

    if (!this.props.freeMode) {
      // layoutDownloadLink = <a className="track__layout-download" onClick={this.handleLayoutDownloadClick} title="Layout-Download">
      //     <div className='tooltip'>
      //     <i className='fa fa-download'></i>
      //     <span className='track__download-link-label'>Demo</span>
      //     {/* <div className='tooltip__content'>{this.props.locale === 'de' ? 'DEMO-Download: Wenn Sie die Musik erst ausprobieren möchten, können Sie eine Demo-Version herunterladen. Der Download erfordert ein Kunden-Konto, das Sie nach einem Klick einfach und schnell anlegen können.' : 'Demo download: If you want to try the music first, you can download a demo version. The download requires a customer account, which you can create easily after one click.'}</div> */}
      //     </div>

      //     {/* <i className="fa fa-download"></i><span className="track__layout-download-label">{this.props.layout_download}</span> */}
      // </a>;
    } else {
      layoutDownloadLink = <span className="track__free-download" title="Free-Download" onClick={this.handleFreeDownloadClick}>

        <i className='fa fa-download'></i>
        <span className='track__free-download-link-label'>Download</span>
        {/* <i className="fa fa-download"></i><span className="track__layout-download-label">{this.props.layout_download}</span> */}
      </span>;
    }

    shareLink = (
      <a className="track__share" onClick={this.handleShareClick} title="Share this track">
        <div className='tooltip'>
          <i className='fa fa-share-alt'></i>
          <span className='track__share-link-label'>{this.props.locale === 'de' ? 'Teilen' : 'share'}</span>
          {/* <div className='tooltip__content'>{this.props.locale === 'de' ? 'DEMO-Download: Wenn Sie die Musik erst ausprobieren möchten, können Sie eine Demo-Version herunterladen. Der Download erfordert ein Kunden-Konto, das Sie nach einem Klick einfach und schnell anlegen können.' : 'Demo download: If you want to try the music first, you can download a demo version. The download requires a customer account, which you can create easily after one click.'}</div> */}
        </div>

        {/* <i className="fa fa-download"></i><span className="track__layout-download-label">{this.props.layout_download}</span> */}
      </a>);


    var formatLength = function (length) {
      var minutes = Math.floor(length / 60);
      var seconds = length % 60;

      if (minutes < 10) {
        minutes = "0" + minutes;
      }

      if (seconds < 10) {
        seconds = "0" + seconds;
      }

      return minutes + ':' + seconds;
    };

    var soundEffectVariants = this.props.sound_effect_variants.map(function (variant) {
      return (<a key={'sound_effect_variant_' + variant.id} className={(parseInt(this.props.activeSoundEffectVariantId, 10) === variant.id) ? 'track__variant-wrapper track__variant-wrapper--active' : 'track__variant-wrapper'} onClick={this.handleVariantClick.bind(this, variant.id, variant.track_id, variant.title)}><span className='track__variant-icon'><i className={(this.state.playing && (parseInt(this.props.activeTrackVariantId, 10) === variant.id)) ? 'fa fa-pause' : 'fa fa-play'}></i></span><span className='track__variant-title'>{variant.title}</span></a>)
    }.bind(this));

    var soundEffectVariantInfo;

    if (this.props.sound_effect_variants.length > 0) {
      soundEffectVariantInfo = <span className='track__variant-info'>{this.props.locale === 'de' ? (' Enthält ' + this.props.sound_effect_variants.length + ' zusätzliche ' + (this.props.sound_effect_variants.length === 1 ? 'Variante.' : 'Varianten.')) : (' Including ' + this.props.sound_effect_variants.length + ' additional ' + (this.props.sound_effect_variants.length === 1 ? 'variant.' : 'variants.'))}</span>;
    }

    if (this.props.prev_sound_effect) {
      prevButton = <span onClick={this.handlePrevClick} className='track__prev-link'><i className="fa fa-step-backward"></i></span>;
    } else {
      prevButton = <span className='track__prev-link track__prev-link--inactive'><i className="fa fa-step-backward"></i></span>;
    }

    if (this.props.next_sound_effect) {
      nextButton = <span onClick={this.handleNextClick} className='track__next-link'><i className="fa fa-step-forward"></i></span>;
    } else {
      nextButton = <span className='track__next-link track__next-link--inactive'><i className="fa fa-step-forward"></i></span>;
    }

    if (this.props.favorited) {
      favoriteLink = <a href='#' onClick={this.handleFavoriteClick} className='track__favorite-link'><div className="tooltip"><i className="fa fa-heart"></i><span className='track__favorite-label'>{this.props.locale === 'de' ? 'gemerkt' : 'remembered'}</span></div></a>;
    } else {
      favoriteLink = <a href='#' onClick={this.handleFavoriteClick} className='track__favorite-link'><div className="tooltip"><i className="fa fa-heart-o"></i><span className='track__favorite-label'>{this.props.locale === 'de' ? 'merken' : 'remember'}</span></div></a>;
    }

    if (this.props.recently_activated) {
      recentlyCreatedLabel = <span className='track__label track__label--new'>{this.props.i18n.recently_created}</span>
      soundEffectLabels = <span className="track__labels">{recentlyCreatedLabel}</span>;
    }

    if (this.props.composerName) {
      composerInfo = <span className="track__composer">by <a itemProp="author" className="track__composer-name" href={this.props.composerUrl}>{this.props.composerName}</a></span>;
    } else {
      composerInfo = '';
    }


    if (!this.props.hideItemIdList) {
      soundEffectTagItems = this.props.sound_effect_tag_id_list.map((soundEffectTagId) =>
        <li key={'sound_effect_' + this.props.id + '_sound_effect_tag_' + soundEffectTagId} className={'track__detail-list-item ' + (this.props.isActiveItem('tags', soundEffectTagId) ? 'track__detail-list-item--active' : '')}><a className='tag' itemProp='tag' href='#' onClick={this.handleSoundEffectTagClick.bind(this, soundEffectTagId)}>{this.props.getNameFor('tags', soundEffectTagId)}</a></li>
      );
    }

    if (!this.props.freeMode) {
      if (this.props.inCart) {
        if (this.props.show_download_button) {
          cartLinkIcon = <span className='track__cart-link-icon'><i className="fa fa-download"></i></span>;
        } else {
          cartLinkIcon = <span className='track__cart-link-icon'><i className="track__cart-link-icon-added fa fa-check"></i><i className="fa fa-shopping-cart track__cart-link-icon-cart"></i></span>;
        }
        cartLink = <a className="track__action-button-added-to-cart" href="#" onClick={this.handleCartClick}>{cartLinkIcon}{this.props.remove_from_cart}</a>
      } else {
        if (this.props.show_download_button) {
          cartLinkIcon = <span className='track__cart-link-icon'><i className="fa fa-download"></i></span>;
        } else {
          cartLinkIcon = <span className='track__cart-link-icon'><i className="track__cart-link-icon-add fa fa-plus"></i><i className="fa fa-shopping-cart track__cart-link-icon-cart"></i></span>;
        }
        cartLink = <a className="track__action-button-add-to-cart" href="#" onClick={this.handleCartClick}>{cartLinkIcon}{this.props.add_to_cart}</a>
      }
    }

    if (this.props.showUsageLicenseSelect && !this.props.freeMode) {
      usageLicenseTypeSelect = (
        <UsageLicenseTypeSelect
          locale={this.props.locale}
          addToCart={this.props.i18n.add_to_cart}
          discount={this.props.discount}
          usageLicenseTypes={this.props.usageLicenseTypes}
          subscribedUsageLicenseTypes={this.props.subscribedUsageLicenseTypes}
          updateCart={this.updateCart}
          pricing_path={this.props.pricing_path}
          selectedUsageLicenseTypeIds={this.getSelectedUsageLicenseTypeIds()}
          i18n={this.props.i18n}
          visible={this.props.showUsageLicenseSelect}
          position='overlay'
          show_download_button={this.props.show_download_button}
          handleCartClick={this.handleCartClick}
          trackTitle={this.props.display_title || this.props.title} />
      );
    }


    return (
      <div className={classes} id={'track-' + this.props.id} onMouseEnter={this.handleMouseEnter} onMouseLeave={this.handleMouseLeave} itemScope itemType='https://schema.org/CreativeWork'>
        <span itemProp="copyrightHolder" itemType="https://schema.org/Organization" itemScope="" className="hide-element"><span itemProp="name">audiocrowd GmbH</span></span>
        <span itemProp="publisher" itemType="https://schema.org/Organization" itemScope="" className="hide-element"><span itemProp="name">audiocrowd GmbH</span></span>
        <span itemProp="provider" itemType="https://schema.org/Organization" itemScope="" className="hide-element"><span itemProp="name">audiocrowd GmbH</span></span>
        <meta itemProp="isFamilyFriendly" content="true" />
        <meta itemProp="copyrightYear" content={this.props.copyrightYear}></meta>
        {prevButton}
        <div className='track__play-icon' onClick={this.handleClick}>
          <i className={(this.state.playing && !(parseInt(this.props.activeSoundEffectVariantId, 10) > 0)) ? 'fa fa-pause' : 'fa fa-play'} />
          <div className='track__image' dangerouslySetInnerHTML={{ __html: this.props.sound_effect_image_markup }}></div>
        </div>
        {nextButton}
        <div className="track__title-wrapper">
          <span className="track__title" onClick={this.handleClick}>
            <span className="track__id">{this.props.id}</span>
            <span itemProp="name" className="track__title-value">{this.props.display_title || this.props.title}</span>
            <span>{soundEffectLabels}</span>
            {/* <br />
              <span>{this.props.score}</span> */}
          </span>
          {composerInfo}
        </div>
        <div className="track__length hide-lg-down" onClick={this.handleClick}>
          {formatLength(this.props.length)}
        </div>

        {favoriteLink}
        {shareLink}
        {copyHint}
        {layoutDownloadLink}
        {freeDownloadDialog}

        <div className={(this.props.free && this.props.freeMode) || (this.props.hideAddToCart) ? 'hide-element' : 'track__cart-link'}>
          {cartLink}
        </div>
        {usageLicenseTypeSelect}
        <div className="track__details">
          <div className="track__desc-wrapper">
            <p itemProp="description">{this.props.description}{soundEffectVariantInfo}</p>
          </div>
          <ul className='track__details-list'>
            {soundEffectVariants}
            <li className='track__detail-list-item'>
              {this.props.youtube_content_id_enabled ?
                (
                  <span className="tag" title={this.props.locale === 'de' ? 'Dieser Sound-Effect wird bei Einsatz auf YouTube durch ContentID erkannt. Es ist eine einmalige Freigabe seitens des Urhebers notwendig. Dies ist in der Regel innerhalb weniger Stunden erledigt. Weitere Infos entnehmen Sie dem Lizenzdokument, das Sie beim Kauf erhalten.' : 'This sound effect is recognized by ContentID when used on YouTube. A one-time release on the part of the author is necessary. This is usually done within a few hours. For more information, please refer to the license document, which you will receive upon purchase.'}>
                    <span>{this.props.locale === 'de' ? 'YouTube ContentID' : 'YouTube ContentID'}</span>
                  </span>
                ) :
                ('')
              }
            </li>
            <li className='track__detail-list-item'>
              <a itemProp="url" className="tag" href={this.props.showcaseUrl} target="_blank" title={this.props.title}>
                <span>{this.props.locale === 'de' ? 'Track-Details' : 'track details'}</span>
              </a>
            </li>
            {soundEffectTagItems}
            {/* <li className='track__detail-list-item'>
                <a className="tag" href={this.props.similar_tracks_url} target="_blank" title={this.props.i18n.similar_tracks + ' ' + this.props.title}>
                  <i className="fa fa-share-alt track__similar-link-icon"></i>
                  <span className='track__similar-link-text'>{this.props.i18n.similar_tracks}</span>
                </a>
              </li>
              <li className='track__detail-list-item'>
                <a itemProp="url" className="tag"  href={this.props.showcaseUrl} target="_blank" title={this.props.title}>
                  <i className="fa fa-info-circle"></i>
                  <span className='track__similar-link-text'>{this.props.locale === 'de' ? 'Track-Details' : 'track details'}</span>
                </a>
              </li> */}
          </ul>
        </div>
      </div>
    )
  }
};

SoundEffect.defaultProps = {
  cart_items: [],
  hideAddToCart: false
}

export default SoundEffect;

