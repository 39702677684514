import React, { Component } from 'react';
import classNames from 'classnames/bind';
// // import $ from 'jquery';
import confetti from 'canvas-confetti';
import ahoy from 'ahoy.js';

import UsageLicenseTypeSelect from '~/components/legacy/UsageLicenseTypeSelect.jsx'

class StaticTrack extends Component {

  // mixins : [UsageLicenseTypeSelectMixin],

  constructor(props) {
    super(props)

    this.handleClick = this.handleClick.bind(this)
    this.handleLayoutDownloadClick = this.handleLayoutDownloadClick.bind(this)
    this.handleShareClick = this.handleShareClick.bind(this)
    this.handlefreeDownloadBuyInShopClick = this.handlefreeDownloadBuyInShopClick.bind(this)
    this.handlefreeDownloadAgreeClick = this.handlefreeDownloadAgreeClick.bind(this)
    this.handleNextClick = this.handleNextClick.bind(this)
    this.handlePrevClick = this.handlePrevClick.bind(this)
    this.handleFavoriteClick = this.handleFavoriteClick.bind(this)
    this.handleFreeDownloadClick = this.handleFreeDownloadClick.bind(this)
    this.handleMouseEnter = this.handleMouseEnter.bind(this)
    this.handleMouseLeave = this.handleMouseLeave.bind(this)
    this.handleVariantClick = this.handleVariantClick.bind(this)
    this.handleCartClick = this.handleCartClick.bind(this)
    this.updateCart = this.updateCart.bind(this)

    this.state = {
      playing: false,
      isHovered: false,
      showUsageLicenseSelect: false
    }
  }

  componentDidMount() {
    let that = this;

    global.EventHub.on('updateTrackInfo', function (data) {
      if (data[0].id !== that.props.id) {
        that.setState({
          playing: false
        });
      }
    });

    global.EventHub.on('updateTrackVariantInfo', function (data) {
      if (data[0].id !== that.props.id) {
        that.setState({
          playing: false
        });
      }
    });


    global.EventHub.on('audioPlayerPlay', function (data) {
      if (data[0].id === that.props.id) {
        that.setState({
          playing: true
        });
      }
    });


    global.EventHub.on('audioPlayerStop', function () {
      if (that.state.playing) {
        that.setState({
          playing: false
        });
      }
    });

    global.EventHub.on('audioPlayerEnded', function () {
      if (that.state.playing) {
        that.setState({
          playing: false
        });
      }
    });
  }

  handleClick(e) {
    e.preventDefault();
    global.EventHub.trigger('playTrack', this.props.id);
    global.EventHub.trigger('updateTrackInfo', this.props);
    this.setState({
      playing: !this.state.playing
    });
  }

  handleLayoutDownloadClick(e) {
    this.triggerDemoDownload();

    if (typeof confetti !== 'undefined') {
      let rect = e.target.getBoundingClientRect();
      console.log(rect.top, rect.right, rect.bottom, rect.left);

      confetti({
        particleCount: 200,
        startVelocity: 20,
        angle: 90,
        spread: 60,
        origin: {
          x: (rect.left + 10) / window.innerWidth,
          // since they fall down, start a bit higher than random
          y: (rect.top + 30) / window.innerHeight
        },
        colors: ['#e97900', '#d77812', '#ffd6aa', '#43ce00', '#63de00', '#a4d08f', '#52489C', '#635b9a', '#baafff']
      });
    }
  }

  handleShareClick(e) {
    e.preventDefault();

    if (this.state.displayCopyHint) {
      this.setState({ displayCopyHint: false });
    } else {
      if (!this.state.shareTrackingFired) {
        if (typeof plausible === 'function') {
          plausible('track_share', { props: { track_id: this.props.id, location: window.location.href } });
        }

        ahoy.track('track_share', { track_id: this.props.id, location: window.location.href, categorization_processed: false });
      }

      if (navigator.share) {
        // Web Share API is supported
        navigator.share({
          title: (this.props.display_title || this.props.title) + ' | ' + (this.props.locale === 'de' ? 'GEMAfreie Musik auf audiocrowd.net' : 'Royalty-free music on audiocrowd.net'),
          url: this.props.showcaseUrl + '?utm_source=share_link_teaser&utm_medium=referral'
        })
        this.setState({ shareTrackingFired: true });
      } else {
        // Fallback
        this.copyToClipboard(this.props.showcaseUrl + '?utm_source=share_link_teaser&utm_medium=referral');
        this.setState({ displayCopyHint: true, shareTrackingFired: true }, function () {
          setTimeout(function () {
            this.setState({ displayCopyHint: false })
          }.bind(this), 5000)
        });
      }

      if (typeof confetti !== 'undefined') {
        let rect = e.target.getBoundingClientRect();
        console.log(rect.top, rect.right, rect.bottom, rect.left);

        confetti({
          particleCount: 200,
          startVelocity: 30,
          angle: 90,
          spread: 60,
          shapes: ['circle'],
          origin: {
            x: (rect.left + 10) / window.innerWidth,
            // since they fall down, start a bit higher than random
            y: (rect.top + 20) / window.innerHeight
          },
          colors: ['#e97900', '#d77812', '#ffd6aa', '#43ce00', '#63de00', '#a4d08f', '#52489C', '#635b9a', '#baafff']
        });
      }
    }
  }

  copyToClipboard(str) {
    let el = document.createElement('textarea');

    el.value = str;
    el.setAttribute('readonly', '');
    el.style.position = 'absolute';
    el.style.left = '-9999px';
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
  }


  triggerDemoDownload() {
    let link = document.createElement("a"),
      uri = '/c/tracks/' + this.props.id + '/download_layout',
      isChrome = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor),
      isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;

    // If you don't know the name or want to use
    // the webserver default set name = ''

    link.href = uri

    if ($('[data-logged-in]').length === 0) {
      // not logged in
      link.setAttribute('target', '_blank');
    } else {
      // logged in
      if (!isChrome && !isFirefox) {
        link.setAttribute('download', '');
      }
    }

    if (typeof plausible === 'function') {
      plausible('track_layout_download', { props: { track_id: this.props.id, location: window.location.href } });
    }

    ahoy.track('track_layout_download', { track_id: this.props.id, location: window.location.href, categorization_processed: false })

    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  handlefreeDownloadBuyInShopClick(e) {
    e.preventDefault();
    let win = window.open(this.props.showcaseUrl, '_blank');
    // let win = window.open((this.props.locale === 'de' ? ('http://localhost:3000/tracks/' + this.props.id + '/free_download') : ('http://localhost:3000/tracks/' + this.props.id + '/free_download')), '_blank');
    win.focus();

    console.log('handle buy in shop click');
  }

  handlefreeDownloadAgreeClick(e) {
    e.preventDefault();

    if (typeof plausible === 'function') {
      plausible('track_download_free', { props: { track_id: this.props.id, location: window.location.href } });
    }

    ahoy.track('track_download_free', { track_id: this.props.id, location: window.location.href, categorization_processed: false })

    let win = window.open((this.props.locale === 'de' ? ('/tracks/' + this.props.id + '/free_download') : ('/tracks/' + this.props.id + '/free_download')), '_blank');
    // let win = window.open((this.props.locale === 'de' ? ('http://localhost:3000/tracks/' + this.props.id + '/free_download') : ('http://localhost:3000/tracks/' + this.props.id + '/free_download')), '_blank');
    win.focus();
  }

  handlefreeDownloadRejectClick(e) {
    e.preventDefault();
    console.log('handle reject click');
  }

  handleNextClick(e) {
    e.preventDefault();
    global.EventHub.trigger('playTrack', this.props.next_track.id);
    global.EventHub.trigger('updateTrackInfo', this.props.next_track);
    this.setState({
      playing: !this.state.playing
    });
  }

  handlePrevClick(e) {
    e.preventDefault();
    global.EventHub.trigger('playTrack', this.props.prev_track.id);
    global.EventHub.trigger('updateTrackInfo', this.props.prev_track);
    this.setState({
      playing: !this.state.playing
    });
  }

  handleFavoriteClick(e) {
    e.preventDefault();

    this.props.handleFavoriteClick(this.props.id);

    if (!this.props.favorited && typeof confetti !== 'undefined') {
      let rect = e.target.getBoundingClientRect();
      console.log(rect.top, rect.right, rect.bottom, rect.left);

      confetti({
        particleCount: 200,
        startVelocity: 30,
        angle: 90,
        spread: 60,
        shapes: ['circle'],
        origin: {
          x: (rect.left + 10) / window.innerWidth,
          // since they fall down, start a bit higher than random
          y: (rect.top + 20) / window.innerHeight
        },
        colors: ['#e97900', '#d77812', '#ffd6aa', '#43ce00', '#63de00', '#a4d08f', '#52489C', '#635b9a', '#baafff']
      });
    }
  }

  handleFreeDownloadClick(e) {
    e.preventDefault();
    this.props.handleFreeDownloadClick(this.props.id);
  }

  handleFreeDownloadDialogClick(e) {
    e.preventDefault();
  }

  handlefreeDownloadRejectClick(e) {
    e.preventDefault();
    global.EventHub.trigger('closePopupMenus');
  }


  handleTagClick(tagId, e) {
    e.preventDefault();
    global.EventHub.trigger('trackTagClick', tagId);
  }

  handleGenreClick(genreId, e) {
    e.preventDefault();
    global.EventHub.trigger('trackGenreClick', genreId);
  }

  handleMoodClick(moodId, e) {
    e.preventDefault();
    global.EventHub.trigger('trackMoodClick', moodId);
  }

  handleMouseEnter(e) {
    e.preventDefault();
    this.setState({
      isHovered: true
    });
  }

  handleMouseLeave(e) {
    e.preventDefault();
    this.setState({
      isHovered: false
    });
  }

  handleVariantClick(variantId, trackId, variantTitle, e) {
    e.preventDefault();
    global.EventHub.trigger('playTrackVariant', variantId, trackId);
    this.setState({
      playing: false
    });
  }


  truncate(text, count) {
    return (text.length > count) ? text.substr(0, count - 1) + '...' : text;
  }


  updateCart(usageLicenseTypeId, exposurePackId, forFx) {
    let itemId = this.props.id || this.state.data.id,
      i,
      updateUrl;

    if (exposurePackId) {
      updateUrl = "/cart?exposure_pack_id=" + exposurePackId + "&usage_license_type_id=" + usageLicenseTypeId;
    } else if (forFx) {
      updateUrl = "/cart?sound_effect_id=" + itemId + "&usage_license_type_id=" + usageLicenseTypeId;
    } else {
      updateUrl = "/cart?track_id=" + itemId + "&usage_license_type_id=" + usageLicenseTypeId;
    }

    $.ajax({
      url: updateUrl,
      type: 'POST'
    }).done(function (data) {

      global.EventHub.trigger('updateCart', data)

      for (i = 0; i < data.length; i += 1) {
        if (data[i].track_id === itemId) {
          if (typeof plausible === 'function') {
            plausible('track_add_cart', { props: { track_id: itemId, usage_license_type_id: usageLicenseTypeId, location: window.location.href } });
          }

          ahoy.track('track_add_cart', { track_id: itemId, usage_license_type_id: usageLicenseTypeId, location: window.location.href, categorization_processed: false })
        } else if (data[i].sound_effect_id === itemId) {
          if (typeof plausible === 'function') {
            plausible('sound_effect_add_cart', { props: { sound_effect_id: itemId, usage_license_type_id: usageLicenseTypeId, location: window.location.href } });
          }

          ahoy.track('sound_effect_add_cart', { sound_effect_id: itemId, usage_license_type_id: usageLicenseTypeId, location: window.location.href, categorization_processed: false })
        }
      }

      if (typeof fbq === 'function') {
        fbq('track', 'AddToWishlist', {
          content_ids: itemId.toString(),
          content_type: 'product',
        });
      }

    }.bind(this));
  }

  handleCartClick(e) {
    e.preventDefault();
    this.props.handleCartClick(this.props.id);
  }

  getSelectedUsageLicenseTypeIds() {
    let ids = [],
      that = this,
      trackId = this.props.id || that.state.data.id,
      cart_items = this.state.cart_items || this.props.cart_items;

    cart_items.forEach(function (element) {
      if (element.track_id === trackId) {
        ids.push(element.usage_license_type_id);
      }
    });

    return ids;
  }



  render() {
    let classes = classNames({
      'static-track': true,
      'static-track--slim': this.props.slim,
      'static-track--active': this.props.active,
      'static-track--new': this.props.recently_activated,
      'static-track--favorited': this.props.favorited,
      'static-track--license-select-open': this.props.showUsageLicenseSelect,
      'static-track--playing': this.state.playing && !(parseInt(this.props.activeTrackVariantId, 10) > 0),
      'static-track--variant-playing': this.state.playing && (parseInt(this.props.activeTrackVariantId, 10) > 0)
    }),
      cartLink,
      favoriteLink,
      recentlyCreatedLabel,
      cartLinkIcon,
      usageLicenseTypeSelect,
      trackLabels,
      nextButton,
      prevButton,
      composerInfo,
      genreItems = [],
      tagItems = [],
      moodItems = [];

    let layoutDownloadLink,
      shareLink,
      freeDownloadDialog,
      copyHint;

    if (this.state.displayCopyHint && !navigator.share) {
      copyHint = <div className="static-track__copy-hint">
        <div className="static-track__copy-hint-content">
          <i className='static-track__copy-hint-icon fa fa-check'></i>
          <span className='static-track__copy-hint-text'>{this.props.locale === 'de' ? 'Die Track-URL wurde in die Zwischenablage kopiert.' : 'The track URL was copied to your clipboard.'}</span>
        </div>
      </div>;
    }


    layoutDownloadLink = <a className="static-track__layout-download" onClick={this.handleLayoutDownloadClick} title="Layout-Download">
      <div className='tooltip'>
        <i className='fa fa-download'></i>
        <span className='static-track__download-link-label'>Demo</span>
        {/* <div className='tooltip__content'>{this.props.locale === 'de' ? 'DEMO-Download: Wenn Sie die Musik erst ausprobieren möchten, können Sie eine Demo-Version herunterladen. Der Download erfordert ein Kunden-Konto, das Sie nach einem Klick einfach und schnell anlegen können.' : 'Demo download: If you want to try the music first, you can download a demo version. The download requires a customer account, which you can create easily after one click.'}</div> */}
      </div>

      {/* <i className="fa fa-download"></i><span className="static-track__layout-download-label">{this.props.layout_download}</span> */}
    </a>;

    shareLink = (
      <a className="static-track__share" onClick={this.handleShareClick} title="Share this track">
        <div className='tooltip'>
          <i className='fa fa-share-alt'></i>
          <span className='static-track__share-link-label'>{this.props.locale === 'de' ? 'Teilen' : 'share'}</span>
          {/* <div className='tooltip__content'>{this.props.locale === 'de' ? 'DEMO-Download: Wenn Sie die Musik erst ausprobieren möchten, können Sie eine Demo-Version herunterladen. Der Download erfordert ein Kunden-Konto, das Sie nach einem Klick einfach und schnell anlegen können.' : 'Demo download: If you want to try the music first, you can download a demo version. The download requires a customer account, which you can create easily after one click.'}</div> */}
        </div>

        {/* <i className="fa fa-download"></i><span className="static-track__layout-download-label">{this.props.layout_download}</span> */}
      </a>);

    let trackVariants = this.props.track_variants.map(function (variant) {
      return (<a key={'track_variant_' + variant.id} className={(parseInt(this.props.activeTrackVariantId, 10) === variant.id) ? 'track__variant-wrapper track__variant-wrapper--active' : 'track__variant-wrapper'} onClick={this.handleVariantClick.bind(this, variant.id, variant.track_id, variant.title)}><span className='static-track__variant-icon'><i className={(this.state.playing && (parseInt(this.props.activeTrackVariantId, 10) === variant.id)) ? 'fa fa-pause' : 'fa fa-play'}></i></span><span className='static-track__variant-title'>{variant.title}</span></a>)
    }.bind(this));

    let trackVariantInfo;

    if (this.props.track_variants.length > 0) {
      trackVariantInfo = <span className='static-track__variant-info'>{this.props.locale === 'de' ? (' Enthält ' + this.props.track_variants.length + ' zusätzliche ' + (this.props.track_variants.length === 1 ? 'Variante.' : 'Varianten.')) : (' Including ' + this.props.track_variants.length + ' additional ' + (this.props.track_variants.length === 1 ? 'variant.' : 'variants.'))}</span>;
    }

    if (this.props.prev_track) {
      prevButton = <span onClick={this.handlePrevClick} className='static-track__prev-link'><i className="fa fa-step-backward"></i></span>;
    } else {
      prevButton = <span className='static-track__prev-link track__prev-link--inactive'><i className="fa fa-step-backward"></i></span>;
    }

    if (this.props.next_track) {
      nextButton = <span onClick={this.handleNextClick} className='static-track__next-link'><i className="fa fa-step-forward"></i></span>;
    } else {
      nextButton = <span className='static-track__next-link track__next-link--inactive'><i className="fa fa-step-forward"></i></span>;
    }

    if (this.props.favorited) {
      favoriteLink = <a href='#' onClick={this.handleFavoriteClick} className='static-track__favorite-link'><div className="tooltip"><i className="fa fa-heart"></i><span className='static-track__favorite-label'>{this.props.locale === 'de' ? 'gemerkt' : 'remembered'}</span></div></a>;
    } else {
      favoriteLink = <a href='#' onClick={this.handleFavoriteClick} className='static-track__favorite-link'><div className="tooltip"><i className="fa fa-heart-o"></i><span className='static-track__favorite-label'>{this.props.locale === 'de' ? 'merken' : 'remember'}</span></div></a>;
    }

    if (this.props.recently_activated) {
      recentlyCreatedLabel = <span className='static-track__label track__label--new'>{this.props.i18n.recently_created}</span>
      trackLabels = <span className="static-track__labels">{recentlyCreatedLabel}</span>;
    }

    if (this.props.composerName) {
      composerInfo = <span className="static-track__composer">by <a itemProp="author" className="static-track__composer-name" href={this.props.composerUrl}>{this.props.composerName}</a></span>;
    } else {
      composerInfo = '';
    }

    if (!this.props.freeMode) {
      if (this.props.inCart) {
        if (this.props.show_download_button) {
          cartLinkIcon = <span className='static-track__cart-link-icon'><i className="fa fa-download"></i></span>;
        } else {
          cartLinkIcon = <span className='static-track__cart-link-icon'><i className="static-track__cart-link-icon-added fa fa-check"></i><i className="fa fa-shopping-cart track__cart-link-icon-cart"></i></span>;
        }
        cartLink = <a className="static-track__action-button-added-to-cart" href="#" onClick={this.handleCartClick}>{cartLinkIcon}{this.props.remove_from_cart}</a>
      } else {
        if (this.props.show_download_button) {
          cartLinkIcon = <span className='static-track__cart-link-icon'><i className="fa fa-download"></i></span>;
        } else {
          cartLinkIcon = <span className='static-track__cart-link-icon'><i className="static-track__cart-link-icon-add fa fa-plus"></i><i className="fa fa-shopping-cart track__cart-link-icon-cart"></i></span>;
        }
        cartLink = <a className="static-track__action-button-add-to-cart" href="#" onClick={this.handleCartClick}>{cartLinkIcon}{this.props.add_to_cart}</a>
      }
    }

    if (this.props.showUsageLicenseSelect && !this.props.freeMode) {
      usageLicenseTypeSelect = (
        <UsageLicenseTypeSelect
          locale={this.props.locale}
          addToCart={this.props.i18n.add_to_cart}
          discount={this.props.discount}
          usageLicenseTypes={this.props.usageLicenseTypes}
          subscribedUsageLicenseTypes={this.props.subscribedUsageLicenseTypes}
          updateCart={this.updateCart}
          pricing_path={this.props.pricing_path}
          selectedUsageLicenseTypeIds={this.getSelectedUsageLicenseTypeIds()}
          i18n={this.props.i18n}
          visible={this.props.showUsageLicenseSelect}
          position='overlay'
          show_download_button={this.props.show_download_button}
          handleCartClick={this.handleCartClick}
          trackTitle={this.props.display_title || this.props.title} />
      );
    }


    return (
      <div className={classes} id={'track-static-' + this.props.id} onMouseEnter={this.handleMouseEnter} onMouseLeave={this.handleMouseLeave} itemScope itemType='https://schema.org/CreativeWork'>


        <div className='static-track__content'>
          <div className='static-track__play-wrapper'>
            {prevButton}
            <div className='static-track__play-icon' onClick={this.handleClick}>
              <i className={(this.state.playing && !(parseInt(this.props.activeTrackVariantId, 10) > 0)) ? 'fa fa-pause' : 'fa fa-play'} />
              <div className='static-track__image' dangerouslySetInnerHTML={{ __html: this.props.track_image_markup }}></div>
            </div>
            {nextButton}
          </div>
          <div className='static-track__content-items'>
            <span itemProp="copyrightHolder" itemType="https://schema.org/Organization" itemScope="" className="hide-element"><span itemProp="name">audiocrowd GmbH</span></span>
            <span itemProp="publisher" itemType="https://schema.org/Organization" itemScope="" className="hide-element"><span itemProp="name">audiocrowd GmbH</span></span>
            <span itemProp="provider" itemType="https://schema.org/Organization" itemScope="" className="hide-element"><span itemProp="name">audiocrowd GmbH</span></span>
            <meta itemProp="isFamilyFriendly" content="true" />
            <meta itemProp="copyrightYear" content={this.props.copyrightYear}></meta>
            <div className="static-track__title-wrapper">
              <span className="static-track__title" onClick={this.handleClick}>
                <span className="static-track__id">{this.props.id}</span>
                <span itemProp="name" className="static-track__title-value">{this.props.display_title || this.props.title}</span>
                <span>{trackLabels}</span>
                {/* <br />
                  <span>{this.props.score}</span> */}
              </span>
              {composerInfo}
            </div>
            <div className='static-track__actions'>
              {favoriteLink}
              {shareLink}
              {copyHint}
              {layoutDownloadLink}
              <div className={this.props.free && this.props.freeMode ? 'hide-element' : 'track__cart-link'}>
                {cartLink}
              </div>
              {usageLicenseTypeSelect}
            </div>
          </div>
        </div>
      </div>
    )
  }
};

StaticTrack.defaultProps = {
  cart_items: [],
  locale: 'de'
}

export default StaticTrack;

