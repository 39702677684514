import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import classNames from 'classnames/bind';
// import $ from 'jquery';

import SearchTabs from '~/components/legacy/SearchTabs.jsx';
// import SearchTab from '~/components/legacy/SearchTab.jsx';
// import SearchTabContent from '~/components/legacy/SearchTabContent.jsx';
// import SearchTabFilterContent from '~/components/legacy/SearchTabFilterContent.jsx';
import SearchMenuLengthInputs from '~/components/legacy/SearchMenuLengthInputs.jsx';
import SearchMenuQueryInput from '~/components/legacy/SearchMenuQueryInput.jsx';

class SoundEffectSearch extends Component {

  // mixins: [SoundEffectSearchMenuMixin],

  constructor(props) {
    super(props);

    this.handleSubmitSearchClick = this.handleSubmitSearchClick.bind(this);
    this.submitQueryClick = this.submitQueryClick.bind(this);
    this.submitMenuItemClick = this.submitMenuItemClick.bind(this);
    this.submitQueryChange = this.submitQueryChange.bind(this);
    this.submitMinLengthClick = this.submitMinLengthClick.bind(this);
    this.submitMaxLengthClick = this.submitMaxLengthClick.bind(this);
    this.submitLengthChange = this.submitLengthChange.bind(this);
    this.submitSortChange = this.submitSortChange.bind(this);

    this.state = {
      query: '',
      page: this.props.initialSearchQuery.page ? parseInt(this.props.initialSearchQuery.page, 10) : 1,
      composers: [],
      soundEffectTags: [],
      completedQuery: '',
      minLength: '',
      maxLength: '',
      free: false,
      sortDirection: '',
      sortBy: '',
      soundEffectId: this.props.initialSearchQuery.sound_effect_id
    }
  }

  componentWillMount() {
    var stateObject = {},
      searchCloudItemIds = [];

    for (var searchCategory in this.props.initialSearchQuery) {
      if (this.props.initialSearchQuery.hasOwnProperty(searchCategory)) {

        if (searchCategory === 'query' && this.props.initialSearchQuery[searchCategory].length > 0) {
          this.setState({ query: this.props.initialSearchQuery[searchCategory] });
        } else if (searchCategory === 'minLength' && this.props.initialSearchQuery[searchCategory] >= 0) {
          this.setState({ minLength: this.props.initialSearchQuery[searchCategory] });
        } else if (searchCategory === 'maxLength' && parseInt(this.props.initialSearchQuery[searchCategory]) >= 0) {
          this.setState({ maxLength: this.props.initialSearchQuery[searchCategory] });
        } else if (searchCategory === 'soundEffectId') {
          this.setState({ soundEffectId: this.props.initialSearchQuery[searchCategory] });
        } else if (searchCategory === 'page') {
          if (this.props.initialSearchQuery[searchCategory]) {
            this.setState({ page: parseInt(this.props.initialSearchQuery[searchCategory], 10) });
          }
        } else {
          stateObject = {};
          searchCloudItemIds = [];

          for (var i = 0; i < this.props.initialSearchQuery[searchCategory].length; i += 1) {
            searchCloudItemIds.push(this.props.initialSearchQuery[searchCategory][i]);
          }

          stateObject[searchCategory] = searchCloudItemIds;
          this.setState(stateObject);
        }
      }
    }
  }

  componentDidMount() {

    this.setStateViaUrlParams();
    global.EventHub.on('queryInputSubmit', function () {
      this.sendSearchQuery();
    }.bind(this));

    global.EventHub.on('paginationClick', this.sendPaginationSearchQuery);

    global.EventHub.on('changeSort', function (payload) {
      if (payload[0]) {
        this.setState({ sortDirection: payload[0].sortDirection, sortBy: payload[0].sortBy }, function () {
          this.sendSearchQuery();
        });
      }
    }.bind(this));

    global.EventHub.on('soundEffectTagClick', function (payload) {
      if (payload[0]) {
        if (this.state.soundEffectTags.indexOf(payload[0]) > -1) {
          var index = this.state.soundEffectTags.indexOf(payload[0]);
          var soundEffectTags = this.state.soundEffectTags;

          soundEffectTags.splice(index, 1)

          this.setState({ soundEffectTags: soundEffectTags }, function () {
            this.sendSearchQuery();
          });

        } else {
          this.setState({ soundEffectTags: [payload[0]] }, function () {
            this.sendSearchQuery();
          });
        }

      }
    }.bind(this));

    global.EventHub.on('requestUpdateSoundEffectListing', function () {
      this.sendSearchQuery(true, true);
    }.bind(this));
  }

  sendPaginationSearchQuery(pageNumber) {
    this.setState({ page: pageNumber }, function () {
      this.sendSearchQuery(true);
    });
  }

  setStateViaUrlParams() {
    this.setQueryFromUrl();
    this.setIdsFromUrlFor('sound_effect_tag');
    this.setLengthFromUrl();
    this.setSortFromUrl();
  }

  setQueryFromUrl() {
    var regex = /\q\=([\w\+\d\%,-]*)/g,
      matches = regex.exec(document.location.search),
      matchesLength,
      queryText;

    if (matches !== null) {
      matchesLength = matches.length;
      for (var i = 0; i < matchesLength; i += 1) {
        queryText = matches[i].replace(/\+/g, ' ');
      }
      this.setState({ query: decodeURI(queryText), completedQuery: decodeURI(queryText) });
    }
  }

  setIdsFromUrlFor(paramString) {
    var pattern = '\_id\\[\\]\\=(\\d)+',
      regex = new RegExp(paramString + pattern, 'gi'),
      matches = document.location.search.match(regex),
      ids = [],
      stateObject = {},
      matchesLength,
      matchId,
      i;

    if (matches !== null) {
      matchesLength = matches.length;
      for (i = 0; i < matchesLength; i += 1) {
        matchId = parseInt(matches[i].replace(paramString + '_id[]=', ''), 10);
        ids.push(matchId);
      }
      stateObject[paramString + 's'] = ids;
      this.setState(stateObject, function () {
        setTimeout(function () {
          global.EventHub.trigger('updateItemIdList', { soundEffectTags: this.state.soundEffectTags });
        }.bind(this), 50)
      }.bind(this));
    }
  }

  setLengthFromUrl() {
    var minRegex = /min_length\=([\d]*)/g,
      minMatches = minRegex.exec(document.location.search),
      minMatchesLength,
      minQueryText,

      maxRegex = /max_length\=([\d]*)/g,
      maxMatches = maxRegex.exec(document.location.search),
      maxMatchesLength,
      maxQueryText;

    if (minMatches !== null) {
      minMatchesLength = minMatches.length;
      for (var i = 0; i < minMatchesLength; i += 1) {
        minQueryText = minMatches[i];
      }
    }

    if (maxMatches !== null) {
      maxMatchesLength = maxMatches.length;
      for (var i = 0; i < maxMatchesLength; i += 1) {
        maxQueryText = maxMatches[i];
      }
    }

    if (minQueryText) {
      this.setState({
        minLength: parseInt(minQueryText, 10)
      });
    }

    if (maxQueryText) {
      this.setState({
        maxLength: parseInt(maxQueryText, 10)
      });
    }
  }

  setSortFromUrl() {
    var directionRegex = /sort_direction\=([a-zA-Z]+)/g,
      directionMatches = directionRegex.exec(document.location.search),
      directionMatchesLength,
      directionQueryText;

    if (directionMatches !== null) {
      directionMatchesLength = directionMatches.length;
      for (var i = 0; i < directionMatchesLength; i += 1) {
        directionQueryText = directionMatches[i];
      }
    }

    var sortByRegex = /sort_by\=([a-zA-Z]+)/g,
      sortByMatches = sortByRegex.exec(document.location.search),
      sortByMatchesLength,
      sortByQueryText;

    if (sortByMatches !== null) {
      sortByMatchesLength = sortByMatches.length;
      for (var i = 0; i < sortByMatchesLength; i += 1) {
        sortByQueryText = sortByMatches[i];
      }
    }


    if (sortByQueryText && directionQueryText) {
      this.setState({
        sortDirection: directionQueryText,
        sortBy: sortByQueryText
      });
    } else if (directionQueryText) {
      this.setState({
        sortDirection: directionQueryText
      });
    }
  }

  handleSubmitSearchClick() {
    this.sendSearchQuery();
  }

  submitQueryClick(query) {
    var stateObject = this.state;

    stateObject.query = '';
    stateObject.completedQuery = '';

    this.setState(stateObject);
    this.sendSearchQuery();
  }

  submitMenuItemClick(name, id) {
    var stateObject = this.state,
      arrayLength = stateObject[name].length,
      i,
      removeIndex,
      shouldAddToArray = true;

    for (i = 0; i < arrayLength; i += 1) {
      if (stateObject[name][i] === id) {
        shouldAddToArray = false;
      }
    }

    if (shouldAddToArray) {
      if (name === 'moods' || name === 'instruments') {
        stateObject[name].push(id);
      } else {
        stateObject[name] = [id];
      }
    } else {
      removeIndex = stateObject[name].indexOf(id);
      if (removeIndex > -1) {
        stateObject[name].splice(removeIndex, 1);
      }
    }

    this.setState(stateObject);
    this.sendSearchQuery();
  }

  submitQueryChange(fieldValue) {
    this.setState({ query: fieldValue });
  }

  submitMinLengthClick() {
    var stateObject = this.state;

    stateObject.minLength = '';

    this.setState(stateObject);
    this.sendSearchQuery();
  }

  submitMaxLengthClick() {
    var stateObject = this.state;

    stateObject.maxLength = '';

    this.setState(stateObject);
    this.sendSearchQuery();
  }

  submitLengthChange(minLength, maxLength) {
    if (minLength > 0) {
      this.setState({ minLength: minLength });
    } else {
      this.setState({ minLength: '' });
    }
    if (maxLength > 0) {
      this.setState({ maxLength: maxLength });
    } else {
      this.setState({ maxLength: '' });
    }
  }

  submitSortChange(sortBy, sortDirection) {
    var stateObject = {
      sortBy: '',
      sortDirection: ''
    };

    if (sortBy === 'date') {
      stateObject['sortBy'] = sortBy;
    } else if (sortBy === 'popularity') {
      stateObject['sortBy'] = sortBy;
    } else if (sortBy === 'length') {
      stateObject['sortBy'] = sortBy;
    } else {
      stateObject['sortBy'] = '';
    }

    if (sortDirection === 'asc') {
      stateObject['sortDirection'] = sortDirection;
    } else if (sortDirection === 'desc') {
      stateObject['sortDirection'] = sortDirection;
    } else {
      stateObject['sortDirection'] = '';
    }

    this.setState(stateObject, function () {
      this.sendSearchQuery();
    });
  }

  getActiveSearchCriteriaCount() {
    var criteriaCount = 0;

    if (this.props.hideCategoryFromUrl !== 'sound_effect_tag' && this.state.soundEffectTags.length > 0) {
      criteriaCount += this.state.soundEffectTags.length;
    }

    if (this.state.minLength > 0) {
      criteriaCount += 1;
    }

    if (this.state.maxLength > 0) {
      criteriaCount += 1;
    }

    if (this.state.query.length > 0) {
      criteriaCount += 1;
    }

    return criteriaCount;
  }

  getActiveSearchCriteriaCountWithHiddenCriterias() {
    var criteriaCount = 0;

    if (this.state.soundEffectTags.length > 0) {
      criteriaCount += this.state.soundEffectTags.length;
    }

    if (this.state.minLength > 0) {
      criteriaCount += 1;
    }

    if (this.state.maxLength > 0) {
      criteriaCount += 1;
    }

    if (this.state.query.length > 0) {
      criteriaCount += 1;
    }

    return criteriaCount;
  }

  buildSearchQueryParams(omitPageParam) {
    var searchQueryParamsString = '',
      textQuery = '';

    for (var searchCategory in this.state) {
      if (this.state.hasOwnProperty(searchCategory)) {

        if (searchCategory === 'query' && this.state[searchCategory].length > 0) {
          textQuery = 'q=' + this.state[searchCategory] + '&';
          searchQueryParamsString += textQuery;
        } else if (searchCategory === 'minLength' && this.state[searchCategory] > 0) {
          searchQueryParamsString += 'min_length=' + this.state[searchCategory] + '&';
        } else if (searchCategory === 'maxLength' && this.state[searchCategory] > 0) {
          searchQueryParamsString += 'max_length=' + this.state[searchCategory] + '&';
        } else if (searchCategory === 'soundEffectId') {
          if (parseInt(this.state[searchCategory], 10) > 0) {
            searchQueryParamsString += 'sound_effect_id=' + this.state[searchCategory] + '&';
          }
        } else if (searchCategory === 'page') {
          if (omitPageParam) {
            searchQueryParamsString += 'page=' + this.state[searchCategory] + '&';
          }
        } else if (searchCategory === 'sortBy' && typeof this.state[searchCategory] === 'string') {
          searchQueryParamsString += 'sort_by=' + this.state[searchCategory] + '&';
        } else if (searchCategory === 'sortDirection' && typeof this.state[searchCategory] === 'string') {
          searchQueryParamsString += 'sort_direction=' + this.state[searchCategory] + '&';
        } else if (searchCategory === 'free' && this.state[searchCategory] === true) {
          searchQueryParamsString += 'free=' + this.state[searchCategory] + '&';
        } else if (searchCategory === 'completedQuery') {

        } else {
          for (var i = 0; i < this.state[searchCategory].length; i += 1) {
            var elementValue = this.state[searchCategory][i],
              singularCategory = searchCategory.slice(0, -1),
              text = '';

            // if (this.props.hideCategoryFromUrl !== singularCategory) {
            //     text = singularCategory + '_id[]=' + elementValue + '&';
            // }

            text = singularCategory + '_id[]=' + elementValue + '&';

            searchQueryParamsString += text;
          }
        }
      }
    }


    if (searchQueryParamsString.endsWith('&')) {
      searchQueryParamsString = searchQueryParamsString.slice(0, -1);
    }

    if (searchQueryParamsString === 'sort_direction=&sort_by=') {
      searchQueryParamsString = '';
    }

    return searchQueryParamsString;
  }

  sendSearchQuery(paginationMode, initialPageLoadQuery) {
    let that = this,
      searchQueryParams, // search object
      newUrl,
      urlParams;

    if (!paginationMode) {
      this.setState({ page: 1 });
    }

    searchQueryParams = this.buildSearchQueryParams(paginationMode); // search object
    urlParams = (searchQueryParams.length > 0 ? ('?' + searchQueryParams) : '');
    
    $.ajax({
      url: '/sound_effects/search_results' + urlParams,
      beforeSend: function () {
        if (initialPageLoadQuery) {
          global.EventHub.trigger('fireSearchWithoutAnimation');
        } else {
          global.EventHub.trigger('fireSearch');
        }
      },
      success: function(data) {
        if (initialPageLoadQuery) {
          global.EventHub.trigger('initialUpdateSoundEffectListing', data);
        } else {
          global.EventHub.trigger('updateSoundEffectListing', data);
        }

        global.EventHub.trigger('searchSuccess', data);
        global.EventHub.trigger('updateItemIdList', { soundEffectTags: that.state.soundEffectTags });
        if (history.pushState && !initialPageLoadQuery) {
          if (paginationMode) {
            var prevParams = location.search.replace(/\?page=[\d]+/, '').replace(/&page=[\d]+/, '').replace(/[\?]+/, '');
            var path = window.location.pathname + '?' + prevParams + (prevParams.length > 0 ? '&' : '') + 'page=' + (this.state.page);
            if (path === '?') {
              path = '';
            }
            newUrl = window.location.protocol + "//" + window.location.host + path;
            newUrl = newUrl.replace(/\?page=1$/, '').replace(/&page=1$/, '');
          } else {
            var path = (searchQueryParams.length > 0 ? ('?' + searchQueryParams) : '').replace('?sort_direction=&sort_by=', '?').replace('&sort_direction=&sort_by=', '').replace('&sort_direction=desc&sort_by=popularity', '').replace('?sort_direction=desc&sort_by=popularity', '?');

            if (path === '?') {
              path = ''
            }

            newUrl = window.location.protocol + "//" + window.location.host + window.location.pathname + path;
            newUrl = newUrl.replace(/&page=[\d]+/, '');
          }

          if (this.props.hideCategoryFromUrl.length > 0) {
            if (this.props.hideCategoryFromUrl === 'tag') {
              newUrl = newUrl.replace(/\?sound_effect_tag_id\[\]=[\d]+&/, '?');
              newUrl = newUrl.replace(/&sound_effect_tag_id\[\]=[\d]+&/, '&');
              newUrl = newUrl.replace(/&sound_effect_tag_id\[\]=[\d]+/, '');
              newUrl = newUrl.replace(/\?sound_effect_tag_id\[\]=[\d]+/, '');
            }

            if (this.props.hideCategoryFromUrl === 'composer') {
              newUrl = newUrl.replace(/\?composer_id\[\]=[\d]+&/, '?');
              newUrl = newUrl.replace(/&composer_id\[\]=[\d]+&/, '&');
              newUrl = newUrl.replace(/&composer_id\[\]=[\d]+/, '');
              newUrl = newUrl.replace(/\?composer_id\[\]=[\d]+/, '');
            }

          }

          window.history.pushState({ path: newUrl }, '', newUrl);

          if (typeof ga === 'function') {
            ga('send', 'pageview', path);
          }

          this.setState({ completedQuery: this.state.query });
        }
      }
    });
  }


  render() {
    let classes = classNames({
      'search': true
    });

    return (
      <div className={classes} ref='search'>
        <div className='search__inputs'>
          <div className='row'>
            <div className='col12'>
              <div className="search__bar-wrapper">
                <SearchTabs activeTab={0}>
                  <SearchMenuQueryInput query={this.state.query} submitQueryChange={this.submitQueryChange} placeholder={this.props.i18n.placeholder} autocompletion={this.props.autocompletion} />
                  <SearchMenuLengthInputs minLength={this.state.minLength} maxLength={this.state.maxLength} submitLengthChange={this.submitLengthChange} />
                </SearchTabs>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
};

SoundEffectSearch.defaultProps = {
  composers: [
    {
      name: '',
      id: ''
    }
  ],
  sortDirection: 'desc',
  sortBy: 'popularity',
  initialSearchQuery: {
    // query : '',
    // free : false,
    // soundEffectTags  : [],
    // page : 1,
    // composers : [],
    // soundEffectId : undefined

  },
  autocompletion: [],
  i18n: {
    placeholder: 'z.B. Atmen'
  },
  hideCategoryFromUrl: ''
}



// INIT

let elements = document.querySelectorAll('[data-react-component=SoundEffectSearch]');

elements.forEach(function (element) {
  let reactProps = JSON.parse(element.dataset.reactProps);

  console.log('reactProps');
  console.log(reactProps);

  ReactDOM.render(
    <React.StrictMode>
      <SoundEffectSearch {...reactProps} />
    </React.StrictMode>,
    element
  );
})


export default SoundEffectSearch;
