import React, { Component } from 'react';
import classNames from 'classnames/bind';

class UsageLicenseTypeSelectItemSidebar extends Component {

  constructor(props) {
    super(props);

    this.handleClick = this.handleClick.bind(this);

    this.state = {
      hover: false
    }
  }

  handleClick(e) {
    e.preventDefault();

    this.props.handleClick(this.props.id);
  }

  render() {
    let classes = classNames({
      'usage-license-type-select__item': true,
      'usage-license-type-select__item--selected': this.props.selected
    }),
      icon,
      price,
      usageLicenseTypeLabel;

    let addToCartButton;

    addToCartButton = (<a href='#' onClick={this.handleClick} className='usage-license-type-select__item-link button button--secondary button--full'>
      <span className='usage-license-type-select__item-title'>{this.props.locale === 'de' ? '+ In den Warenkorb' : '+ Add to cart'}</span>
    </a>);


    if (this.props.free) {
      price = this.props.i18n.free;
    } else {
      if (this.props.discount) {
        price = this.props.discountGrossPrice;
      } else {
        price = this.props.grossPrice;
      }

    }

    // if (this.state.hover) {
    //     if (this.props.selected) {
    //         usageLicenseTypeLabel = <div><span className='usage-license-type-select__item-title'>{this.props.name}</span><span className='usage-license-type-select__item-remove-label'>{this.props.i18n.remove}</span></div>
    //     } else {
    //         usageLicenseTypeLabel = <div><span className='usage-license-type-select__item-title'>{this.props.name}</span><span className='usage-license-type-select__item-add-label'>{this.props.i18n.add_to_cart}</span></div>
    //     }
    // } else {
    //     usageLicenseTypeLabel = <span className='usage-license-type-select__item-title'>{this.props.name}</span>
    // }

    usageLicenseTypeLabel = <span className='usage-license-type-select__item-title'>{this.props.name}</span>

    return (
      <div className={classes}>
        <div className='usage-license-type-select__item-price'>
          {price}
          <span className='usage-license-type-select__item-price-tax-hint'>inkl. MwSt.</span>
        </div>
        {addToCartButton}
      </div>
    )
  }
}

UsageLicenseTypeSelectItemSidebar.defaultProps = {
  selected: false,
  free: false
}

export default UsageLicenseTypeSelectItemSidebar;
