import React, { Component } from 'react';
import classNames from 'classnames/bind';

class UsageLicenseTypeSelectItem extends Component {

  constructor(props) {
    super(props);

    this.handleAddClick = this.handleAddClick.bind(this);
    this.handleMouseEnter = this.handleMouseEnter.bind(this);
    this.handleMouseLeave = this.handleMouseLeave.bind(this);

    this.state = {
      hover: false
    }
  }
  
  handleAddClick(e) {
      e.preventDefault();

      global.EventHub.trigger('elementCatapult', 'cart', this.refs.plusButton);

      if (this.props.forFx) {
          this.props.handleClick(this.props.id, true);
      } else {
          this.props.handleClick(this.props.id, false);
      }
  }

  handleMouseEnter() {
      this.setState({
          hover: true
      });
  }

  handleMouseLeave() {
      this.setState({
          hover: false
      });
  }

  render() {
      let classes = classNames({
            'usage-license-type-select__item': true,
            'usage-license-type-select__item--hover': this.state.hover,
            'usage-license-type-select__item--selected': this.props.selected
          }),
          icon,
          price,
          usageLicenseTypeLabel;

      if (this.props.selected) {
          icon = <div className='usage-license-type-select__item-added'><i className='fa fa-check' /></div>;
      }

      if (this.props.free) {
          price = this.props.i18n.free;
      } else {
          if (this.props.discount) {
              price = this.props.discountGrossPrice;
          } else if (this.props.includedInSubscription) {
              price = this.props.i18n.free;
          } else {
              price = this.props.grossPrice;
          }

      }

      if (this.state.hover) {
          usageLicenseTypeLabel = <div className='usage-license-type-select__item-title-wrapper'><span className='usage-license-type-select__item-title'>{this.props.name}{this.props.count > 0 ? (' (x' + this.props.count + ')') : ''}<span className='usage-license-type-select__item-add-label'>{this.props.locale === 'de' ? 'In Warenkorb' : 'Add to cart'}</span></span></div>
      } else {
          usageLicenseTypeLabel = <div className='usage-license-type-select__item-title-wrapper'><span className='usage-license-type-select__item-title'>{this.props.name}{this.props.count > 0 ? (' (x' + this.props.count + ')') : ''}</span></div>
      }

      return (
        <div className={classes}>
          <a href='#' onClick={this.handleAddClick} onMouseEnter={this.handleMouseEnter} onMouseLeave={this.handleMouseLeave} className='usage-license-type-select__item-link'>
            <div className='usage-license-type-select__item-content'>
              {icon}
              {usageLicenseTypeLabel}
              <div className='usage-license-type-select__item-content-price text-right'>
                <span className='usage-license-type-select__item-price'>
                  {price}
                </span>
              </div>
              <div className='usage-license-type-select__item-add'>
                  <span ref='plusButton' className='usage-license-type-select__item-add-icon fa fa-plus-circle'></span>
              </div>
            </div>
          </a>
        </div>
      )
  }
};

UsageLicenseTypeSelectItem.defaultProps = {
  selected: false,
  free: false,
  count: 0,
  includedInSubscription: false
}

export default UsageLicenseTypeSelectItem;
