import React, { Component } from 'react';

class SearchMenuLengthInputs extends Component {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);

  }

  handleChange(e) {
    let $minInputValue = parseInt($(this.refs.minField).val(), 10),
        $maxInputValue = parseInt($(this.refs.maxField).val(), 10);

    if (typeof tempoLengthChangeTimeout !== 'undefined') {
        clearTimeout(tempoLengthChangeTimeout);
    }

    this.props.submitLengthChange($minInputValue, $maxInputValue);

    window.tempoLengthChangeTimeout = setTimeout(function() {
        global.EventHub.trigger('queryInputSubmit');
    }, 500);
  }

  handleKeyPress(e) {
      if (e.key === 'Enter'){
        global.EventHub.trigger('queryInputSubmit');
      }
  }

  render() {
    return (
      <div className='search-menu__query-input-wrapper search-menu__query-input-wrapper--length'>
        <div className='row'>
          <div className='col6 padding-right5'>
            <input type='text' className='search-menu__min-length-input' placeholder={this.props.locale === 'de' ? 'min. Länge (sec)' : 'Min length (sec)'} ref='minField' onChange={this.handleChange} onKeyPress={this.handleKeyPress} value={this.props.minLength}/>
            </div>
          <div className='col6 padding-left5'>
            <input type='text' className='search-menu__max-length-input' placeholder={this.props.locale === 'de' ? 'max. Länge (sec)' : 'Max length (sec)'} ref='maxField' onChange={this.handleChange} onKeyPress={this.handleKeyPress} value={this.props.maxLength}/>
          </div>
        </div>
      </div>
    )
  }
};

SearchMenuLengthInputs.defaultProps = {
  submitLengthChange : undefined
}

export default SearchMenuLengthInputs;
