import Helpers from '~/modules/Helpers.js';
// // import $ from 'jquery';

const PriceInfo = function(selectorString, config) {
    let _defaultConfig,
        that = this;

    _defaultConfig = {
        selectorString : '[data-action~="initPriceInfo"]',
        selectBoxSelectorString : '[data-action~="initPriceInfoSelect"]',
        selectBoxPrevPriceString : '[data-action~="initPriceInfoPrevPrice"]',
        priceInfoNetPriceCssClass : '.price-info-net-price',
        priceInfoGrossPriceCssClass : '.price-info-gross-price',
        priceInfoCurrencyCssClass : '.price-info-currency',
        priceInfoTaxCssClass : '.price-info-tax',
        priceInfoReducedNetPriceCssClass : '.price-info-reduced-net-price',
        currency : '€',
        taxAmount : '0.07',
        delimiter : ',',
        beforeLoad : function(instance) {

        },
        afterLoad : function(instance) {

        }
    };

    this.config = Helpers.createConfigObject(config, _defaultConfig);

    if (selectorString) {
        this.config.selectorString = selectorString;
    }

    // elements
    this.containers = [];
    this.selectBoxes = [];
    this.netPrice = 0.00;
    this.grossPrice = 0.00;
    this.prevPrice = undefined;
    this.reducedPrice = undefined;

    this.onSelectBoxChange = function(e) {
        this._updatePriceInfo();
    }.bind(this);

    this.renderPriceInfo = function() {
        var containersLength =  this.containers.length,
            i;

        for (i = 0; i < containersLength; i += 1) {
            this._updateContainerPrice(this.containers[i]);
        }

    }.bind(this);

    this._init();
};


PriceInfo.prototype._init = function() {
    var containers = document.querySelectorAll(this.config.selectorString);
    this.config.beforeLoad(this);

    if (containers.length > 0) {
        this._mountContainers();
        this._mountSelectBoxes();
        this._fetchCurrency();
        this._fetchTax();
        this._mountPrevPrice();
        this._updatePriceInfo();
    }

    this.config.afterLoad(this);
};

PriceInfo.prototype._mountContainers = function() {
    var containers = document.querySelectorAll(this.config.selectorString),
        containersLength = containers.length,
        i;

    // Add event handlers to all link elements and populate links array
    for (i = 0; i < containersLength; i += 1) {
        this.containers.push(containers[i]);
        this._addEventListenersForContainers();
    }
};

PriceInfo.prototype._mountSelectBoxes = function() {
    var selectBoxes = document.querySelectorAll(this.config.selectBoxSelectorString),
        selectBoxesLength = selectBoxes.length,
        i;

    for (i = 0; i < selectBoxesLength; i += 1) {
        this.selectBoxes.push(selectBoxes[i]);
        this._addEventListenersForSelectBoxes(selectBoxes[i]);
    }
};

PriceInfo.prototype._fetchCurrency = function() {
    var currencySymbol = this.containers[0].dataset.priceInfoCurrencySymbol;

    this.config.currency = currencySymbol;
};

PriceInfo.prototype._fetchTax = function() {
    var taxAmount = this.containers[0].dataset.priceInfoTax;

    this.config.taxAmount = taxAmount;
};


PriceInfo.prototype._mountPrevPrice = function() {
    var prevPriceElement = document.querySelector(this.config.selectBoxPrevPriceString);

    if (prevPriceElement) {
        this.prevPrice = (parseFloat(prevPriceElement.dataset.priceInfoPrev)).toFixed(2);
    }
};

PriceInfo.prototype._addEventListenersForSelectBoxes = function(selectBox) {
    Helpers._addEventListener(selectBox, 'change', this.onSelectBoxChange);
};

PriceInfo.prototype._updatePriceInfo = function() {
    var selectBoxesLength = this.selectBoxes.length,
        i,
        price = 0.00,
        vatPrice = 0.00,
        grossPrice = 0.00,
        reducedPrice = 0.00,
        priceString,
        vatString,
        grossPriceString;

    for (i = 0; i < selectBoxesLength; i += 1) {
        priceString = $(this.selectBoxes[i]).find('option:selected').attr('data-price');
        price += parseFloat(priceString);

        vatString = $(this.selectBoxes[i]).find('option:selected').attr('data-vat-price');
        vatPrice += parseFloat(vatString);

        grossPriceString = $(this.selectBoxes[i]).find('option:selected').attr('data-gross-price');
        grossPrice += parseFloat(grossPriceString);
    }

    if (this.prevPrice) {
        this.netPrice = price.toFixed(2);
        this.reducedPrice = (price - this.prevPrice).toFixed(2);
        this.tax = vatPrice.toFixed(2);
        this.grossPrice = (parseFloat(this.reducedPrice) + parseFloat(this.tax)).toFixed(2);
    } else {
        this.netPrice = price.toFixed(2);
        this.grossPrice = grossPrice.toFixed(2);
        this.tax = vatPrice.toFixed(2);
    }

    global.EventHub.trigger('renderPriceInfo');
};

PriceInfo.prototype._addEventListenersForContainers = function(container) {
    global.EventHub.on('renderPriceInfo', this.renderPriceInfo);
};

PriceInfo.prototype._updateContainerPrice = function(container) {
    $(container).find(this.config.priceInfoNetPriceCssClass).text(this.netPrice.replace('.',this.config.delimiter));
    
    if (this.reducedPrice) {
        $(container).find(this.config.priceInfoReducedNetPriceCssClass).text(this.reducedPrice.replace('.',this.config.delimiter))
        let tax = (parseFloat(this.reducedPrice) * this.config.taxAmount).toFixed(2);
        $(container).find(this.config.priceInfoTaxCssClass).text(tax.replace('.',this.config.delimiter));
        let reduced = parseFloat(this.reducedPrice);
        let gross = (reduced + parseFloat(tax)).toFixed(2);
        $(container).find(this.config.priceInfoGrossPriceCssClass).text(gross.replace('.',this.config.delimiter));
    } else {
        $(container).find(this.config.priceInfoTaxCssClass).text(this.tax.replace('.',this.config.delimiter));
        $(container).find(this.config.priceInfoGrossPriceCssClass).text(this.grossPrice.replace('.',this.config.delimiter));
    }

    $(container).find(this.config.priceInfoCurrencyCssClass).text(this.config.currency);
};

export default PriceInfo;
