import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
// import $ from 'jquery';


class SearchMenuInvisible extends Component {

  constructor(props) {
    super(props);

    this.searchRef = null;

    this.setSearchRef = element => {
      this.searchRef = element;
    };

    this.handleTempoChange = this.handleTempoChange.bind(this);
    this.toggleTempoSearch = this.toggleTempoSearch.bind(this);
    this.handleSubmitSearchClick = this.handleSubmitSearchClick.bind(this);
    this.submitQueryClick = this.submitQueryClick.bind(this);
    this.submitMenuItemClick = this.submitMenuItemClick.bind(this);
    this.submitQueryChange = this.submitQueryChange.bind(this);
    this.submitMinLengthClick = this.submitMinLengthClick.bind(this);
    this.submitMaxLengthClick = this.submitMaxLengthClick.bind(this);
    this.submitLengthChange = this.submitLengthChange.bind(this);
    this.submitExtendedFilterChange = this.submitExtendedFilterChange.bind(this);
    this.submitProFilterChange = this.submitProFilterChange.bind(this);
    this.isExtendedFilterChecked = this.isExtendedFilterChecked.bind(this);
    this.isProFilterChecked = this.isProFilterChecked.bind(this);
    this.sendPaginationSearchQuery = this.sendPaginationSearchQuery.bind(this);

    this.state = {
      query: '',
      genres: [],
      instruments: [],
      moods: [],
      tags: [],
      instoreTags: [],
      minTempo: 80,
      maxTempo: 140,
      tempoSearchActive: false,
      page: this.props.initialSearchQuery.page ? parseInt(this.props.initialSearchQuery.page, 10) : 1,
      composers: [],
      completedQuery: '',
      minLength: '',
      maxLength: '',
      free: false,
      sortDirection: '',
      exposurePackId: '',
      mediaBundleId: '',
      labelId: '',
      albumId: '',
      sortBy: '',
      trackId: this.props.initialSearchQuery.track_id,
      extendedFilters: {
        drums: 0,
        vocals: 0,
        withVariants: 0,
        loop: 0
      },
      proFilter: (typeof this.props.initialSearchQuery.include_pro === 'undefined') ? 2 : this.props.initialSearchQuery.include_pro
    };
  }

  UNSAFE_componentWillMount() {
    let stateObject = {},
      searchCloudItemIds = [];

    for (let searchCategory in this.props.initialSearchQuery) {
      if (this.props.initialSearchQuery.hasOwnProperty(searchCategory)) {

        if (searchCategory === 'query' && this.props.initialSearchQuery[searchCategory].length > 0) {
          this.setState({ query: this.props.initialSearchQuery[searchCategory] });
        } else if (searchCategory === 'minTempo' && this.props.initialSearchQuery[searchCategory] > 0 && this.props.initialSearchQuery.tempoSearchActive) {
          this.setState({ minTempo: this.props.initialSearchQuery[searchCategory] });
        } else if (searchCategory === 'maxTempo' && this.props.initialSearchQuery[searchCategory] > 0 && this.props.initialSearchQuery.tempoSearchActive) {
          this.setState({ maxTempo: this.props.initialSearchQuery[searchCategory] });
        } else if (searchCategory === 'minLength' && this.props.initialSearchQuery[searchCategory] >= 0) {
          this.setState({ minLength: this.props.initialSearchQuery[searchCategory] });
        } else if (searchCategory === 'maxLength' && parseInt(this.props.initialSearchQuery[searchCategory]) >= 0) {
          this.setState({ maxLength: this.props.initialSearchQuery[searchCategory] });
        } else if (searchCategory === 'trackId') {
          this.setState({ trackId: this.props.initialSearchQuery[searchCategory] });
        } else if (searchCategory === 'exposurePackId') {
          this.setState({ exposurePackId: this.props.initialSearchQuery[searchCategory] });
        } else if (searchCategory === 'mediaBundleId') {
          this.setState({ mediaBundleId: this.props.initialSearchQuery[searchCategory] });
        } else if (searchCategory === 'labelId') {
          this.setState({ labelId: this.props.initialSearchQuery[searchCategory] });
        } else if (searchCategory === 'albumId') {
          this.setState({ albumId: this.props.initialSearchQuery[searchCategory] });
        } else if (searchCategory === 'free') {
          if (this.props.initialSearchQuery[searchCategory]) {
            this.setState({ free: this.props.initialSearchQuery[searchCategory] });
          }
        } else if (searchCategory === 'page') {
          if (this.props.initialSearchQuery[searchCategory]) {
            this.setState({ page: parseInt(this.props.initialSearchQuery[searchCategory], 10) });
          }
        } else {
          stateObject = {};
          searchCloudItemIds = [];

          for (let i = 0; i < this.props.initialSearchQuery[searchCategory].length; i += 1) {
            searchCloudItemIds.push(this.props.initialSearchQuery[searchCategory][i]);
          }

          stateObject[searchCategory] = searchCloudItemIds;
          this.setState(stateObject);
        }
      }
    }
  }

  componentDidMount() {
    this.setStateViaUrlParams();

    global.EventHub.on('queryInputSubmit', function () {
      this.sendSearchQuery();
    }.bind(this));

    global.EventHub.on('paginationClick', this.sendPaginationSearchQuery);

    global.EventHub.on('changeSort', function (payload) {
      if (payload[0]) {
        this.setState({ sortDirection: payload[0].sortDirection, sortBy: payload[0].sortBy }, function () {
          this.sendSearchQuery();
        });
      }
    }.bind(this));

    global.EventHub.on('trackTagClick', function (payload) {
      if (payload[0]) {
        if (this.state.tags.indexOf(payload[0]) > -1) {
          let index = this.state.tags.indexOf(payload[0]);
          let tags = this.state.tags;

          tags.splice(index, 1)

          this.setState({ tags: tags }, function () {
            this.sendSearchQuery();
          });

        } else {
          this.setState({ tags: [payload[0]] }, function () {
            this.sendSearchQuery();
          });
        }

      }
    }.bind(this));

    global.EventHub.on('trackGenreClick', function (payload) {
      if (payload[0]) {
        if (this.state.genres.indexOf(payload[0]) > -1) {
          let index = this.state.genres.indexOf(payload[0]);
          let genres = this.state.genres;

          genres.splice(index, 1)

          this.setState({ genres: genres }, function () {
            this.sendSearchQuery();
          });

        } else {
          this.setState({ genres: [payload[0]] }, function () {
            this.sendSearchQuery();
          });
        }
      }
    }.bind(this));

    global.EventHub.on('trackMoodClick', function (payload) {
      if (payload[0]) {
        if (this.state.moods.indexOf(payload[0]) > -1) {
          let index = this.state.moods.indexOf(payload[0]);
          let moods = this.state.moods;

          moods.splice(index, 1)

          this.setState({ moods: moods }, function () {
            this.sendSearchQuery();
          });

        } else {
          this.setState({ moods: [payload[0]] }, function () {
            this.sendSearchQuery();
          });
        }

      }
    }.bind(this));


    global.EventHub.on('requestUpdateTrackListing', function () {
      this.sendSearchQuery(true, true);
    }.bind(this));
  }

  sendPaginationSearchQuery(pageNumber) {
    this.setState({ page: pageNumber }, function () {
      this.sendSearchQuery(true);
    });
  }

  setStateViaUrlParams() {
    this.setQueryFromUrl();    
    this.setExtendedFiltersFromUrl();
    this.setIdsFromUrlFor('genre');
    this.setIdsFromUrlFor('instrument');
    this.setIdsFromUrlFor('mood');
    this.setIdsFromUrlFor('tag');
    this.setTempoFromUrl();
    this.setLabelFromUrl();
    this.setLengthFromUrl();
    this.setSortFromUrl();
  }

  setQueryFromUrl() {
    let regex = /\q\=([\w\+\d\%,-]*)/g,
      matches = regex.exec(document.location.search),
      matchesLength,
      queryText;

    if (matches !== null) {
      matchesLength = matches.length;
      for (let i = 0; i < matchesLength; i += 1) {
        queryText = matches[i].replace(/\+/g, ' ');
      }
      this.setState({ query: decodeURI(queryText), completedQuery: decodeURI(queryText) });
    }
  }

  setLabelFromUrl() {
    let labelRegex = /label_id\=([\d]*)/g,
      labelMatches = labelRegex.exec(document.location.search),
      labelMatchesLength,
      labelId;

    if (labelMatches !== null) {
      labelMatchesLength = labelMatches.length;
      for (let i = 0; i < labelMatchesLength; i += 1) {
        labelId = labelMatches[i];
      }
    }

    if (labelId) {
      this.setState({
        labelId: labelId
      });
    }

  }

  setIdsFromUrlFor(paramString) {
    let pattern = '\_id\\[\\]\\=(\\d)+',
      regex = new RegExp(paramString + pattern, 'gi'),
      matches = document.location.search.match(regex),
      ids = [],
      stateObject = {},
      matchesLength,
      matchId,
      i;

    if (matches !== null) {
      matchesLength = matches.length;
      for (i = 0; i < matchesLength; i += 1) {
        matchId = parseInt(matches[i].replace(paramString + '_id[]=', ''), 10);
        ids.push(matchId);
      }
      stateObject[paramString + 's'] = ids;
      this.setState(stateObject, function () {
        setTimeout(function () {
          global.EventHub.trigger('updateItemIdList', { genres: this.state.genres, tags: this.state.tags, moods: this.state.moods, includePro: this.state.proFilter });
        }.bind(this), 50)
      });
    }
  }

  setTempoFromUrl() {
    let minRegex = /min_tempo\=([\d]*)/g,
      minMatches = minRegex.exec(document.location.search),
      minMatchesLength,
      minQueryText,

      maxRegex = /max_tempo\=([\d]*)/g,
      maxMatches = maxRegex.exec(document.location.search),
      maxMatchesLength,
      maxQueryText;

    if (minMatches !== null) {
      minMatchesLength = minMatches.length;
      for (let i = 0; i < minMatchesLength; i += 1) {
        minQueryText = minMatches[i];
      }
    }

    if (maxMatches !== null) {
      maxMatchesLength = maxMatches.length;
      for (let i = 0; i < maxMatchesLength; i += 1) {
        maxQueryText = maxMatches[i];
      }
    }

    if (minQueryText) {
      this.setState({
        minTempo: minQueryText,
        tempoSearchActive: true
      });
    }

    if (maxQueryText) {
      this.setState({
        maxTempo: maxQueryText,
        tempoSearchActive: true
      });
    }
  }

  setExtendedFiltersFromUrl() {
    let drumRegex = /drums\=([\d]*)/g,
      drumMatches = drumRegex.exec(document.location.search),
      drumMatchesLength,
      drumValue,

      vocalRegex = /vocals\=([\d]*)/g,
      vocalMatches = vocalRegex.exec(document.location.search),
      vocalMatchesLength,
      vocalValue,

      includeProRegex = /include_pro\=([\d]*)/g,
      includeProMatches = includeProRegex.exec(document.location.search),
      includeProMatchesLength,
      includeProValue,

      variantsRegex = /with_variants\=([\d]*)/g,
      variantsMatches = variantsRegex.exec(document.location.search),
      variantsMatchesLength,
      variantsValue,

      loopRegex = /loop\=([\d]*)/g,
      loopMatches = loopRegex.exec(document.location.search),
      loopMatchesLength,
      loopValue,

      shouldUpdate = false,

      stateObject = this.state;

    if (drumMatches !== null) {
      drumMatchesLength = drumMatches.length;
      for (let i = 0; i < drumMatchesLength; i += 1) {
        drumValue = drumMatches[i];
      }
    }

    if (drumValue) {
      stateObject.extendedFilters.drums = parseInt(drumValue, 10);
      if (this.state.extendedFiters && this.state.extendedFiters.drums !== stateObject.extendedFilters.drums) {
        shouldUpdate = true;
      }
    }

    if (vocalMatches !== null) {
      vocalMatchesLength = vocalMatches.length;
      for (let i = 0; i < vocalMatchesLength; i += 1) {
        vocalValue = vocalMatches[i];
      }
    }

    if (vocalValue) {
      stateObject.extendedFilters.vocals = parseInt(vocalValue, 10);
      if (this.state.extendedFiters && this.state.extendedFiters.vocals !== stateObject.extendedFilters.vocals) {
        shouldUpdate = true;
      }
    }

    if (includeProMatches !== null) {
      includeProMatchesLength = includeProMatches.length;
      for (var i = 0; i < includeProMatchesLength; i += 1) {
          includeProValue = includeProMatches[i];
      }
    }

    if (includeProValue) {
      stateObject.proFilter = parseInt(includeProValue, 10);
      if (this.stateproFilter !== stateObject.proFilter) {
          shouldUpdate = true;
      }
    }

    if (variantsMatches !== null) {
      variantsMatchesLength = variantsMatches.length;
      for (let i = 0; i < variantsMatchesLength; i += 1) {
        variantsValue = variantsMatches[i];
      }
    }

    if (variantsValue) {
      stateObject.extendedFilters.withVariants = parseInt(variantsValue, 10);
      if (this.state.extendedFiters && this.state.extendedFiters.withVariants !== stateObject.extendedFilters.withVariants) {
        shouldUpdate = true;
      }
    }

    if (loopMatches !== null) {
      loopMatchesLength = loopMatches.length;
      for (let i = 0; i < loopMatchesLength; i += 1) {
        loopValue = loopMatches[i];
      }
    }

    if (loopValue) {
      stateObject.extendedFilters.loop = parseInt(loopValue, 10);
      if (this.state.extendedFiters && this.state.extendedFiters.loop !== stateObject.extendedFilters.loop) {
        shouldUpdate = true;
      }
    }

    if (shouldUpdate) {
      this.setState(stateObject);
    }
  }

  setLengthFromUrl() {
    let minRegex = /min_length\=([\d]*)/g,
      minMatches = minRegex.exec(document.location.search),
      minMatchesLength,
      minQueryText,

      maxRegex = /max_length\=([\d]*)/g,
      maxMatches = maxRegex.exec(document.location.search),
      maxMatchesLength,
      maxQueryText;

    if (minMatches !== null) {
      minMatchesLength = minMatches.length;
      for (let i = 0; i < minMatchesLength; i += 1) {
        minQueryText = minMatches[i];
      }
    }

    if (maxMatches !== null) {
      maxMatchesLength = maxMatches.length;
      for (let i = 0; i < maxMatchesLength; i += 1) {
        maxQueryText = maxMatches[i];
      }
    }

    if (minQueryText) {
      this.setState({
        minLength: parseInt(minQueryText, 10)
      });
    }

    if (maxQueryText) {
      this.setState({
        maxLength: parseInt(maxQueryText, 10)
      });
    }
  }

  setSortFromUrl() {
    let directionRegex = /sort_direction\=([a-zA-Z]+)/g,
      directionMatches = directionRegex.exec(document.location.search),
      directionMatchesLength,
      directionQueryText;

    if (directionMatches !== null) {
      directionMatchesLength = directionMatches.length;
      for (let i = 0; i < directionMatchesLength; i += 1) {
        directionQueryText = directionMatches[i];
      }
    }

    let sortByRegex = /sort_by\=([a-zA-Z]+)/g,
      sortByMatches = sortByRegex.exec(document.location.search),
      sortByMatchesLength,
      sortByQueryText;

    if (sortByMatches !== null) {
      sortByMatchesLength = sortByMatches.length;
      for (let i = 0; i < sortByMatchesLength; i += 1) {
        sortByQueryText = sortByMatches[i];
      }
    }


    if (sortByQueryText && directionQueryText) {
      this.setState({
        sortDirection: directionQueryText,
        sortBy: sortByQueryText
      });
    } else if (directionQueryText) {
      this.setState({
        sortDirection: directionQueryText
      });
    }
  }

  handleTempoChange(minTempo, maxTempo, active) {
    this.setState({
      minTempo: minTempo,
      maxTempo: maxTempo,
      tempoSearchActive: active
    });

    this.sendSearchQuery();
  }

  toggleTempoSearch(e) {
    e.preventDefault();

    this.setState({
      tempoSearchActive: !this.state.tempoSearchActive,
    });

    setTimeout(function () {
      this.sendSearchQuery();
    }.bind(this), 1)
  }

  handleSubmitSearchClick() {
    this.sendSearchQuery();
  }

  submitQueryClick(query) {
    let stateObject = this.state;

    stateObject.query = '';
    stateObject.completedQuery = '';

    this.setState(stateObject);
    this.sendSearchQuery();
  }

  submitMenuItemClick(name, id) {
    let stateObject = this.state,
      arrayLength = stateObject[name].length,
      i,
      removeIndex,
      shouldAddToArray = true;

    for (i = 0; i < arrayLength; i += 1) {
      if (stateObject[name][i] === id) {
        shouldAddToArray = false;
      }
    }

    if (shouldAddToArray) {
      if (name === 'moods' || name === 'instruments') {
        stateObject[name].push(id);
      } else {
        stateObject[name] = [id];
      }
    } else {
      removeIndex = stateObject[name].indexOf(id);
      if (removeIndex > -1) {
        stateObject[name].splice(removeIndex, 1);
      }
    }

    this.setState(stateObject);
    this.sendSearchQuery();
  }

  submitQueryChange(fieldValue) {
    this.setState({ query: fieldValue });
  }

  submitMinLengthClick() {
    let stateObject = this.state;

    stateObject.minLength = '';

    this.setState(stateObject);
    this.sendSearchQuery();
  }

  submitMaxLengthClick() {
    let stateObject = this.state;

    stateObject.maxLength = '';

    this.setState(stateObject);
    this.sendSearchQuery();
  }

  submitLengthChange(minLength, maxLength) {
    if (minLength > 0) {
      this.setState({ minLength: minLength });
    } else {
      this.setState({ minLength: '' });
    }
    if (maxLength > 0) {
      this.setState({ maxLength: maxLength });
    } else {
      this.setState({ maxLength: '' });
    }
  }

  submitExtendedFilterChange(name, value) {
    let shouldChange = false;
    let stateObject = this.state;

    stateObject.extendedFilters[name] = value;

    this.setState(stateObject, function () {
      this.sendSearchQuery();
    });


  }

  submitProFilterChange(value) {
    let shouldChange = false;
    let stateObject = this.state;

    stateObject.proFilter = value;

    this.setState(stateObject, function () {
      this.sendSearchQuery();
    });
  }


  isExtendedFilterChecked(name, value) {
    let isChecked = false;

    if (this.state.extendedFilters[name] === value) {
      isChecked = true;
    }

    return isChecked;
  }

  isProFilterChecked(value) {
    let isChecked = false;

    if (this.state.proFilter === value) {
      isChecked = true;
    }

    return isChecked;
  }

  extendedFiltersMenuHighlighted() {
    let isHighlighted = false;

    if (this.state.extendedFilters.drums !== 0 || this.state.extendedFilters.vocals !== 0 || this.state.extendedFilters.withVariants !== 0 || this.state.extendedFilters.loop !== 0) {
      isHighlighted = true
    }

    return isHighlighted;
  }

  proFiltersMenuHighlighted() {
    let isHighlighted = true;
    // let isHighlighted = false;

    // if (this.state.proFilter !== 2) {
    //   isHighlighted = true
    // }

    return isHighlighted;
  }


  submitSortChange(sortBy, sortDirection) {
    let stateObject = {
      sortBy: '',
      sortDirection: ''
    };

    if (sortBy === 'date') {
      stateObject['sortBy'] = sortBy;
    } else if (sortBy === 'popularity') {
      stateObject['sortBy'] = sortBy;
    } else if (sortBy === 'length') {
      stateObject['sortBy'] = sortBy;
    } else {
      stateObject['sortBy'] = '';
    }

    if (sortDirection === 'asc') {
      stateObject['sortDirection'] = sortDirection;
    } else if (sortDirection === 'desc') {
      stateObject['sortDirection'] = sortDirection;
    } else {
      stateObject['sortDirection'] = '';
    }

    this.setState(stateObject, function () {
      this.sendSearchQuery();
    });

  }

  getActiveSearchCriteriaCount() {
    let criteriaCount = 0;

    if (this.props.hideCategoryFromUrl !== 'tag' && this.state.tags.length > 0) {
      criteriaCount += this.state.tags.length;
    }

    if (this.props.hideCategoryFromUrl !== 'instore_tag' && this.state.instoreTags.length > 0) {
      criteriaCount += this.state.instoreTags.length;
    }

    if (this.props.hideCategoryFromUrl !== 'genre' && this.state.genres.length > 0) {
      criteriaCount += this.state.genres.length;
    }

    if (this.props.hideCategoryFromUrl !== 'instrument' && this.state.instruments.length > 0) {
      criteriaCount += this.state.instruments.length;
    }

    if (this.props.hideCategoryFromUrl !== 'mood' && this.state.moods.length > 0) {
      criteriaCount += this.state.moods.length;
    }

    if (this.state.minLength > 0) {
      criteriaCount += 1;
    }

    if (this.state.maxLength > 0) {
      criteriaCount += 1;
    }

    if (this.state.query.length > 0) {
      criteriaCount += 1;
    }

    return criteriaCount;
  }

  getActiveSearchCriteriaCountWithHiddenCriterias() {
    let criteriaCount = 0;

    if (this.state.tags.length > 0) {
      criteriaCount += this.state.tags.length;
    }

    if (this.state.instoreTags.length > 0) {
      criteriaCount += this.state.instoreTags.length;
    }

    if (this.state.genres.length > 0) {
      criteriaCount += this.state.genres.length;
    }

    if (this.state.instruments.length > 0) {
      criteriaCount += this.state.instruments.length;
    }

    if (this.state.moods.length > 0) {
      criteriaCount += this.state.moods.length;
    }

    if (this.state.minLength > 0) {
      criteriaCount += 1;
    }

    if (this.state.maxLength > 0) {
      criteriaCount += 1;
    }

    if (this.state.query.length > 0) {
      criteriaCount += 1;
    }

    return criteriaCount;
  }

  buildSearchQueryParams(omitPageParam) {
    let searchQueryParamsString = '',
      textQuery = '';

    for (let searchCategory in this.state) {
      if (this.state.hasOwnProperty(searchCategory)) {

        if (searchCategory === 'query' && this.state[searchCategory].length > 0) {
          textQuery = 'q=' + this.state[searchCategory] + '&';
          searchQueryParamsString += textQuery;
        } else if (searchCategory === 'minLength' && this.state[searchCategory] > 0) {
          searchQueryParamsString += 'min_length=' + this.state[searchCategory] + '&';
        } else if (searchCategory === 'maxLength' && this.state[searchCategory] > 0) {
          searchQueryParamsString += 'max_length=' + this.state[searchCategory] + '&';
        } else if (searchCategory === 'minTempo' && this.state[searchCategory] > 0 && this.state.tempoSearchActive) {
          searchQueryParamsString += 'min_tempo=' + this.state[searchCategory] + '&';
        } else if (searchCategory === 'maxTempo' && this.state[searchCategory] > 0 && this.state.tempoSearchActive) {
          searchQueryParamsString += 'max_tempo=' + this.state[searchCategory] + '&';
        } else if (searchCategory === 'trackId') {
          if (parseInt(this.state[searchCategory], 10) > 0) {
            searchQueryParamsString += 'track_id=' + this.state[searchCategory] + '&';
          }
        } else if (searchCategory === 'exposurePackId') {
          if (parseInt(this.state[searchCategory], 10) > 0) {
            searchQueryParamsString += 'exposure_pack_id=' + this.state[searchCategory] + '&';
          }
        } else if (searchCategory === 'mediaBundleId') {
          if (parseInt(this.state[searchCategory], 10) > 0) {
            searchQueryParamsString += 'media_bundle_id=' + this.state[searchCategory] + '&';
          }
        } else if (searchCategory === 'labelId') {
          if (parseInt(this.state[searchCategory], 10) > 0) {
            searchQueryParamsString += 'label_id=' + this.state[searchCategory] + '&';
          }
        } else if (searchCategory === 'albumId') {
          if (parseInt(this.state[searchCategory], 10) > 0) {
            searchQueryParamsString += 'album_id=' + this.state[searchCategory] + '&';
          }
        } else if (searchCategory === 'proFilter') {
          if (this.state[searchCategory] !== 2) {
            searchQueryParamsString += 'include_pro=' + this.state[searchCategory] + '&';
          }
        } else if (searchCategory === 'extendedFilters') {
          if (this.state[searchCategory].drums !== 0) {
            searchQueryParamsString += 'drums=' + this.state[searchCategory].drums + '&';
          }

          if (this.state[searchCategory].vocals !== 0) {
            searchQueryParamsString += 'vocals=' + this.state[searchCategory].vocals + '&';
          }

          // if (this.state[searchCategory].includePro !== 2) {
          //   searchQueryParamsString += 'include_pro=' + this.state[searchCategory].includePro + '&';
          // }

          if (this.state[searchCategory].withVariants !== 0) {
            searchQueryParamsString += 'with_variants=' + this.state[searchCategory].withVariants + '&';
          }

          if (this.state[searchCategory].loop !== 0) {
            searchQueryParamsString += 'loop=' + this.state[searchCategory].loop + '&';
          }

        } else if (searchCategory === 'page') {
          if (omitPageParam) {
            searchQueryParamsString += 'page=' + this.state[searchCategory] + '&';
          }
        } else if (searchCategory === 'sortBy' && typeof this.state[searchCategory] === 'string') {
          searchQueryParamsString += 'sort_by=' + this.state[searchCategory] + '&';
        } else if (searchCategory === 'sortDirection' && typeof this.state[searchCategory] === 'string') {
          searchQueryParamsString += 'sort_direction=' + this.state[searchCategory] + '&';
        } else if (searchCategory === 'free' && this.state[searchCategory] === true) {
          searchQueryParamsString += 'free=' + this.state[searchCategory] + '&';
        } else if (searchCategory === 'completedQuery') {

        } else {
          for (let i = 0; i < this.state[searchCategory].length; i += 1) {
            let elementValue = this.state[searchCategory][i],
              singularCategory = searchCategory.slice(0, -1),
              text = '';

            // if (this.props.hideCategoryFromUrl !== singularCategory) {
            //     text = singularCategory + '_id[]=' + elementValue + '&';
            // }

            text = singularCategory + '_id[]=' + elementValue + '&';

            searchQueryParamsString += text;
          }
        }
      }
    }


    if (searchQueryParamsString.endsWith('&')) {
      searchQueryParamsString = searchQueryParamsString.slice(0, -1);
    }

    if (searchQueryParamsString === 'sort_direction=&sort_by=') {
      searchQueryParamsString = '';
    }

    return searchQueryParamsString;
  }

  sendSearchQuery(paginationMode, initialPageLoadQuery) {
    let that = this,
      searchQueryParams, // search object
      newUrl,
      urlParams;

    if (!paginationMode) {
      this.setState({ page: 1 });
    }

    searchQueryParams = this.buildSearchQueryParams(paginationMode); // search object
    urlParams = (searchQueryParams.length > 0 ? ('?' + searchQueryParams) : '');

    $.ajax({
      url: '/tracks/search_results' + urlParams,
      beforeSend: function () {
        if (initialPageLoadQuery) {
          global.EventHub.trigger('fireSearchWithoutAnimation');
        } else {
          global.EventHub.trigger('fireSearch');
        }
      },
      success: function (data) {
        if (initialPageLoadQuery) {
          global.EventHub.trigger('initialUpdateTrackListing', data);
        } else {
          global.EventHub.trigger('updateTrackListing', data);
        }

        global.EventHub.trigger('searchSuccess', data);
        global.EventHub.trigger('updateItemIdList', { genres: that.state.genres, tags: that.state.tags, moods: that.state.moods, instoreTags: that.state.instoreTags, includePro: that.state.proFilter  });
        if (history.pushState && !initialPageLoadQuery) {
          if (paginationMode) {
            var prevParams = location.search.replace(/\?page=[\d]+/, '').replace(/&page=[\d]+/, '').replace(/[\?]+/, '');
            var path = window.location.pathname + '?' + prevParams + (prevParams.length > 0 ? '&' : '') + 'page=' + (that.state.page);
            if (path === '?') {
              path = '';
            }
            newUrl = window.location.protocol + "//" + window.location.host + path;
            newUrl = newUrl.replace(/\?page=1$/, '').replace(/&page=1$/, '');
          } else {
            var path = (searchQueryParams.length > 0 ? ('?' + searchQueryParams) : '').replace('?sort_direction=&sort_by=', '?').replace('&sort_direction=&sort_by=', '').replace('&sort_direction=desc&sort_by=popularity', '').replace('?sort_direction=desc&sort_by=popularity', '?');

            if (path === '?') {
              path = ''
            }

            newUrl = window.location.protocol + "//" + window.location.host + window.location.pathname + path;
            newUrl = newUrl.replace(/&page=[\d]+/, '');
          }

          if (that.props.hideCategoryFromUrl.length > 0) {
            if (that.props.hideCategoryFromUrl === 'tag') {
              newUrl = newUrl.replace(/\?tag_id\[\]=[\d]+&/, '?');
              newUrl = newUrl.replace(/&tag_id\[\]=[\d]+&/, '&');
              newUrl = newUrl.replace(/&tag_id\[\]=[\d]+/, '');
              newUrl = newUrl.replace(/\?tag_id\[\]=[\d]+/, '');
            }

            if (that.props.hideCategoryFromUrl === 'instore_tag') {
              newUrl = newUrl.replace(/\?instore_tag_id\[\]=[\d]+&/, '?');
              newUrl = newUrl.replace(/&instore_tag_id\[\]=[\d]+&/, '&');
              newUrl = newUrl.replace(/&instore_tag_id\[\]=[\d]+/, '');
              newUrl = newUrl.replace(/\?instore_tag_id\[\]=[\d]+/, '');
            }

            if (that.props.hideCategoryFromUrl === 'genre') {
              newUrl = newUrl.replace(/\?genre_id\[\]=[\d]+&/, '?');
              newUrl = newUrl.replace(/&genre_id\[\]=[\d]+&/, '&');
              newUrl = newUrl.replace(/&genre_id\[\]=[\d]+/, '');
              newUrl = newUrl.replace(/\?genre_id\[\]=[\d]+/, '');
            }

            if (that.props.hideCategoryFromUrl === 'composer') {
              newUrl = newUrl.replace(/\?composer_id\[\]=[\d]+&/, '?');
              newUrl = newUrl.replace(/&composer_id\[\]=[\d]+&/, '&');
              newUrl = newUrl.replace(/&composer_id\[\]=[\d]+/, '');
              newUrl = newUrl.replace(/\?composer_id\[\]=[\d]+/, '');
            }

          }

          window.history.pushState({ path: newUrl }, '', newUrl);

          if (typeof ga === 'function') {
            ga('send', 'pageview', path);
          }

          that.setState({ completedQuery: that.state.query });
        }
      }
    });
  }


  render() {
    return (
      <div className='search-menu__invisible'>
      </div>
    )
  }
};

SearchMenuInvisible.defaultProps = {
  genres: [
    {
      name: '',
      id: ''
    }
  ],
  instruments: [
    {
      name: '',
      id: ''
    }
  ],
  moods: [
    {
      name: '',
      id: ''
    }
  ],
  composers: [
    {
      name: '',
      id: ''
    }
  ],
  sortDirection: 'desc',
  sortBy: 'popularity',
  initialSearchQuery: {
    // query : '',
    // free : false,
    // genres : [],
    // instruments : [],
    // moods : [],
    // tags  : [],
    // instoreTags  : [],
    // minTempo : 80,
    // maxTempo : 140,
    // tempoSearchActive : false,
    // page : 1,
    // composers : [],
    // trackId : undefined,
    // aId : 1,
    // albumId : 1

  },
  autocompletion: [],
  i18n: {
    placeholder: 'z.B. Urlaub Gitarre fröhlich'
  },
  hideCategoryFromUrl: '',
  extendedFilters: [
    { category: 'drums', nameDe: 'Schlagzeug / Beat', nameEn: 'Drums / beat', value: undefined, labelsDe: ['Beliebig', 'Mit Beat', 'Ohne Beat'], labelsEn: ['Any', 'With beat', 'Without beat'] },
    { category: 'vocals', nameDe: 'Gesang / Stimme', nameEn: 'Singing / vocals', value: undefined, labelsDe: ['Beliebig', 'Mit Gesang', 'Ohne Gesang'], labelsEn: ['Any', 'With vocals', 'Without vocals'] },
    { category: 'withVariants', nameDe: 'Varianten / Stems', nameEn: 'Variants / stems', value: undefined, labelsDe: ['Beliebig', 'Mit Varianten', 'Ohne Varianten'], labelsEn: ['Any', 'With variants', 'Without variants'] },
    { category: 'loop', nameDe: 'Loop', nameEn: 'Loop', value: undefined, labelsDe: ['Beliebig', 'Mit Loops', 'Ohne Loops'], labelsEn: ['Any', 'With loops', 'Without loops'] },
  ],
  proFilters: [
    { category: 'includePro', nameDe: 'Verwertungsgesellschaft', nameEn: 'P.R.O.', value: undefined, labelsDe: ['Alle Tracks', 'Nur GEMA-pflichtig (P.R.O.)', 'Nur GEMA-frei'], labelsEn: ['Any', 'Only P.R.O. tracks', 'Only royalty free tracks'] }
  ]
}

SearchMenuInvisible.propTypes = {
  locale: PropTypes.string
};


// INIT

let elements = document.querySelectorAll('[data-react-component=SearchMenuInvisible]');

elements.forEach(function (element) {
  let reactProps = JSON.parse(element.dataset.reactProps);

  console.log('reactProps');
  console.log(reactProps);

  ReactDOM.render(
    <React.StrictMode>
      <SearchMenuInvisible {...reactProps} />
    </React.StrictMode>,
    element
  );
})

export default SearchMenuInvisible;
