import Helpers from '~/modules/Helpers.js';

const CancellationPolicyForm = function (element, config) {

    var _defaultConfig;

    _defaultConfig = {
        toggleClass: 'cancellation-policy__target--visible',
        // internals
        beforeMount: function () {
            // instance is available as instance parameter
        },
        afterMount: function () {
            // instance is available as instance parameter
        }
    };

    if (element && element.nodeType === 1) {
        this.cancellationPolicyContainer = element;
    } else {
        throw new Error('node for CancellationPolicyForm was invalid or not of the correct type');
    }

    this.cancellationPolicyFormSwitchLabel = undefined;
    this.cancellationPolicyTargetNotAccepted = undefined;
    this.cancellationPolicyTargetAccepted = undefined;

    this.config = Helpers.createConfigObject(config, _defaultConfig);

    this._init();
};

CancellationPolicyForm.prototype._init = function () {
    this.config.beforeMount(this);

    if (this.cancellationPolicyContainer) {
        this._mountElements();
        this._addEventListeners();
    }

    this.config.afterMount(this);
};

CancellationPolicyForm.prototype._mountElements = function () {
  this.cancellationPolicySwitchLabel = document.querySelector('[data-action~=cancellationPolicySwitchLabel]');
  this.cancellationPolicyTargetNotAccepted = document.querySelector('[data-action~=cancellationPolicyTargetNotAccepted]');
  this.cancellationPolicyTargetAccepted = document.querySelector('[data-action~=cancellationPolicyTargetAccepted]');
};


CancellationPolicyForm.prototype._addEventListeners = function () {
    this.cancellationPolicySwitchLabel.addEventListener('change', function (e) {
        this.cancellationPolicyTargetAccepted.classList.toggle(this.config.toggleClass);
        this.cancellationPolicyTargetNotAccepted.classList.toggle(this.config.toggleClass);
    }.bind(this));
};

export default CancellationPolicyForm;
