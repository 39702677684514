import Helpers from '~/modules/Helpers.js';

const Dropdown = function (element, config) {

    var _defaultConfig;

    _defaultConfig = {
        toggleClass: 'dropdown--visible',
        // internals
        beforeMount: function () {
            // instance is available as instance parameter
        },
        afterMount: function () {
            // instance is available as instance parameter
        }
    };

    if (element && element.nodeType === 1) {
        this.dropdownContainer = element;
    } else {
        throw new Error('linkNode for ToggleLink was invalid or not of the correct type');
    }

    this.closeEvent = undefined;

    this.config = Helpers.createConfigObject(config, _defaultConfig);

    this._init();
};

Dropdown.prototype._init = function () {
    this.config.beforeMount(this);

    if (this.dropdownContainer) {
        this._addEventListeners();
    }

    this.config.afterMount(this);
};

Dropdown.prototype._addEventListeners = function () {

    global.EventHub.on('closeAllDropdowns', function (data) {
        console.log('removing via eventhub');
        var originDropdownContainerElement = data[0];
        if (originDropdownContainerElement !== this.dropdownContainer) {
            this.dropdownContainer.parentElement.classList.remove(this.config.toggleClass);
        }
    }.bind(this));

    global.EventHub.on('closePopupMenus', function (data) {
        this.dropdownContainer.parentElement.classList.remove(this.config.toggleClass);
    }.bind(this));

    this.dropdownContainer.addEventListener('click', function (e) {
        e.preventDefault();

        global.EventHub.trigger('closeAllDropdowns', this.dropdownContainer);

        if (this.dropdownContainer.parentElement.classList.contains(this.config.toggleClass)) {
            // still open
            console.log('removing after 10ms');
            this.dropdownContainer.parentElement.classList.remove(this.config.toggleClass);
        } else {
            // closed
            console.log('adding after 10ms');
            this.dropdownContainer.parentElement.classList.add(this.config.toggleClass);
        }
    }.bind(this));
};

export default Dropdown;
