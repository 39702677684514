import React, { Component } from 'react';
import classNames from 'classnames/bind';
// import $ from 'jquery';
import ahoy from 'ahoy.js';

import UsageLicenseTypeSelect from '~/components/legacy/UsageLicenseTypeSelect.jsx';

class TrackFavorite extends Component {

  constructor(props) {
    super(props);
    
    this.handleClick = this.handleClick.bind(this);
    this.playTrack = this.playTrack.bind(this);
    this.handleCartClick = this.handleCartClick.bind(this);
    this.updateCart = this.updateCart.bind(this);

    this.state = {
      playing: false,
      showUsageLicenseSelect: false
     };
  }

  componentDidMount() {

      let that = this;

      global.EventHub.on('audioPlayerPlay', function(data) {
          if (data[0].id === that.props.id) {
              that.setState({
                  playing : true
              });
          }
      });


      global.EventHub.on('audioPlayerStop', function() {
          if (that.state.playing) {
              that.setState({
                  playing : false
              });
          }
      });

      global.EventHub.on('audioPlayerEnded', function() {
          if (that.state.playing) {
              that.setState({
                  playing : false
              });
          }
      });
  }

  handleClick(e) {
    e.preventDefault();

    $.ajax({
      url: "/track_favorite?track_id=" + this.props.id,
      type: 'POST'
    }).done(function(data) {
        global.EventHub.trigger('updateTrackFavorites', data)
    }.bind(this))
  }

  playTrack(e) {
      e.preventDefault();
      global.EventHub.trigger('playTrack', this.props.id);
      global.EventHub.trigger('updateTrackInfo', this.props);
      this.setState({
        playing: !this.state.playing
      });
  }

  updateCart(usageLicenseTypeId, exposurePackId, forFx) {
    let itemId = this.props.id || this.state.data.id,
        i,
        updateUrl;

    if (exposurePackId) {
        updateUrl = "/cart?exposure_pack_id=" + exposurePackId + "&usage_license_type_id=" + usageLicenseTypeId;
    } else if (forFx) {
        updateUrl = "/cart?sound_effect_id=" + itemId + "&usage_license_type_id=" + usageLicenseTypeId;
    } else {
        updateUrl = "/cart?track_id=" + itemId + "&usage_license_type_id=" + usageLicenseTypeId;
    }

    $.ajax({
      url: updateUrl,
      type: 'POST'
    }).done(function(data) {

      global.EventHub.trigger('updateCart', data)

      for (i = 0; i < data.length; i += 1) {
          if (data[i].track_id === itemId) {
              if (typeof plausible === 'function') {
                  plausible('track_add_cart', { props: {track_id: itemId, usage_license_type_id: usageLicenseTypeId, location: window.location.href}});
              }

              ahoy.track('track_add_cart', {track_id: itemId, usage_license_type_id: usageLicenseTypeId, location: window.location.href, categorization_processed: false})
          } else if (data[i].sound_effect_id === itemId) {
              if (typeof plausible === 'function') {
                  plausible('sound_effect_add_cart', { props: {sound_effect_id: itemId, usage_license_type_id: usageLicenseTypeId, location: window.location.href}});
              }

              ahoy.track('sound_effect_add_cart', {sound_effect_id: itemId, usage_license_type_id: usageLicenseTypeId, location: window.location.href, categorization_processed: false})
          }
      }

      if (typeof fbq === 'function') {
          fbq('track', 'AddToWishlist', {
              content_ids: itemId.toString(),
              content_type: 'product',
          });
      }

    }.bind(this));
  }

  handleCartClick(e) {
      e.preventDefault();
      this.props.handleCartClick(this.props.id);
  }

  getSelectedUsageLicenseTypeIds() {
      let ids = [],
          that = this,
          trackId = this.props.id || that.state.data.id,
          cart_items = this.state.cart_items || this.props.cart_items;

      cart_items.forEach(function(element) {
          if (element.track_id === trackId) {
              ids.push(element.usage_license_type_id);
          }
      });

      return ids;
  }

  render() {
      let cartLinkIcon,
          usageLicenseTypeSelect,
          cartLink,
          classes;

      usageLicenseTypeSelect = (
          <UsageLicenseTypeSelect
          locale={this.props.locale}
          addToCart={this.props.locale === 'de' ? 'zum Warenkorb hinzufügen' : 'add to cart'}
          free={this.props.free}
          discount={this.props.discount}
          subscribedUsageLicenseTypes={this.props.subscribedUsageLicenseTypes}
          usageLicenseTypes={this.props.usageLicenseTypes}
          updateCart={this.updateCart}
          pricing_path={this.props.pricing_path}
          i18n={this.props.i18n}
          visible={this.props.showUsageLicenseSelect}
          selectedUsageLicenseTypeIds={this.getSelectedUsageLicenseTypeIds()}
          position='cart'
          show_download_button={this.props.show_download_button} />
      );

      if (this.props.inCart) {
          if (this.props.show_download_button) {
          cartLinkIcon = <span className='track-favorites__cart-link-icon'><i className="fa fa-download"></i></span>;
          } else {
          cartLinkIcon = <span className='track-favorites__cart-link-icon'><i className="track-favorites__cart-link-icon-added fa fa-check"></i><i className="fa fa-shopping-cart track-favorites__cart-link-icon-cart"></i></span>;
          }
          cartLink = <a className="track-favorites__action-button-added-to-cart" href="#" onClick={this.handleCartClick}>{cartLinkIcon}{this.props.remove_from_cart}</a>
      } else {
          if (this.props.show_download_button) {
              cartLinkIcon = <span className='track-favorites__cart-link-icon'><i className="fa fa-download"></i></span>;
          } else {
              cartLinkIcon = <span className='track-favorites__cart-link-icon'><i className="track-favorites__cart-link-icon-add fa fa-plus"></i><i className="fa fa-shopping-cart track-favorites__cart-link-icon-cart"></i></span>;
          }
          cartLink = <a className="track-favorites__action-button-add-to-cart"  href="#" onClick={this.handleCartClick}>{cartLinkIcon}{this.props.add_to_cart}</a>
      }

      classes = classNames({
          'track-favorites__item': true,
          'track--active': this.props.active,
          'track-favorites__item--license-select-open': this.props.showUsageLicenseSelect
      });

      return (
        <div className={classes}>
          <div className="track-favorites__item-content">
            <a href="#" onClick={this.playTrack} className='track-favorites__item-play-icon'>
              <span>
                <i className={this.state.playing ? 'fa fa-pause' : 'fa fa-play' } />
              </span>
            </a>
            <div className='track-favorites__item-name'>
              <div className='track-favorites__item-title'>
                <span className='track-favorites__item-id'>{this.props.id}</span>
                {this.props.title}
              </div>
            </div>
            <a href="#" onClick={this.handleClick} className='track-favorites__item-remove'>
              <i className='fa fa-trash'></i>
            </a>
            <div className="track-favorites__cart-link">
              {cartLink}
            </div>
          </div>
          {usageLicenseTypeSelect}
        </div>
      )
  }
};

TrackFavorite.defaultProps = {
  cart_items : []
}

export default TrackFavorite;