
// import $ from 'jquery';
import ahoy from 'ahoy.js';
import LazyLoad from '~/modules/LazyLoad.js';
import CancellationPolicyForm from '~/modules/CancellationPolicyForm.js';
import CheckboxDropdown from '~/modules/CheckboxDropdown.js';
import Dropdown from '~/modules/Dropdown.js';
import ToggleLink from '~/modules/ToggleLink.js';
import PriceInfo from '~/modules/PriceInfo.js';
import ProductFetcher from '~/modules/ProductFetcher.js';
import Helpers from './Helpers';

const I18n = {};
window.I18n = I18n;

function getPeaks(data) {

  // What we're going to do here, is to divide up our audio into parts.

  // We will then identify, for each part, what the loudest sample is in that
  // part.

  // It's implied that that sample would represent the most likely 'beat'
  // within that part.

  // Each part is 0.5 seconds long - or 22,050 samples.

  // This will give us 60 'beats' - we will only take the loudest half of
  // those.

  // This will allow us to ignore breaks, and allow us to address tracks with
  // a BPM below 120.

  var partSize = 22050,
    parts = data[0].length / partSize,
    peaks = [];

  for (var i = 0; i < parts; i++) {
    var max = 0;
    for (var j = i * partSize; j < (i + 1) * partSize; j++) {
      var volume = Math.max(Math.abs(data[0][j]), Math.abs(data[1][j]));
      if (!max || (volume > max.volume)) {
        max = {
          position: j,
          volume: volume
        };
      }
    }
    peaks.push(max);
  }

  // We then sort the peaks according to volume...

  peaks.sort(function (a, b) {
    return b.volume - a.volume;
  });

  // ...take the loundest half of those...

  peaks = peaks.splice(0, peaks.length * 0.5);

  // ...and re-sort it back based on position.

  peaks.sort(function (a, b) {
    return a.position - b.position;
  });

  return peaks;
}

function getIntervals(peaks) {

  // What we now do is get all of our peaks, and then measure the distance to
  // other peaks, to create intervals.  Then based on the distance between
  // those peaks (the distance of the intervals) we can calculate the BPM of
  // that particular interval.

  // The interval that is seen the most should have the BPM that corresponds
  // to the track itself.

  var groups = [];

  peaks.forEach(function (peak, index) {
    for (var i = 1; (index + i) < peaks.length && i < 10; i++) {
      var group = {
        tempo: (60 * 44100) / (peaks[index + i].position - peak.position),
        count: 1
      };

      while (group.tempo < 90) {
        group.tempo *= 2;
      }

      while (group.tempo > 180) {
        group.tempo /= 2;
      }

      group.tempo = Math.round(group.tempo);

      if (!(groups.some(function (interval) {
        return (interval.tempo === group.tempo ? interval.count++ : 0);
      }))) {
        groups.push(group);
      }
    }
  });
  return groups;
}


const globalModule = {

  init: function () {
    // PUBLIC AREA
    this.initI18n();
    this.initAhoy();
    this.initMediaBundlePricePreviewForm();
    this.initLazyLoading();
    this.initDropdown();
    this.initCheckboxDropdown();
    this.addScrollToEventListener();
    this.addEventHandlerForGlobalCloseClick();
    this.initPwa();
    this.addEventListenerForNavSearch();
    this.initTabby();
    this.initToggleLink();
    this.addNavScrollEventListener();
    this.addFlashEventListener();
    this.initSort();
    this.initRadioSubscriptionPricingForm();

    if (document.querySelector('audio')) {
      global.EventHub.trigger('mountAudioPlayer');
      global.EventHub.trigger('mountSearchMenu');
      this.initCustomPlaylinks();
      this.addEventHandlerForPlayTracking();
      this.addEventHandlerForFavoriteTracking();
      this.addEventHandlerForSoundEffectFavoriteTracking();
      this.initElementCatapult();
    }

    this.addEventHandlerForSubscriptionSelection();
    this.addEventHandlerForPhoneMessageConfiguratorForm();
    this.addEventHandlerForOnePlayingAudioTagAtATime();

    this.initMultiStepForm();

    // EDITOR AREA
    this.addEventHandlerForCopyButton();
    this.initProductFetcher();

    // // CUSTOMER AREA
    this.initPriceInfo();
    this.addEventHandlerForCheckout();
    this.addEventHandlerForRegistrationEmailCheck();
    this.addEventHandlerForPhoneMessageOrderTemplateSelection();
    this.initCancellationPolicyForm();

    // // MUSICIAN AREA
    this.initDirectFileUpload();
    this.addEventHandlerForTrackFormSubmit();
    this.addEventHandlerForTrackOriginatorSwitch();
    this.addEventHandlerForMusicSourceSelect();
    this.addEventHandlerForVoiceSourceSelect();
    this.initOverlay();
    this.addEventHandlerForCustomTagInput();
  },

  initI18n: function () {
    I18n.locale = $('body').data('lang');
  },

  initProductFetcher: function() {
    let button = document.querySelector('[data-action~="fetchProductData"]');

    if (button) {
        new ProductFetcher(button, {});
    }
  },


  initMediaBundlePricePreviewForm: function() {
    let selectElements = document.querySelectorAll('.media-bundle-price-preview__select');
    let netPriceElements = document.querySelectorAll('.media-bundle-price-preview__target-net-price');
    let netPriceWithoutDiscountElements = document.querySelectorAll('.media-bundle-price-preview__target-net-price-without-discount');
  
    if (selectElements.length > 0) {
      selectElements.forEach(function(selectElement, index) {
        selectElement.addEventListener('change', function(e) {
          let selectedOption = selectElement.options[selectElement.selectedIndex];
          let netPrice = selectedOption.getAttribute('data-net-price');
          let netPriceWithoutDiscount = selectedOption.getAttribute('data-net-price-without-discount');
  
          // Update the corresponding net price elements
          let netPriceElement = netPriceElements[index];
          let netPriceWithoutDiscountElement = netPriceWithoutDiscountElements[index];
          netPriceElement.textContent = netPrice;
          netPriceWithoutDiscountElement.textContent = netPriceWithoutDiscount;
        });
      });
    }
  },

  addEventHandlerForPhoneMessageConfiguratorForm: function () {
    let form = document.querySelector('[data-action~=phoneMessageConfiguratorForm]');

    if (form) {
      form.addEventListener('change', function () {
        let options = form.querySelectorAll('input[type=radio]:checked');
        let speakerLabel = document.querySelector('[data-action~=phoneMessageConfiguratorFormSpeakerLabel]');
        let trackLabel = document.querySelector('[data-action~=phoneMessageConfiguratorFormTrackLabel]');
        let i = 0;
        let trackSelected = true;
        let speakerSelected = true;
        let optionsValid = true;

        for (i = 0; i < options.length; i++) {
          if (options[i].name === 'track_id' || options[i].name === 'phone_message_order[track_id]') {
            trackLabel.innerHTML = options[i].dataset.label;
          }
          if (options[i].name === 'speaker_id' || options[i].name === 'phone_message_order[speaker_id]') {
            speakerLabel.innerHTML = options[i].dataset.label;
          }

          if (options[i].id === 'speaker_id_0') {
            speakerSelected = false;
          }

          if (options[i].id === 'track_id_0') {
            trackSelected = false;
          }

          if (!speakerSelected || !trackSelected) {
            optionsValid = false;
          } else {
            optionsValid = true;
          }
        }

        if (optionsValid) {
          form.querySelector('input[type=submit]').removeAttribute('disabled');
        } else {
          form.querySelector('input[type=submit]').setAttribute('disabled', 'disabled');
        }
      });
    }
  },

  addEventHandlerForCopyButton: function() {
    let copyButtons = document.querySelectorAll('[data-action~=initCopyButton]'); 

    let copyContainers =  [];

    if (copyButtons) {
      for (i = 0; i < copyButtons.length; i++) {
        let copyContainer = document.querySelector(copyButtons[i].dataset.copyContainerSelector);
        
        if (copyContainer) {
          copyButtons[i].addEventListener('click', function(e) {
            e.preventDefault();
    
            navigator.clipboard.writeText(copyContainer.textContent);
          })
        }
      }
    }
  },

  addEventHandlerForOnePlayingAudioTagAtATime: function () {
    function onlyPlayOneIn(container) {
      container.addEventListener("play", function (event) {
        let audio_elements = container.getElementsByTagName("audio");
        let i = 0;

        for (i = 0; i < audio_elements.length; i++) {
          let audio_element = audio_elements[i];
          if (audio_element !== event.target) {
            audio_element.pause();
          }
        }
      }, true);
    }

    function connectCustomPlayButtons(container) {
      let playButtons = container.querySelectorAll('[data-action~=phoneMessageConfiguratorPlayButton]');
      let audioElements = container.getElementsByTagName("audio");

      playButtons.forEach(function (playButton) {
        playButton.querySelector('.phone-message-config-radio__play').style = 'display: inline;';
        playButton.querySelector('.phone-message-config-radio__pause').style = 'display: none;';
        playButton.addEventListener('click', function (event) {
          event.preventDefault();
          let audioElement = playButton.parentNode.parentNode.parentNode.getElementsByTagName("audio")[0];
          let i = 0;
          console.log('click');
          console.log(audioElement);
          console.log(audioElements);
          if (audioElement.paused) {
            for (i = 0; i < audioElements.length; i++) {
              audioElements[i].pause();
              audioElements[i].parentNode.querySelector('.phone-message-config-radio__play').style = 'display: inline;';
              audioElements[i].parentNode.querySelector('.phone-message-config-radio__pause').style = 'display: none;';
            }

            if (audioElement.dataset.volume) {
              audioElement.volume = parseFloat(audioElement.dataset.volume);
            }

            audioElement.play();
            playButton.querySelector('.phone-message-config-radio__play').style = 'display: none;';
            playButton.querySelector('.phone-message-config-radio__pause').style = 'display: inline;';
          } else {
            console.log('audioElement trigger pause');
            audioElement.pause();
            playButton.querySelector('.phone-message-config-radio__play').style = 'display: inline;';
            playButton.querySelector('.phone-message-config-radio__pause').style = 'display: none;';
          }
        });
      })
    }

    let containers = document.querySelectorAll('[data-action~=audioPlayGroup]');

    containers.forEach(function (element) {
      onlyPlayOneIn(element);
      connectCustomPlayButtons(element);
    });


    // let phoneMessageConfiguratorPlayButtons = document.querySelector
  },

  initAhoy: function () {
    ahoy.configure({
      cookies: false
    });
    ahoy.debug();
  },

  addNavScrollEventListener: function () {
    let navElement = document.querySelector('[data-action~="nav-transparent-present"]');
    let visibleState = false;

    if (navElement) {
      if (window.scrollY > 50) {
        navElement.classList.remove('nav--transparent');
      }

      document.addEventListener('scroll', function () {
        if (window.scrollY > 50 && visibleState === false) {
          navElement.classList.remove('nav--transparent');
          visibleState = true;
        }

        if (window.scrollY <= 50 && visibleState === true) {
          navElement.classList.add('nav--transparent');
          visibleState = false;
        }
      })
    }
  },

  addEventHandlerForSubscriptionSelection: function () {

    $('[data-action~=chooseSubscription]').on('click', function (e) {
      $(this).find('input[type="radio"]').attr("checked", true);
      $(this).closest('form').submit();
    });

  },

  initRadioSubscriptionPricingForm: function() {
    $('[data-action~=initRadioSubscriptionPricingForm]').on('change', function (e) {
      $(this).closest('form').submit();
    });

  },

  addFlashEventListener: function () {
    let flashElement = document.querySelector('[data-action~="initFlash"]');

    if (flashElement) {
      let closeButton = flashElement.querySelector('[data-action-flash~="close"]');

      if (closeButton) {
        closeButton.addEventListener('click', function (e) {
          e.preventDefault();
          flashElement.classList.add('flash--hidden');
        });

        setTimeout(function () {
          flashElement.classList.add('flash--hidden');
        }, 5000)
      }
      // flashElement.classList.add('flash--hidden');
    }
  },

  initToggleLink: function () {
    (function () {
      var linkNodes = document.querySelectorAll('[data-action~="initMenuSearchSwitch"]'),
        i,
        linkNodesLength = linkNodes.length;

      for (i = 0; i < linkNodesLength; i += 1) {
        new ToggleLink(linkNodes[i], {
          targetToggleClass: 'nav__search-form-wrapper--visible',
          afterToggle: function () {
            var form = document.querySelector('#header-menu-search-form-wrapper');
            if (form.classList.contains('nav__search-form-wrapper--visible')) {
              form.querySelector('input').focus();
            }
          }
        });
      }
    }());

    (function () {
      var linkNodes = document.querySelectorAll('[data-action~="initMenuBurgerSwitch"]'),
        i,
        linkNodesLength = linkNodes.length;

      for (i = 0; i < linkNodesLength; i += 1) {
        new ToggleLink(linkNodes[i], {
          targetToggleClass: 'overlay-menu--visible',
          lockBodyScrolling: true
        });
      }
    }());
  },

  initPwa: function () {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.register('/service-worker.js');
    }
  },

  initLazyLoading: function () {
    var lazyLoader = new LazyLoad();
    window.Audiocrowd.lazyLoader = lazyLoader;
  },

  initDropdown: function () {
    var dropdownNodes = document.querySelectorAll('[data-action~="initDropdown"]'),
      i,
      dropdownNodesLength = dropdownNodes.length;

    for (i = 0; i < dropdownNodesLength; i += 1) {
      new Dropdown(dropdownNodes[i]);
    }
  },

  initCheckboxDropdown: function () {
    var dropdownNodes = document.querySelectorAll('[data-action~="initCheckboxDropdown"]'),
      i,
      dropdownNodesLength = dropdownNodes.length;

    for (i = 0; i < dropdownNodesLength; i += 1) {
      new CheckboxDropdown(dropdownNodes[i]);
    }
  },

  addEventHandlerForTrackOriginatorSwitch: function () {
    let inputElement = $('[data-action~=initTrackOriginatorSwitch] input')[0];

    if (inputElement && inputElement.checked) {
      $('[data-originator-content]').hide();
    }

    $('[data-action~=initTrackOriginatorSwitch]').on('change', 'input', function (e) {
      e.preventDefault();
      $('[data-originator-content]').toggle();
    });

  },

  addEventHandlerForMusicSourceSelect: function () {
    let inputElement = document.querySelector('[data-action~=initMusicSourceSelect]'),
        audiocrowdComposerIdInput = document.querySelector('[data-action~=initMusicSourceSelectAudiocrowdComposerId]'),
        audiocrowdTrackIdInput = document.querySelector('[data-action~=initMusicSourceSelectAudiocrowdTrackId]'),
        externalComposerInfoInput = document.querySelector('[data-action~=initMusicSourceSelectExternalComposerInfo]');




    if (inputElement) {
      if (inputElement.value !== 'audiocrowd') {
        if (audiocrowdComposerIdInput && audiocrowdComposerIdInput.value === '') {
          audiocrowdComposerIdInput.parentElement.classList.add('hide-element');
        }
    
        if (audiocrowdTrackIdInput && audiocrowdTrackIdInput.value === '') {
          audiocrowdTrackIdInput.parentElement.classList.add('hide-element');
        }
      }

      if (inputElement.value !== 'external') {
        if (externalComposerInfoInput && externalComposerInfoInput.value === '') {
          externalComposerInfoInput.parentElement.classList.add('hide-element');
        }
      }
  



      inputElement.addEventListener('change', function(e) {
        if (e.target.value === 'audiocrowd') {
          audiocrowdComposerIdInput.parentElement.classList.remove('hide-element');
          audiocrowdTrackIdInput.parentElement.classList.remove('hide-element');
        } else {
          audiocrowdComposerIdInput.parentElement.classList.add('hide-element');
          audiocrowdTrackIdInput.parentElement.classList.add('hide-element');
        }

        if (e.target.value === 'external') {
          externalComposerInfoInput.parentElement.classList.remove('hide-element');
        } else {
          externalComposerInfoInput.parentElement.classList.add('hide-element');
        }
      })
    }
  },

  addEventHandlerForVoiceSourceSelect: function () {
    let inputElement = document.querySelector('[data-action~=initVoiceSourceSelect]'),
        audiocrowdComposerIdInput = document.querySelector('[data-action~=initVoiceSourceSelectAudiocrowdSpeakerId]'),
        externalComposerInfoInput = document.querySelector('[data-action~=initVoiceSourceSelectExternalSpeakerInfo]');


    if (audiocrowdComposerIdInput && audiocrowdComposerIdInput.value === '') {
      audiocrowdComposerIdInput.parentElement.classList.add('hide-element');
    }

    if (externalComposerInfoInput && externalComposerInfoInput.value === '') {
      externalComposerInfoInput.parentElement.classList.add('hide-element');
    }

    if (inputElement) {
      inputElement.addEventListener('change', function(e) {
        if (e.target.value === 'audiocrowd') {
          audiocrowdComposerIdInput.parentElement.classList.remove('hide-element');
        } else {
          audiocrowdComposerIdInput.parentElement.classList.add('hide-element');
        }

        if (e.target.value === 'external') {
          externalComposerInfoInput.parentElement.classList.remove('hide-element');
        } else {
          externalComposerInfoInput.parentElement.classList.add('hide-element');
        }
      })
    }
  },

  initCustomPlaylinks: function () {
    $('[data-action~=playTrack]').on('click', function (e) {
      let id = $(this).attr('data-play-track-id');

      e.preventDefault();

      global.EventHub.trigger('playTrack', id);
    });

    $('[data-action~=playTrackVariant]').on('click', function (e) {
      let id = $(this).attr('data-play-track-variant-id');

      e.preventDefault();

      global.EventHub.trigger('playTrackVariant', id);
    });

    $('[data-action~=playSoundEffect]').on('click', function (e) {
      let id = $(this).attr('data-play-sound-effect-id');

      e.preventDefault();

      global.EventHub.trigger('playSoundEffect', id);
    });

    $('[data-action~=playSpeakerAudio]').on('click', function (e) {
      let id = $(this).attr('data-play-speaker-audio-id');

      $('[data-action~=playSpeakerAudio]').removeClass('speaker-audio--active');
      $(this).addClass('speaker-audio--active');

      e.preventDefault();



      global.EventHub.trigger('playSpeakerAudio', id);
    });

    $('[data-action~=playVoiceRecording]').on('click', function (e) {
      var id = $(this).attr('data-play-voice-recording-id');

      e.preventDefault();
      global.EventHub.trigger('playVoiceRecording', id);
    });

  },

  initSort: function () {
    sortable('#sortable', {
      items: 'tr'
    });

    if (typeof sortable('#sortable')[0] != 'undefined') {
      sortable('#sortable')[0].addEventListener('sortupdate', function (e) {

        var elementsArray = Array.prototype.slice.call(this.children);

        var ids = [];

        elementsArray.forEach(function (element) {
          ids.push(element.dataset.id);
        })

        var dataList = ids.map(function (id) {
          return "sortable_element[]=" + id;
        }).join("&")

        console.log(dataList);

        $.ajax({
          url: $(this).data("url"),
          type: "PATCH",
          data: dataList
        });
      });
    }
  },


  addEventHandlerForCheckout: function () {
    $('[data-action~=removeTrackFromNewCheckout]').on('click', function (e) {
      e.preventDefault();
      $.ajax({
        url: "/cart?track_id=" + $(this).data('track-id'),
        type: 'POST'
      }).done(function (data) {
        let env = NODE_ENV === 'production' ? 'production' : 'development';
        let checkoutUrl = env === 'development' ? 'http://' + I18n.locale + '.localhost.dev:3000/c/checkouts/new' : 'https://' + I18n.locale + '.audiocrowd.net/c/checkouts/new';
        window.location.href = checkoutUrl;
      }.bind(this))
    });

    $('[data-action~=removeTrackFromExistingCheckout]').on('click', function (e) {
      e.preventDefault();
      $.ajax({
        url: "/c/checkouts/" + $(this).data('checkout-id') + "/remove_license?id=" + $(this).data('checkout-id') + '&usage_license_id=' + $(this).data('usage-license-id'),
        type: 'POST'
      }).done(function (data) {
        let env = NODE_ENV === 'production' ? 'production' : 'development';
        let checkoutUrl = env === 'development' ? 'http://' + I18n.locale + '.localhost.dev:3000/c/checkouts/' + $(this).data('checkout-id') + '/edit_selection' : 'https://' + I18n.locale + '.audiocrowd.net/c/checkouts/' + $(this).data('checkout-id') + '/edit_selection';
        window.location.href = checkoutUrl;
      }.bind(this))
    });
  },

  addEventHandlerForPhoneMessageOrderTemplateSelection: function () {
    let parentContainer = document.querySelector('[data-action~=initPhoneOrderTemplateSelection]');

    if (parentContainer) {
      parentContainer.addEventListener('change', function (event) {
        let target = event.target,
          selectNodesInItem,
          selectedSelect,
          textareaInItem,
          notSelectedSelect;

        if (target.nodeName === 'SELECT') {
          selectNodesInItem = target.parentNode.parentNode.parentNode.parentNode.querySelectorAll('select');
          textareaInItem = target.parentNode.parentNode.parentNode.parentNode.querySelector('textarea');
          selectedSelect = target;

          for (var i = 0; i < selectNodesInItem.length; i += 1) {
            if (selectNodesInItem[i] !== selectedSelect) {
              notSelectedSelect = selectNodesInItem[i];
            }
          }

          textareaInItem.innerHTML = selectedSelect.options[selectedSelect.selectedIndex].innerHTML.replace(/#[\d,_A-Z]+\s/i, '');
          notSelectedSelect.selectedIndex = 0;
        }

      });

      if (parentContainer) {
        let form = document.querySelector('form[id*="edit_phone_message_order"]');

        form.querySelector('input[type=submit]').addEventListener('click', function (event) {
          event.preventDefault();
          let selectNodes = parentContainer.querySelectorAll('select');

          for (var j = 0; j < selectNodes.length; j += 1) {
            if (selectNodes[j].selectedIndex === 0) {
              selectNodes[j].remove();
              // selectNodes[j].disabled = true;
            } else {
              // selectNodes[j].disabled = false;
            }
          }

          form.submit();
        });
      }
    }
  },

  initCancellationPolicyForm: function() {
    let cancellationPolicyElement = document.querySelector('[data-action~="initCancellationPolicy"]');

    if (cancellationPolicyElement) {
      let cancellationPolicyForm = new CancellationPolicyForm(cancellationPolicyElement);
    }
  },

  addScrollToEventListener: function () {
    $('a[data-action~="scrollTo"]').on('click', function (e) {
      let href = $(this).attr('href');

      $('html, body').animate({
        scrollTop: $(href).offset().top - 200
      }, 'slow');

      e.preventDefault();
    });
  },

  addEventHandlerForPlayTracking: function () {
    global.EventHub.on('audioPlayerPlay', function () {
      var trackData = arguments[0][0],
        shouldTrack = $('[data-logged-in]').length === 0,
        freeTrackingMode = $('[data-free-tracking-mode]').length > 0;

      if (trackData && trackData.id) {
        if (shouldTrack && !freeTrackingMode) {
          if (typeof plausible === 'function') {
            plausible('track_play', { props: { track_id: trackData.id, title: trackData.title, location: window.location.href } });
          }

          ahoy.track('track_play', { track_id: trackData.id, title: trackData.title, location: window.location.href, categorization_processed: false })
        } else if (shouldTrack && freeTrackingMode) {
          if (typeof plausible === 'function') {
            plausible('track_play_free', { props: { track_id: trackData.id, title: trackData.title, location: window.location.href } });
          }

          ahoy.track('track_play_free', { track_id: trackData.id, title: trackData.title, location: window.location.href, categorization_processed: false })
        }
      }

    });
  },

  addEventListenerForNavSearch: function () {
    let searchInput = document.querySelector('.nav__search-input');
    let searchInputWrapper = document.querySelector('.nav__content');

    if (searchInput && searchInputWrapper) {
      searchInput.addEventListener('focus', function () {
        searchInputWrapper.classList.add('nav__content--search-active');
      });

      searchInput.addEventListener('blur', function () {
        searchInputWrapper.classList.remove('nav__content--search-active');
      });
    }
  },

  addEventHandlerForFavoriteTracking: function () {
    global.EventHub.on('trackFavorited', function () {
      let trackId = arguments[0][0],
        shouldTrack = $('[data-logged-in]').length === 0,
        freeTrackingMode = $('[data-free-tracking-mode]').length > 0;

      if (trackId) {
        if (shouldTrack && !freeTrackingMode) {
          if (typeof plausible === 'function') {
            plausible('track_favorite', { props: { track_id: trackId, location: window.location.href } });
          }
          ahoy.track('track_favorite', { track_id: trackId, location: window.location.href, categorization_processed: false })
        } else if (shouldTrack && freeTrackingMode) {
          if (typeof plausible === 'function') {
            plausible('track_favorite_free', { props: { track_id: trackId, location: window.location.href } });
          }

          ahoy.track('track_favorite_free', { track_id: trackId, location: window.location.href, categorization_processed: false })
        }
      }
    });
  },

  addEventHandlerForSoundEffectFavoriteTracking: function () {
    global.EventHub.on('soundEffectFavorited', function () {
      let sound_effect_id = arguments[0][0],
        shouldTrack = $('[data-logged-in]').length === 0,
        freeTrackingMode = $('[data-free-tracking-mode]').length > 0;

      if (sound_effect_id) {
        if (shouldTrack && !freeTrackingMode) {
          if (typeof plausible === 'function') {
            plausible('sound_effect_favorite', { props: { track_id: sound_effect_id, location: window.location.href } });
          }
          ahoy.track('sound_effect_favorite', { track_id: sound_effect_id, location: window.location.href, categorization_processed: false })
        } else if (shouldTrack && freeTrackingMode) {
          if (typeof plausible === 'function') {
            plausible('sound_effect_favorite_free', { props: { track_id: sound_effect_id, location: window.location.href } });
          }

          ahoy.track('sound_effect_favorite_free', { track_id: sound_effect_id, location: window.location.href, categorization_processed: false })
        }
      }
    });
  },

  initPriceInfo: function () {
    let priceInfo = new PriceInfo('[data-action~="initPriceInfo"]', {
      currency: I18n.locale === 'de' ? '€' : '$'
    });
  },

  addEventHandlerForCustomTagInput: function () {
    let $input = $('[data-action~=initCustomTagInput]'),
      availableTags;

    function split(val) {
      return val.split(/,\s*/);
    }

    function extractLast(term) {
      return split(term).pop();
    }

    if ($input.length > 0) {
      availableTags = $input.data('autocompletion-suggestions').split(', ');
    }


    $('[data-action~=initCustomTagInput]')
      // don't navigate away from the field on tab when selecting an item
      .bind("keydown", function (event) {
        if (event.keyCode === $.ui.keyCode.TAB &&
          $(this).autocomplete("instance").menu.active) {
          event.preventDefault();
        }
      })
      .autocomplete({
        minLength: 0,
        source: function (request, response) {
          // delegate back to autocomplete, but extract the last term
          response($.ui.autocomplete.filter(
            availableTags, extractLast(request.term)).slice(0, 20));

        },
        focus: function () {
          // prevent value inserted on focus
          return false;
        },
        select: function (event, ui) {
          var terms = split(this.value);
          // remove the current input
          terms.pop();
          // add the selected item
          terms.push(ui.item.value);
          // add placeholder to get the comma-and-space at the end
          terms.push("");
          this.value = terms.join(", ");
          return false;
        }
      });
  },


  addEventHandlerForTrackFormSubmit: function () {
    let that = this;

    $('.track-form form').on('submit', function () {
      var message = $(this).closest('.track-form').attr('data-upload-text');
      var heading = $(this).closest('.track-form').attr('data-upload-heading');

      that.showOverlay({
        'heading': heading,
        'message': message,
        'icon': 'fa-cog fa-spin'
      });
    });
  },

  showOverlay: function (options) {
    var $overlay = $('.overlay-wrapper');

    if (options === undefined) {
      options = {};
    }

    if (options['icon'] === undefined) {
      options['icon'] = 'fa-info';
    }

    if (options['heading'] === undefined) {
      // TODO: Implement localization
      options['heading'] = "Please wait";
    }

    if (options['message'] === undefined) {
      // TODO: Implement localization
      options['message'] = "Please wait";
    }

    if ($overlay.length === 0) {
      this.initOverlay();
      $overlay = $('.overlay-wrapper');
    }
    $('.overlay-message').text(options['message']);
    $('.overlay-heading').text(options['heading']);

    $overlay.find('.overlay-icon i')[0].className = '';
    $overlay.find('.overlay-icon i').addClass('fa ' + options['icon']);

    $overlay.fadeIn(200);
  },

  hideOverlay: function () {
    $('.overlay-wrapper').fadeOut(200);
  },

  initOverlay: function () {
    $('<div class="overlay-wrapper"><div class="overlay-message-wrapper"><p class="overlay-icon"><i class="fa"></i></p><h2 class="overlay-heading"></h2><p class="overlay-message"></p></div></div>').appendTo('body');
  },

  addEventHandlerForSearchForm: function () {
    $('[data-action~=trackSearchForm]').on('submit', function (e) {
      e.preventDefault();
    });
  },

  addEventHandlerForGlobalCloseClick: function () {
    let element = document.querySelector('[data-action~=global-close-click]');
    element.addEventListener('click', function (e) {
      let usageLicenseTypeSelectCondition = !(e.target.classList.contains('usage-license-type-select') || $(e.target).parents('.usage-license-type-select').length > 0),
        addToCartCondition = !(e.target.classList.contains('track__cart-link') || $(e.target).parents('.track__cart-link').length > 0),
        addToCartLinkCondition = !(e.target.classList.contains('track__action-button-add-to-cart') || $(e.target).parents('.track__action-button-add-to-cart').length > 0),
        cartCondition = !(e.target.classList.contains('cart') || $(e.target).parents('.cart').length > 0),
        trackFavoritesCondition = !(e.target.classList.contains('track-favorites') || $(e.target).parents('.track-favorites').length > 0),
        searchTabCondition = !(e.target.classList.contains('search-tab') || $(e.target).parents('.search-tab').length > 0),
        freeDownloadCondition = !(e.target.classList.contains('track__free-download') || $(e.target).parents('.track__free-download').length > 0),
        freeDownloadDialogCondition = !(e.target.classList.contains('track__free-download-dialog-wrapper') || $(e.target).parents('.track__free-download-dialog-wrapper').length > 0),
        checkboxDropdownCondition = !(e.target.classList.contains('checkbox-dropdown') || $(e.target).parents('.checkbox-dropdown').length > 0),
        dropdownCondition = !(e.target.classList.contains('dropdown') || $(e.target).parents('.dropdown').length > 0);

      if (usageLicenseTypeSelectCondition && addToCartCondition && addToCartLinkCondition && cartCondition && trackFavoritesCondition && searchTabCondition && freeDownloadCondition && freeDownloadDialogCondition && checkboxDropdownCondition && dropdownCondition) {
        global.EventHub.trigger('closePopupMenus');
      }
    })
  },

  initDirectFileUpload: function () {

    $('.directUpload').find('input:file').each(function (i, elem) {
      if (!elem.classList.contains('directUploadIgnore')) {
        this.initDirectFileUploadElement(elem, true);

        // var fileInput = $(elem);
        // var form = $(fileInput.parents('form:first'));
        // var submitButton = form.find('input[type="submit"]');
        // var progressBar = $('<div class="bar"></div>');
        // var barContainer = $('<div class="progress"></div>').append(progressBar);
        // fileInput.after(barContainer);
        // fileInput.fileupload({
        //     fileInput: fileInput,
        //     url: form.data('url'),
        //     type: 'POST',
        //     autoUpload: true,
        //     formData: form.data('form-data'),
        //     paramName: 'file', // S3 does not like nested name fields i.e. name="user[avatar_url]"
        //     dataType: 'XML',  // S3 returns XML if success_action_status is set to 201
        //     replaceFileInput: false,
        //     progressall: function (e, data) {
        //         var progress = parseInt(data.loaded / data.total * 100, 10);
        //         progressBar.css('width', progress + '%');
        //     },
        //     start: function () {
        //         submitButton.prop('disabled', true);

        //         progressBar.
        //             css('background', 'green').
        //             css('display', 'block').
        //             css('width', '0%').
        //             text('Uploading...');
        //     },
        //     done: function (e, data) {
        //         submitButton.prop('disabled', false);
        //         progressBar.text('Uploading done');

        //         // extract key and generate URL from response
        //         var key = $(data.jqXHR.responseXML).find('Key').text();
        //         var url = '//' + form.data('host') + '/' + key;

        //         // create hidden field
        //         var input = $('<input />', { type: 'hidden', name: fileInput.attr('name'), value: url });
        //         form.append(input);

        //         // remove file upload element to avoid duplicate upload time
        //         fileInput.fadeOut(1000, function () { fileInput.remove(); });
        //     },
        //     fail: function (e) {
        //         submitButton.prop('disabled', false);

        //         progressBar.
        //             css('background', 'red').
        //             text('Upload failed');
        //     }
        // });

      }
    }.bind(this));

    $(document).on('nested:fieldAdded', function (event) {
      var field = event.field;
      if (field.closest('form')[0].classList.contains('directUpload') && !field.closest('form')[0].classList.contains('directUpload--bulk-tracks')) {
        var inputElement = field[0].querySelector('input[type="file"]')
        this.initDirectFileUploadElement(inputElement, false);
      } else if (field.closest('form')[0].classList.contains('directUpload') && field.closest('form')[0].classList.contains('directUpload--bulk-tracks')) {
        var inputElement = field[0].querySelector('input[type="file"]')
        this.initDirectFileUploadElement(inputElement, true);
      }

      // Auto fill position
      var currentPositionInput = field[0].querySelector('input[type=number][name$="[position]"]');
      var formerPositionInput = field[0].previousElementSibling && field[0].previousElementSibling.querySelector('input[type=number][name$="[position]"]');

      if (currentPositionInput && currentPositionInput.value.length === 0) {
        if (formerPositionInput) {
          if (formerPositionInput.value.length === 0) {
            currentPositionInput.value = 1;
          } else {
            currentPositionInput.value = parseInt(formerPositionInput.value, 10) + 1;
          }
        } else {
          currentPositionInput.value = 1;
        }
      }

    }.bind(this));
  },

  initDirectFileUploadElement: function (fileInputElement, bulkUploadMode) {
    var fileInput = $(fileInputElement);
    var form = $(fileInput.parents('form:first'));
    var submitButton = form.find('input[type="submit"]');
    var progressBar = $('<div class="bar"></div>');
    var barContainer = $('<div class="progress"></div>').append(progressBar);
    window.formDataAttributes = form.data('form-data');

    fileInput.after(barContainer);
    fileInput.fileupload({
      fileInput: fileInput,
      url: form.data('url'),
      type: 'POST',
      autoUpload: true,
      formData: formDataAttributes,
      paramName: 'file', // S3 does not like nested name fields i.e. name="user[avatar_url]"
      dataType: 'XML',  // S3 returns XML if success_action_status is set to 201
      replaceFileInput: false,
      progressall: function (e, data) {
        var progress = parseInt(data.loaded / data.total * 100, 10);
        progressBar.css('width', progress + '%');
      },

      add: function(e, data){
        console.log('directFileUpload this:', this);
        console.log('directFileUpload this.value:', this.value);

        let fileName = this.value.split(/[\\]+/).pop();
        console.log('directFileUpload fileName:', fileName);

        if (typeof fileName === 'undefined' || fileName.length == 0) {
          fileName = 'fallback_filename_' + Math.random().toString(36).slice(2, 7) + '.wav'
          console.log('directFileUpload fallbackFileName:', fileName);
        }

        let sanitizedFileName = Helpers.sanitizeFileName(fileName);
        console.log('directFileUpload sanitizedFileName:', sanitizedFileName);

        let initialKey = formDataAttributes.key.replace('${filename}', sanitizedFileName);
        formDataAttributes.key = initialKey;

        console.log('initialKey', initialKey);

        let newKey = formDataAttributes.key.replace(/\/([A-Za-z0-9])\w+.wav/, '/' + sanitizedFileName);
        formDataAttributes.key = newKey;

        console.log('newKey', newKey);

        data.formData = formDataAttributes;
        console.log('data:', data);
        data.submit();
      },
      start: function () {
        submitButton.prop('disabled', true);

        progressBar.
          css('background', 'green').
          css('display', 'block').
          css('width', '0%').
          text('Uploading...');
      },
      done: function (e, data) {
        var file = data.files[0];

        console.log('directFileUpload done file:', file);

        submitButton.prop('disabled', false);
        progressBar.text(file['name']);

        if (bulkUploadMode) {

          // var previewUrl = track.preview_url;
          // audioTag.src = track.preview_url;


          if (file['type'] === 'audio/mpeg' || file['type'] === 'audio/wav') {
            setTimeout(function () {
              var urls = document.querySelectorAll('input[type=hidden][name$="[s3_file_upload_url]"');
              var url = urls[urls.length - 1].value;

              var audioElement = document.createElement('audio');
              audioElement.setAttribute('controls', '');
              var sourceElement = document.createElement('source');
              sourceElement.setAttribute('src', url);
              audioElement.appendChild(sourceElement);

              progressBar[0].parentNode.parentNode.appendChild(audioElement);
              // barContainer[0].style.display = 'none';
              barContainer[0].previousElementSibling.style.display = 'none';  // label
              barContainer[0].nextElementSibling.style.display = 'none'; // svg

            }, 2000)


            // album[bulk_track_uploads_attributes][1625863844504][s3_file_upload_url]








            // get length
            // Obtain the uploaded file, you can change the logic if you are working with multiupload
            var file = data.files[0];

            // Create instance of FileReader
            var reader = new FileReader();

            // When the file has been succesfully read
            reader.onload = function (event) {


              // (function() {
              //     // LENGTH DETECTION

              //     // Create an instance of AudioContext
              //     var audioContext = new (window.AudioContext || window.webkitAudioContext)();

              //     // Asynchronously decode audio file data contained in an ArrayBuffer.
              //     audioContext.decodeAudioData(event.target.result, function(buffer) {
              //         // Obtain the duration in seconds of the audio file (with milliseconds as well, a float value)
              //         var duration = buffer.duration;
              //         var minutes = Math.floor(duration / 60);
              //         var seconds = Math.floor(duration - minutes * 60);
              //         // example 12.3234 seconds

              //         console.log("The duration of the song is of: " + minutes + ":" + seconds);

              //         var titleInput = fileInput.closest('.fields')[0] ? fileInput.closest('.fields')[0].querySelector('input[name$="[title]"]') : undefined;
              //         var minutesInput = fileInput.closest('.fields')[0] ? fileInput.closest('.fields')[0].querySelector('input[name$="[minutes]"]') : undefined;
              //         var secondsInput = fileInput.closest('.fields')[0] ? fileInput.closest('.fields')[0].querySelector('input[name$="[seconds]"]') : undefined;

              //         if (minutesInput && secondsInput) {
              //             minutesInput.value = minutes;
              //             secondsInput.value = seconds;
              //         }

              //         if (titleInput && titleInput.value.length === 0) {
              //             titleInput.value = file['name'];
              //         }
              //         // Alternatively, just display the integer value with
              //         // parseInt(duration)
              //         // 12 seconds

              //         // In case that the file couldn't be read

              //     });
              // }())




              (function () {
                var OfflineContext = window.OfflineAudioContext || window.webkitOfflineAudioContext;
                var offlineContext = new OfflineContext(2, 30 * 44100, 44100);

                offlineContext.decodeAudioData(event.target.result, function (buffer) {


                  // LENGTH DETECTION
                  var duration = buffer.duration;
                  // var minutes = Math.floor(duration / 60);
                  // var seconds = Math.floor(duration - minutes * 60);
                  // example 12.3234 seconds

                  var titleInput = barContainer.closest('.fields')[0] ? barContainer.closest('.fields')[0].querySelector('input[name$="[title]"]') : undefined;
                  var lengthInput = barContainer.closest('.fields')[0] ? barContainer.closest('.fields')[0].querySelector('input[name$="[length]"]') : undefined;
                  // var minutesInput = fileInput.closest('.fields')[0] ? fileInput.closest('.fields')[0].querySelector('input[name$="[minutes]"]') : undefined;
                  // var secondsInput = fileInput.closest('.fields')[0] ? fileInput.closest('.fields')[0].querySelector('input[name$="[seconds]"]') : undefined;

                  if (lengthInput) {
                    lengthInput.value = Math.floor(duration);
                  }

                  if (titleInput && titleInput.value.length === 0) {
                    var sanitizedTitle = file['name'].replace(/-/g, ' ').replace(/.mp3/g, '').replace(/.wav/g, '').replace(/.aiff/g, '').replace(/.aif/g, '').replace(/_/g, ' ');

                    var capitalisedTitleArray = sanitizedTitle.split(" ");

                    //loop through each element of the array and capitalize the first letter.


                    for (var i = 0; i < capitalisedTitleArray.length; i++) {
                      capitalisedTitleArray[i] = capitalisedTitleArray[i].charAt(0).toUpperCase() + capitalisedTitleArray[i].slice(1);

                    }

                    //Join all the elements of the array back into a string
                    //using a blankspace as a separator
                    var capitalisedTitle = capitalisedTitleArray.join(" ");

                    titleInput.value = capitalisedTitle;
                  }




                  // BPM DETECTION

                  // Create buffer source
                  var source = offlineContext.createBufferSource();
                  source.buffer = buffer;

                  // Beats, or kicks, generally occur around the 100 to 150 hz range.
                  // Below this is often the bassline.  So let's focus just on that.

                  // First a lowpass to remove most of the song.

                  var lowpass = offlineContext.createBiquadFilter();
                  lowpass.type = "lowpass";
                  lowpass.frequency.value = 150;
                  lowpass.Q.value = 1;

                  // Run the output of the source through the low pass.

                  source.connect(lowpass);

                  // Now a highpass to remove the bassline.

                  var highpass = offlineContext.createBiquadFilter();
                  highpass.type = "highpass";
                  highpass.frequency.value = 100;
                  highpass.Q.value = 1;

                  // Run the output of the lowpass through the highpass.

                  lowpass.connect(highpass);

                  // Run the output of the highpass through our offline context.

                  highpass.connect(offlineContext.destination);

                  // Start the source, and render the output into the offline conext.

                  source.start(0);
                  offlineContext.startRendering();
                });

                offlineContext.oncomplete = function (e) {
                  var buffer = e.renderedBuffer;
                  var peaks = getPeaks([buffer.getChannelData(0), buffer.getChannelData(1)]);
                  var groups = getIntervals(peaks);

                  var svg = document.querySelector('#svg');
                  svg.innerHTML = '';
                  var svgNS = 'http://www.w3.org/2000/svg';
                  var rect;
                  peaks.forEach(function (peak) {
                    rect = document.createElementNS(svgNS, 'rect');
                    rect.setAttributeNS(null, 'x', (100 * peak.position / buffer.length) + '%');
                    rect.setAttributeNS(null, 'y', 0);
                    rect.setAttributeNS(null, 'width', 1);
                    rect.setAttributeNS(null, 'height', '100%');
                    svg.appendChild(rect);
                  });

                  rect = document.createElementNS(svgNS, 'rect');
                  rect.setAttributeNS(null, 'id', 'progress');
                  rect.setAttributeNS(null, 'y', 0);
                  rect.setAttributeNS(null, 'width', 1);
                  rect.setAttributeNS(null, 'height', '100%');
                  svg.appendChild(rect);

                  svg.innerHTML = svg.innerHTML; // force repaint in some browsers

                  var top = groups.sort(function (intA, intB) {
                    return intB.count - intA.count;
                  }).splice(0, 5);

                  var tempoInput = barContainer.closest('.fields')[0] ? barContainer.closest('.fields')[0].querySelector('input[name$="[tempo]"]') : undefined;

                  if (tempoInput && tempoInput.value.length === 0 && top.length > 0) {
                    tempoInput.value = top[0]['tempo']
                  }
                };
              }())
            };

            reader.onerror = function (event) {
              console.error("An error ocurred reading the file: ", event);
            };

            // Read file as an ArrayBuffer, important !
            reader.readAsArrayBuffer(file);

          }

        }








        // extract key and generate URL from response
        var key = $(data.jqXHR.responseXML).find('Key').text();
        var url = '//' + form.data('host') + '/' + key;

        console.log('key: ', key);
        console.log('url: ', url);
        console.log('file:', file)

        debugger

        // create hidden field
        var input = $('<input />', { type: 'hidden', name: fileInput.attr('name'), value: url });
        form.append(input);

        console.log('directFileUpload hidden_input:', input[0]);

        debugger

        // remove file upload element to avoid duplicate upload time
        fileInput.fadeOut(1000, function () { fileInput.remove(); });
      },
      fail: function (e) {
        submitButton.prop('disabled', false);

        progressBar.
          css('background', 'red').
          text('Upload failed');
      }
    });
  },

  addEventHandlerForRegistrationEmailCheck: function() {
    let registrationForm = document.querySelector('.registration-form');

    if (registrationForm) {
      registrationForm.addEventListener('submit', function(event) {
        let email = registrationForm.querySelector('input[type~=email]');
        let emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      
        if (!emailRegex.test(email.value)) {
          event.preventDefault();
          email.classList.add('error');
        } else {
          email.classList.remove('error');
          // you don't need to do anything here, the form will be submitted
        }
      });
    }
  },

  initMultiStepForm: function () {
    let formElement = document.querySelector('[data-action~=initMultiStepForm]');

    if (formElement) {
      formElement.addEventListener('change', function(e) {
        if (e.target.type !== 'checkbox') {
          this.submit();
        }
      })
      // formElement.addEventListener('click', function(e) {

      //   if (e.target.classList.contains('multistep-form__tab-checkbox-label')) {
      //     // do nothing
      //   } else if (e.target.type === 'checkbox') {
      //     // do nothing
      //   } else {
      //     this.submit();
      //   }
      //   // if (e.target.type !== 'checkbox' || !e.target.classList.contains('multistep-form__tab-checkbox-label')) {
      //   //   this.submit();
      //   // }

      //   // if (e.target.type !== 'checkbox' || !e.target.classList.contains('multistep-form__tab-checkbox-label')) {
      //   //   this.submit();
      //   // }
      // })
    }
  },

  initTabby: function () {
    let tabs;
    if (document.querySelector('[data-tabs]')) {
      tabs = new Tabby('[data-tabs]');
    }
  },

  initElementCatapult: function () {
    global.EventHub.on('elementCatapult', function () {
      let type = arguments[0][0],
        sourceNode = arguments[0][1],
        targetNode,
        sourceNodePositionX,
        sourceNodePositionY,
        targetNodePositionX,
        targetNodePositionY,
        cloneElement;

      if (type && sourceNode) {
        if (type === 'cart') {

          targetNode = document.querySelector('.nav .cart');
          if (targetNode) {
            let sourceRect = sourceNode.getBoundingClientRect();
            let targetRect = targetNode.getBoundingClientRect();
            sourceNodePositionX = sourceRect.left;
            sourceNodePositionY = sourceRect.top;
            targetNodePositionX = targetRect.left;
            targetNodePositionY = targetRect.top;

            $(sourceNode)
              .css({ opacity: 0 })
              .clone()
              .css({ opacity: 1 })
              .css({ left: sourceNodePositionX, top: sourceNodePositionY })
              .addClass('catapult-item catapult-item--cart')
              .appendTo($('body'))

            setTimeout(function () {
              $(".catapult-item")
                .css({ left: targetNodePositionX + 20, top: targetNodePositionY - 5, opacity: 0 });
            }, 200);

            setTimeout(function () {
              $(".catapult-item")
                .css({ marginTop: 40 });
            }, 1000);

            setTimeout(function () {
              $(".catapult-item").remove();
              $(sourceNode)
                .css({ opacity: 1 })
            }, 1200);
          }
        }
      }
    });
  }
}

export default globalModule;