import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import classNames from 'classnames/bind';
import CartItem from '~/components/legacy/CartItem.jsx';

class Cart extends Component {

  constructor(props) {
    super(props);


    this.cartList = null;

    this.setCartListRef = element => {
      this.cartList = element;
    };

    this.handleClick = this.handleClick.bind(this);

    this.state = {
      opened: false,
      currencySymbol: '€',
      recentlyUpdated : false,
      data : this.props.data
     };
  }

  // getInitialState: function() {
  //   return {
  //       opened: false,
  //       currencySymbol: '€',
  //       recentlyUpdated : false,
  //       data : this.props.data
  //   };
  // },

  // getDefaultProps : function() {
  //     return {
  //         data : [],
  //         subscribedUsageLicenseTypes: [],
  //         flatrateDownloadsToday: 0,
  //         flatrateDownloadLimit: 0,
  //         usage_license_types: [],
  //         i18n : {
  //             free: 'for free',
  //             checkout_now: 'Checkout now',
  //             shopping_cart: 'Shopping cart'
  //         }
  //     }
  // },

  componentDidMount() {
      global.EventHub.on('updateCart', function(data) {
          let that = this;

          if (data[0].length === this.state.data.length - 1) {
            // item was removed, we omit animations
            that.setState({data: data[0]})
          } else {
            setTimeout(function() {
              that.setState({data: data[0], 'recentlyUpdated': true});
            }, 850)

            setTimeout(function() {
                that.setState({'recentlyUpdated': false});
            }, 2000);
          }

          if (data[0].length === 0) {
            this.setState({'opened': false});
          }

      }.bind(this));

      global.EventHub.on('menuFavoritesOpened', function() {
          this.setState({'opened': false});
      }.bind(this));

      global.EventHub.on('closePopupMenus', function() {
        this.setState({'opened': false});
      }.bind(this));
  }

  handleClick() {
    if (!this.state.opened) {
      global.EventHub.trigger('menuCartOpened');
    }

    this.setState({opened: !this.state.opened});
  }

  cartIsFree() {
      let isFree = false;

      if (this.state.data) {
          isFree = true;

          this.state.data.forEach(function(cartItem) {
              if (!this.props.subscribedUsageLicenseTypes.includes(cartItem.usage_license_type_id)) {
                  isFree = false;
              }
          }.bind(this))
      }

      return isFree;
  }

  getUsageLicenseTypeById(usageLicenseTypeId) {
      let match;

      this.props.usage_license_types.forEach(function(usageLicenseType) {
          if (usageLicenseType.id === usageLicenseTypeId) {
              match = usageLicenseType;
          }
      });

      return match;
  }

  render() {
    let cartItems = [],
        submitButton,
        classes,
        limitHeading;

    if (this.state.data) {
        cartItems = this.state.data.map(function (cartItem, index) {
          return (
            <CartItem
              id={cartItem.track_id}
              locale={this.props.locale}
              exposure_pack_id={cartItem.exposure_pack_id}
              sound_effect_id={cartItem.sound_effect_id}
              title={cartItem.title}
              includedInSubscription={this.props.subscribedUsageLicenseTypes.includes(cartItem.usage_license_type_id)}
              usageLicenseType={this.getUsageLicenseTypeById(cartItem.usage_license_type_id)}
              key={"cartItem-" + cartItem.track_id + '-' + cartItem.usage_license_type_id + '-' + index}
              usage_license_types={this.props.usage_license_types}
              free={cartItem.free}
              discount={cartItem.discount}
              flatrate_access={this.props.flatrate_access}
              i18n={this.props.i18n} />
          );
        }.bind(this));
    }

    classes = classNames({
      'cart': true,
      'cart--filled' : cartItems.length !== 0,
      'is-open' : this.state.opened,
      'is-closed' : !this.state.opened,
      'is-empty' : cartItems.length === 0,
      'is-recently-updated' : this.state.recentlyUpdated
    });

    if (this.props.subscribedUsageLicenseTypes.length > 0) {
      limitHeading = (
        <div className='col8 text-right'>
          <div className='cart__list-heading'>{this.props.locale === 'de' ? 'Heutige Abo-Downloads:' : 'Subscription downloads today:'} ({this.props.flatrateDownloadsToday} / {this.props.flatrateDownloadLimit})</div>
        </div>
      )
    }

    submitButton = (function() {
      let buttonText = this.props.i18n.checkout_now;

      if (this.cartIsFree()) {
        buttonText = this.props.locale === 'de' ? 'Zum Download' : 'Proceed to download';
      }

      return (
        <a className='button button--ghost-white button--full' href='/c/checkouts/new' title={buttonText}>{buttonText}</a>
        );
      }.bind(this))();

    if (this.state.opened) {
      if (cartItems.length > 0) {
        return (
          <div className={classes}>
            <div className='cart__status clearfix' onClick={this.handleClick}>
              <div className='cart__circle'>
                <span className='cart__tracks-count'>{cartItems.length}</span>
              </div>
              <span className='cart__symbol' title={this.props.i18n.shopping_cart}>
                <i className='fa fa-shopping-cart'></i>
              </span>
            </div>
            <div className='cart__list' ref={this.setCartListRef}>
              <div className='row margin-top10'>
                <div className='col4'>
                  <div className='cart__list-heading'>{this.props.i18n.shopping_cart} ({cartItems.length})</div>
                </div>
                {limitHeading}
              </div>
              {cartItems}
              <div className='cart-action'>
                {submitButton}
              </div>
            </div>
          </div>
        )
      } else {
        return (
          <div className={classes}>
            <div className='cart__status clearfix' onClick={this.handleClick}>
              <div className='cart__circle'>
                <span className='cart__tracks-count'>{cartItems.length}</span>
              </div>
              <span className='cart__symbol' title={this.props.i18n.shopping_cart}>
                <i className='fa fa-shopping-cart'></i>
              </span>
            </div>
          </div>
        )
      }
    } else {
      return (
        <div className={classes}>
          <div className='cart__status clearfix' onClick={cartItems.length > 0 ? this.handleClick : (function(){})}>
            <div className='cart__circle'>
              <span className='cart__tracks-count'>{cartItems.length}</span>
            </div>
            <span className='cart__symbol' title={this.props.i18n.shopping_cart}>
              <i className='fa fa-shopping-cart'></i>
            </span>
            <div className='cart__list-invisible' ref={this.setCartListRef}>
              {cartItems}
              <div className='cart-action text-center'>
                {submitButton}
              </div>
            </div>
          </div>
        </div>
      )
    }
  }
};

Cart.defaultProps = {
  data : [],
  subscribedUsageLicenseTypes: [],
  flatrateDownloadsToday: 0,
  flatrateDownloadLimit: 0,
  usage_license_types: [],
  i18n : {
    free: 'for free',
    checkout_now: 'Checkout now',
    shopping_cart: 'Shopping cart'
  }
}


// INIT

let elements = document.querySelectorAll('[data-react-component=Cart]');

elements.forEach(function(element) {
  let reactProps = JSON.parse(element.dataset.reactProps);

  ReactDOM.render(
    <React.StrictMode>
      <Cart {...reactProps} />
    </React.StrictMode>,
    element
  );
})

export default Cart;